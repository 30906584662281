/**
 * Fetches and stores the requirements documents from the repository.
 */

import type { RequirementsDocumentType } from '@asta/core'

import type { BaseResourceState, FetchResourceState } from '../base/base'
import {
	buildResourceReducer,
	createOne,
	fetchAll,
	fetchOne,
	initialBaseResourceState,
	RESOURCE,
} from '../base/base'

type RequirementsDocumentsState = BaseResourceState<RequirementsDocumentDTO>

export const requirementsDocumentsInitialState: RequirementsDocumentsState = {
	...initialBaseResourceState,
}

export type RequirementsDocumentDTO = {
	id: string

	/**
	 * Any of the meta
	 */
	metadata?: {
		name: string
		version: string
	}

	type: RequirementsDocumentType

	/**
	 * For the moment just any, we can enforce some stuff about
	 * the structure of the dat, but I don't want to care about
	 * that it's just up to the parser.
	 */
	data: any
}

// Selectors

export const documentsSelector = (state: any): RequirementsDocumentDTO[] =>
	state.requirementsDocuments.resources

export const documentSelector = (
	state: any,
	docId: string
): RequirementsDocumentDTO | null =>
	state.requirementsDocuments.resources.find(d => d.id === docId)

export const fetchAllDocumentsPendingSelector = (state: any) =>
	state.requirementsDocuments.getAll.isPending

export const lastFetchedAllDocumentsSelector = (state: any) =>
	state.requirementsDocuments.getAll.lastFetched

export const lastFetchedDocumentSelector = (
	state: any,
	docId: string
): number | null => {
	const docState = state.requirementsDocuments as RequirementsDocumentsState
	return docState.fetchResourceState[docId]?.lastFetched
}

export const documentsFetchStateSelector = (
	state: any
): Record<string, FetchResourceState> => {
	const docState = state.requirementsDocuments as RequirementsDocumentsState
	return docState.fetchResourceState
}

export const lastFetchedOneDocumentSelector = (state: any) =>
	state.requirementsDocuments.getOne.lastFetched

export const timeSinceLastFetchedDocumentsSelector = (state: any) =>
	Date.now() - state.requirementsDocuments.getAll.lastFetched

// Requests

export const fetchAllRequirementsDocuments = () =>
	fetchAll(RESOURCE.REQUIREMENTS_DOCUMENTS)

export const fetchRequirementsDocument = (docId: string) =>
	fetchOne(
		RESOURCE.REQUIREMENTS_DOCUMENTS,
		docId,
		'/api/v2/requirementsDocuments/' + docId
	)

export const createRequirementsDocument = (docDTO: RequirementsDocumentDTO) =>
	createOne(
		RESOURCE.REQUIREMENTS_DOCUMENTS,
		docDTO,
		'/api/v2/requirementsDocuments/v2'
	)

/**
 * Base reducer handles CRUD actions and updates the list of resources,
 * additional functionality can be added by wrapping this reducer with another.
 * @see models.ts
 */
export const requirementsDocumentsReducer = buildResourceReducer(
	requirementsDocumentsInitialState,
	RESOURCE.REQUIREMENTS_DOCUMENTS
)
