/**
 * Fetches and stores the application models from the repository.
 */

import { RelationshipType } from '@asta/core'

import { post } from '../base'
import type {
	BaseResourceState,
	RequestSuccessAction,
	ResourceAction,
} from '../base/base'
import {
	ACTION,
	actionType,
	API_PREFIX,
	buildResourceReducer,
	fetchAll,
	fetchOne,
	initialBaseResourceState,
	REQUEST_STATUS,
	RESOURCE,
} from '../base/base'

interface ModelsState extends BaseResourceState<ModelDTO> {
	nodeIndex: Record<string, Record<string, ModelNodeDTO>>
	edgeIndex: Record<string, Record<string, ModelEdgeDTO>>
}

export const modelsInitialState: ModelsState = {
	nodeIndex: {},
	edgeIndex: {},
	...initialBaseResourceState,
}

export type ModelDTO = {
	id: string
	applicationId: string
	nodes: ModelNodeDTO[]
	edges: ModelEdgeDTO[]
}

export type ModelNodeDTO = {
	id: string
	applicationId: string
	name: string
	type: string
	data: Record<string, string>
}

export type ModelEdgeDTO = {
	id: string
	applicationId: string
	type: string
	to: string
	from: string
	data: Record<string, string>
}

export const modelsSelector = (state: any) => state.models.resources

export const modelSelector = (state: any, appId: string) => {
	return state.models.resources.find(m => m.applicationId === appId)
}

export const lastFetchedAllModelsSelector = (state: any) =>
	state.models.getAll.lastFetched
export const lastFetchedOneModelSelector = (state: any) =>
	state.models.getOne.lastFetched

export const timeSinceLastFetchedOneModelSelector = (state: any) => {
	return Date.now() - state.models.getOne.lastFetched
}

export const componentsSelector = (state: any, appId: string) => {
	const modelsState: ModelsState = state.models
	const model = modelsState.resources.find(m => m.applicationId === appId)
	return model?.nodes
}

export const componentsIndexSelector = (state: any, appId: string) => {
	const modelsState: ModelsState = state.models
	return modelsState.nodeIndex[appId]
}

export const componentSelector = (
	state: any,
	appId: string,
	componentId: string
): ModelNodeDTO | null => {
	const modelsState: ModelsState = state.models
	const model = modelsState.resources.find(m => m.applicationId === appId)
	return model == null ? null : model.nodes.find(n => n.id === componentId)
}

export const fetchModels = () =>
	fetchAll(RESOURCE.MODELS, API_PREFIX + 'variants/model')

export const fetchModel = (appId: string) =>
	fetchOne(RESOURCE.MODELS, appId, `${API_PREFIX}/variants/${appId}/model`)

/**
 *
 */
type ComponentActions = AddComponent | AddRelationship

export const ADD_COMPONENT = 'ADD_COMPONENT'
interface AddComponent {
	type: typeof ADD_COMPONENT
	appId: string
	component: ModelNodeDTO
}
function addComponent(appId: string, component: ModelNodeDTO): AddComponent {
	return { type: ADD_COMPONENT, appId, component }
}

export const ADD_RELATIONSHIP = 'ADD_RELATIONSHIP'
interface AddRelationship {
	type: typeof ADD_RELATIONSHIP
	appId: string
	relationship: ModelEdgeDTO
}
function addRelationship(
	appId: string,
	relationship: ModelEdgeDTO
): AddRelationship {
	return { type: ADD_RELATIONSHIP, appId, relationship }
}

/**
 * Create a new component in the model. If the component has a parent, its id
 * should be provided, so a contains relationship from the parent can be created.
 * If the request(s) are successful, the model persisted in the state and its
 * indexes are updated to include the new component and relationship.
 */
export const createComponent = (
	appId: string,
	componentDTO: ModelNodeDTO,
	parentComponentId?: string
) => {
	return (dispatch: any) => {
		return async () => {
			// Create new component
			const addCompUrl = `/api/v2/variants/${appId}/model/components/`
			const addCompRes = await post(addCompUrl, componentDTO)
			const addCompBody = await addCompRes.json()
			const componentId = addCompBody.id
			const componentCreated: ModelNodeDTO = addCompBody
			let relationshipCreated: ModelEdgeDTO | null = null

			// If there's a parent,
			// create new relationship
			if (parentComponentId != null) {
				const addRelUrl = `/api/v2/variants/${appId}/model/relationships/`
				const createRelationshipDTO: ModelEdgeDTO = {
					applicationId: appId,
					id: null, // generated by repository, returned in res
					type: RelationshipType.containment,
					to: componentId,
					from: parentComponentId,
					data: {},
				}
				const addRelRes = await post(addRelUrl, createRelationshipDTO)
				const addRelBody = await addRelRes.json()
				relationshipCreated = addRelBody
			}

			dispatch(addComponent(appId, componentCreated))
			if (relationshipCreated)
				dispatch(addRelationship(appId, relationshipCreated))
		}
	}
}

/**
 * Base reducer handles CRUD actions and updates the list of model resources,
 * additional functionality for updating indexes is implemented in modelsReducer.
 */
export const baseReducer = buildResourceReducer(
	modelsInitialState,
	RESOURCE.MODELS
)

export function modelsReducer(
	state = modelsInitialState,
	action: ResourceAction | ComponentActions
): ModelsState {
	// Base reducer will update the list of model resources after being
	// fetched. So what comes after will have the most recent list of models.
	state = baseReducer(state, action as ResourceAction)

	// When a model is fetched, update the component and relationship indexes
	// to include the components and relationships from that model.
	switch (action.type) {
		// When a model has been fetched, update the node & relationship indexes
		case actionType(
			ACTION.GET_ONE,
			RESOURCE.MODELS,
			REQUEST_STATUS.SUCCESS
		):
			const nodes = (
				(action as RequestSuccessAction).responseBody as ModelDTO
			).nodes
			const edges = (
				(action as RequestSuccessAction).responseBody as ModelDTO
			).edges
			return {
				...state,
				nodeIndex: {
					...state.nodeIndex,
					...nodes.reduce((p, c) => (p[c.id] = c), {}),
					...edges.reduce((p, c) => (p[c.id] = c), {}),
				},
			}

		// When a component is added to the model,
		// add that component to the model's list of nodes,
		// update the nodeIndex for that model
		case ADD_COMPONENT:
			return {
				...state,
				resources: state.resources.map(model => {
					if (model.applicationId != action.appId) return model
					model.nodes = [...model.nodes, action.component]
					return model
				}),
				nodeIndex: {
					...state.nodeIndex,
					[action.appId]: {
						...state.nodeIndex[action.appId],
						[action.component.id]: action.component,
					},
				},
			}

		// When a component is added to the model,
		// add that relationship to the model's list of edges,
		// update the edgeIndex for that model
		case ADD_RELATIONSHIP:
			return {
				...state,
				resources: state.resources.map(model => {
					if (model.applicationId != action.appId) return model
					model.edges = [...model.edges, action.relationship]
					return model
				}),
				edgeIndex: {
					...state.edgeIndex,
					[action.appId]: {
						...state.edgeIndex[action.appId],
						[action.relationship.id]: action.relationship,
					},
				},
			}

		default:
			return state
	}
}
