import { useQuery } from '@tanstack/react-query'
import { DEFAULT_STALE_TIME } from 'deprecated/services/constants'
import VariantService from 'deprecated/services/VariantService'

export const useVariantsWorkspace = (variantId: string) => {
	const { data: workspace } = useQuery(
		['variants-workspace', variantId],
		() => VariantService.fetchVariantsWorkspace(variantId),
		{
			staleTime: DEFAULT_STALE_TIME,
			enabled: !!variantId,
		}
	)

	return {
		workspace,
	}
}
