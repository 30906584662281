import type {
	OptionType,
	SelectFilterDefinition,
	TextFilterDefinition,
} from '../TableFilters/filter-shapes'
import type { CustomFilterShape } from './useFilters'

export const selectSx = {
	width: '125px',
	maxHeight: '32.25px',
}

export const convertFilterObjectToArray = (
	filters: CustomFilterShape
): Array<CustomFilterShape> => {
	return (Object.keys(filters) as Array<keyof CustomFilterShape>)
		.map(key => key && filters[key] && { [key]: filters[key] })
		.filter(val => !!val)
}

export const convertFilterArrayToObject = (
	filters: Array<CustomFilterShape>
): CustomFilterShape => {
	return filterIncompleteFilters(filters).reduce(
		(accumulator, curr) => Object.assign(accumulator, curr),
		{} as CustomFilterShape
	)
}

export const getFlatFilter = (
	filter: CustomFilterShape
): [string, string, string | string[] | boolean] => {
	const column = Object.keys(filter)[0]
	const operator = filter[column] && Object.keys(filter[column])[0]
	const value = filter[column] && Object.values(filter[column])[0]
	return [column, operator, value]
}

export interface OperatorDefinition {
	label: string
	value: string
}

export const filterTypeToOperatorDefinition: {
	[key in OptionType]: OperatorDefinition[]
} = {
	select: [
		{
			label: 'is',
			value: '$eq',
		},
		{
			label: 'is not',
			value: '$ne',
		},
	],
	text: [
		{
			label: 'contains',
			value: '$regex',
		},
		{
			label: 'is',
			value: '$eq',
		},
	],
	range: [
		{
			label: 'to',
			value: '$in',
		},
	],
}

export const addOperatorsToOptions = (
	option: SelectFilterDefinition | TextFilterDefinition
): (SelectFilterDefinition | TextFilterDefinition) & {
	operators: Array<OperatorDefinition>
} => {
	return {
		...option,
		operators:
			option.type === 'text' && option.operator
				? filterTypeToOperatorDefinition[option.type].filter(
						opt => option.operator === opt.value
					)
				: filterTypeToOperatorDefinition[option.type],
	}
}

export const filterIncompleteFilters = (
	filters: Array<CustomFilterShape>
): Array<CustomFilterShape> => {
	return filters.filter(filter => {
		const [column, operator, value] = getFlatFilter(filter)
		return !!column && !!operator && !!value
	})
}
