import { useContext } from 'react'

import { WorkspaceContext } from './workspaceContext'

export const useWorkspaceContext = () => {
	const workspace = useContext(WorkspaceContext)

	if (!workspace) {
		throw new Error(
			'useWorkspaceContext must be used within a WorkspaceProvider'
		)
	}

	return workspace
}
