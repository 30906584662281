import { Buffer } from 'buffer'
import type { Dispatch } from 'redux'

import type { Request } from './base'
import { get } from './base'

export type ScreenshotsState = {
	/**
	 * Records are indexed by the screenshotId and the stored value is the base64
	 * encoded string for the image
	 */
	screenshots: Record<string, string>

	/**
	 * Keeps track of the status of all the outgoing requests for screenshots
	 */
	requests: Record<string, Request>
}

export const screenshotsInitialState = {
	screenshots: {} as Record<string, string>,
	requests: {} as Record<string, Request>,
}

export const screenshotsSelector = (state: any) => state.screenshots.screenshots

// ACTIONS
export type ScreenshotActions =
	| GetScreenshotPending
	| GetScreenshotSuccess
	| GetScreenshotError
export const GET_SCREENSHOT_PENDING = 'GET_SCREENSHOT_PENDING'
export const GET_SCREENSHOT_ERROR = 'GET_SCREENSHOT_ERROR'
export const GET_SCREENSHOT_SUCCESS = 'GET_SCREENSHOT_SUCCESS'
interface GetScreenshotPending {
	type: typeof GET_SCREENSHOT_PENDING
	screenshotId: string
}
interface GetScreenshotSuccess {
	type: typeof GET_SCREENSHOT_SUCCESS
	screenshotId: string
	screenshotBase64: string
}
interface GetScreenshotError {
	type: typeof GET_SCREENSHOT_ERROR
	screenshotId: string
	error: any
}
export function getScreenshotPending(screenshotId: string) {
	return { type: GET_SCREENSHOT_PENDING, screenshotId }
}
export function getScreenshotError(screenshotId: string, error: any) {
	return { type: GET_SCREENSHOT_ERROR, screenshotId, error }
}
export function getScreenshotSuccess(
	screenshotId: string,
	screenshotBase64: string
) {
	return { type: GET_SCREENSHOT_SUCCESS, screenshotId, screenshotBase64 }
}

// REDUCER
export const screenshotsReducer = (
	state = screenshotsInitialState,
	action: ScreenshotActions
): ScreenshotsState => {
	const requests = state.requests
	switch (action.type) {
		case GET_SCREENSHOT_SUCCESS:
			return {
				...state,
				screenshots: {
					...state.screenshots,
					[action.screenshotId]: action.screenshotBase64,
				},
				requests: {
					...state.requests,
					[action.screenshotId]: { pending: false, error: null },
				},
			}
		case GET_SCREENSHOT_PENDING:
			return {
				...state,
				requests: {
					...requests,
					[action.screenshotId]: { pending: true, error: null },
				},
			}
		case GET_SCREENSHOT_ERROR:
			return {
				...state,
				requests: {
					...requests,
					[action.screenshotId]: {
						error: action.error,
						pending: false,
					},
				},
			}
		default:
			return state
	}
}

// REQUESTS
export function getScreenshot(screenshotId: string, screenshot: any) {
	return (dispatch: Dispatch<any>) => {
		if (screenshot != null) return
		dispatch(getScreenshotPending(screenshotId))
		get(`/api/v2/screenshots/${screenshotId}`)
			.then(async res => {
				if (res.status === 404)
					throw new Error('Screenshot does not exist')
				const imageBuffer = await res.arrayBuffer()
				const imageStr = Buffer.from(imageBuffer).toString('base64')
				dispatch(getScreenshotSuccess(screenshotId, imageStr))
			})
			.catch(error => {
				dispatch(
					getScreenshotError(
						screenshotId,
						error?.message ||
							'Unexpected Error while getting screenshots'
					)
				)
			})
	}
}
