import { post } from './base'

/******************************* STATE ****************************************/
export type StopRunState = {
	stoppingRun: boolean
	errorMessage: string | null
}

export const stopRunInitialState = {
	stoppingRun: false,
	errorMessage: null,
}

/***************************** SELECTORS **************************************/
export const stopErrorSelector = (state: any) => state.stopRun.errorMessage

/****************************** ACTIONS ***************************************/
export type StopActionTypes = StopErrorAction

export const STOP_ERROR = 'STOP_ERROR'

interface StopErrorAction {
	type: typeof STOP_ERROR
	errorMessage: string | null
}

export function stopErrorAction(errorMessage: any) {
	return {
		type: STOP_ERROR,
		errorMessage,
	}
}

/****************************** REQUESTS ***************************************/
export function stopRunRequest(runId: string) {
	return (dispatch: any) => {
		// dispatch(stopPendingAction())
		post(`/api/v2/run/${runId}/stop`, {})
			.then(res => res.json())
			.then(res => {
				if (res.error) throw res.error
				if (res.errorMessage)
					dispatch(stopErrorAction(res.errorMessage))
			})
			.catch(() => {
				dispatch(stopErrorAction('Unknown error'))
			})
	}
}

/****************************** REDUCER ***************************************/
export function stopRunReducer(
	state = stopRunInitialState,
	action: StopActionTypes
): StopRunState {
	switch (action.type) {
		case STOP_ERROR:
			return { ...state, errorMessage: action.errorMessage }
		default:
			return state
	}
}
