import type { Tag } from 'shared/types'

export const tagColors = [
	'#F8B9C5',
	'#FFBC78',
	'#FEE685',
	'#C5EE93',
	'#29E1CB',
	'#A1D3FF',
	'#CFC4FD',
	'#F4B2FF',
	'#C9C9C9',
]

export type TagWithColor = Tag & { color: string }

export const getAvailableTagsWithColor = (tags: Tag[]): TagWithColor[] => {
	return (
		tags?.map((tag, i) => ({
			...tag,
			label: tag.name,
			value: tag._id,
			color: tagColors[i],
		})) ?? []
	)
}

export const getAvailableTagsObjWithColor = (tags: TagWithColor[]) => {
	return getAvailableTagsWithColor(tags).reduce<Record<string, Tag>>(
		(acc, tag) => {
			acc[tag._id] = tag
			return acc
		},
		{}
	)
}
