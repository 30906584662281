import { useContext } from 'react'

import { BreadcrumbsContext } from './BreadcrumbsContext'

export const useBreadcrumbs = () => {
	const breadcrumbsContext = useContext(BreadcrumbsContext)

	return {
		...breadcrumbsContext,
	}
}
