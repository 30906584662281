import './index.scss'

import React from 'react'

import { registerThemes } from '@asta/react-component-library'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { loader } from '@monaco-editor/react'
import {
	QueryCache,
	QueryClient,
	QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ConfigProvider } from 'contexts/ConfigContext'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import App from './App'
import store from './store'

// prefetch monaco from cdn for later use
Promise.all([loader.init(), import('@asta/lang-ide')]).then(([monaco, ide]) => {
	ide.registerFlowsSyntax(monaco)
	ide.registerRulesLanguage(monaco)
	registerThemes(monaco)
})

const queryCache = new QueryCache()
const client = new QueryClient({
	queryCache,
	defaultOptions: {
		queries: {
			// queries must opt in to caching
			staleTime: 0,
		},
	},
})
const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement,
	{
		onRecoverableError(error, errorInfo) {
			console.error(error)
			console.error(errorInfo)
		},
	}
)

// The React team claims that strict mode gets disabled in production, but I've
// had past experiences where double-renders still occurred in prod. By doing a
// check ourselves, we ensure strict mode is only ever enabled in dev.
const StrictMode =
	process.env.NODE_ENV !== 'production' ? React.StrictMode : React.Fragment

const cache = createCache({
	key: 'css',
	container: document.head,
})
root.render(
	<StrictMode>
		<CacheProvider value={cache}>
			<QueryClientProvider client={client}>
				<ReactQueryDevtools />
				<Provider store={store}>
					<ConfigProvider>
						<BrowserRouter>
							<App />
						</BrowserRouter>
					</ConfigProvider>
				</Provider>
			</QueryClientProvider>
		</CacheProvider>
	</StrictMode>
)
