/**
 * Implements the logic for creating a base reducer and requests for CRUD
 * operations on the system's resources. This provides an easy way to create
 * a new redux slice for a resource, which can re-use this module's reducer
 * and requests for the logic around fetching resources and storing the state
 * of those requests per resource.
 *
 * This only works if the resource's endpoints implement the following
 * constraints on the response body and resource url:
 *
 * - GET /api/v2/RESOURCE 		200 returns a list of extends BaseDTO
 * - GET /api/v2/RESOURCE/:id	200 returns a extends BaseDTO
 * - GET /api/v2/RESOURCE		non-200 returns a extends ErrorDTO
 * - GET /api/v2/RESOURCE/:id	non-200 returns a extends ErrorDTO
 *
 * - POST /api/v2/RESOURCE		201 returns a extends BaseDTO
 * - POST /api/v2/RESOURCE		non-201 returns a extends ErrorDTO
 */

import { get, post, put } from '../base'

export const API_PREFIX = '/api/v2'

type BaseDTO =
	| {
			id: string
	  }
	| {
			_id: string
	  }

export enum RESOURCE {
	AGENTS = 'agents',
	APPLICATIONS = 'variants',
	APPLICATION_COMPONENT_ASSOCIATIONS = 'applicationComponentAssociations',
	DATASETS = 'datasets',
	DRIVERS = 'drivers',
	MODELS = 'models',
	SUMMARY_STATISTICS = 'summaryStatistics',
	RULES = 'rules',
	RULE_ASSETS = 'assets/rule',
	RUNS = 'runs',
	ISSUES = 'issues',
	REQUIREMENTS_DOCUMENTS = 'requirementsDocuments',
	WORK_QUEUE = 'workQueue',
	ASSETS = 'assets',
}

export enum ACTION {
	/**
	 * Fetch one of a resource from the repository.
	 */
	GET_ONE = 'GET_ONE',

	/**
	 * Fetch all of a resource from the repository.
	 */
	GET_ALL = 'GET_ALL',

	/**
	 * Create one of a resource in the repository.
	 */
	CREATE_ONE = 'CREATE_ONE',

	DELETE_ONE = 'DELETE_ONE',

	/**
	 * Update one of a resource in the repository.
	 */
	UPDATE_ONE = 'UPDATE_ONE',

	/**
	 * Add one of a resource to the redux store.
	 */
	ADD_ONE = 'ADD_ONE',

	/**
	 * Remove one of a resource from the redux store.
	 */
	REMOVE_ONE = 'REMOVE_ONE',
}

export enum REQUEST_STATUS {
	PENDING = 'PENDING',
	ERROR = 'ERROR',
	SUCCESS = 'SUCCESS',
}

export type RequestState = {
	isPending: boolean
	errorMessage: string | null
	lastFetched: number
}

export interface BaseResourceState<ResourceDTO> {
	/**
	 * Stores the list of resource dtos.
	 */
	resources: ResourceDTO[]

	/**
	 * Stores metadata related to requests
	 * for resources by id. Enables us to know
	 * if a resource is currently being fetched,
	 * when it was last fetched, and if there
	 * was an error fetching it. Needed to
	 * prevent unnecessary fetches and retrying
	 * when a resource fails to load.
	 */
	fetchResourceState: Record<string, FetchResourceState>

	/**
	 * Stores metadata for update requests
	 * by resourceId.
	 */
	updateResourceState: Record<string, UpdateResourceState>

	getOne: {
		isPending: boolean
		errorMessage: string | null
		lastFetched: number
	}
	getAll: {
		isPending: boolean
		errorMessage: string | null
		lastFetched: number
	}
	createOne: {
		isPending: boolean
		errorMessage: string | null
	}
}

export type UpdateResourceState = {
	/**
	 * Time the last update request for
	 * the resource was made.
	 */
	timeUpdated: number

	/**
	 * Whether an update request for the
	 * resource is pending.
	 */
	updatePending: boolean

	/**
	 * Error message from the last update
	 * request made for the resource.
	 */
	errorMessage: string | null
}

export type FetchResourceState = {
	/**
	 * Unix time when last fetch was
	 * initiated.
	 */
	lastFetched: number

	/**
	 * Is a request to fetch this resource
	 * currently pending?
	 */
	fetchPending: boolean

	/**
	 * The error message from the last
	 * fetch response for this resource.
	 */
	errorMessage: string | null
}

export const initialBaseResourceState = {
	resources: [],
	fetchResourceState: {},
	updateResourceState: {},
	getOne: {
		isPending: false,
		errorMessage: null,
		lastFetched: 0,
	},
	getAll: {
		isPending: false,
		errorMessage: null,
		lastFetched: 0,
	},
	createOne: {
		isPending: false,
		errorMessage: null,
	},
}

/**
 * ResourceActions are things like 'GET RULES PENDING'. This template string
 * should be sufficient for all the type of actions [WIP NAME] reducers need
 * to handle.
 */
export type ResourceActionType = `${ACTION} ${RESOURCE} ${REQUEST_STATUS}`

export function actionType(
	action: ACTION,
	resource: RESOURCE,
	requestStatus: REQUEST_STATUS
): ResourceActionType {
	return `${action} ${resource} ${requestStatus}` as ResourceActionType
}

export function storeActionType(action: ACTION, resource: RESOURCE): string {
	return `${action} ${resource}`
}

export type ResourceAction =
	| RequestPendingAction
	| RequestErrorAction
	| RequestSuccessAction
	| AddOneAction
	| RemoveOneAction

export type RequestPendingAction = {
	type: `${ACTION} ${RESOURCE} ${REQUEST_STATUS.PENDING}`
	resourceId?: string
}

export type RequestErrorAction = {
	type: `${ACTION} ${RESOURCE} ${REQUEST_STATUS.ERROR}`
	error: string
	resourceId?: string
}

export type RequestSuccessAction = {
	type: `${ACTION} ${RESOURCE} ${REQUEST_STATUS.SUCCESS}`
	responseBody: BaseDTO | BaseDTO[]
	resourceId?: string
}

export type AddOneAction = {
	type: `${ACTION.ADD_ONE} ${RESOURCE}`
	resource: BaseDTO
}

export type RemoveOneAction = {
	type: `${ACTION.REMOVE_ONE} ${RESOURCE}`
	resource: BaseDTO
}

function buildPending(
	action: ACTION,
	resource: RESOURCE,
	resourceId?: string
): RequestPendingAction {
	return {
		type: `${action} ${resource} ${REQUEST_STATUS.PENDING}`,
		resourceId,
	} as RequestPendingAction
}

function buildError(
	action: ACTION,
	resource: RESOURCE,
	error: any,
	resourceId?: string
): RequestErrorAction {
	return {
		type: `${action} ${resource} ${REQUEST_STATUS.ERROR}`,
		error,
		resourceId,
	} as RequestErrorAction
}

function buildSuccess(
	action: ACTION,
	resource: RESOURCE,
	responseBody: BaseDTO | BaseDTO[],
	resourceId?: string
): RequestSuccessAction {
	return {
		type: `${action} ${resource} ${REQUEST_STATUS.SUCCESS}`,
		responseBody,
		resourceId,
	} as RequestSuccessAction
}

export function buildAddOne(
	resource: RESOURCE,
	resourceDTO: BaseDTO
): AddOneAction {
	return {
		type: `${ACTION.ADD_ONE} ${resource}`,
		resource: resourceDTO,
	}
}

export function buildRemoveOne(
	resource: RESOURCE,
	resourceDTO: BaseDTO
): RemoveOneAction {
	return {
		type: `${ACTION.REMOVE_ONE} ${resource}`,
		resource: resourceDTO,
	}
}

export function fetchAll(resource: RESOURCE, route?: string) {
	return (dispatch: any) => {
		const action = ACTION.GET_ALL
		dispatch(buildPending(action, resource))
		get(route != null ? route : `/api/v2/${resource}`)
			.then(res => {
				if (res.status == 500) {
					dispatch(buildError(action, resource, 'Status 500'))
					return
				}
				if (res.status !== 200) {
					dispatch(buildError(action, resource, 'Status not 200'))
				}
				return res.json()
			})
			.then(body => {
				if (body.error != null) {
					dispatch(buildError(action, resource, body.error as string))
				} else {
					if (body.data != null && body.data.length != null)
						dispatch(buildSuccess(action, resource, body.data))
					else dispatch(buildSuccess(action, resource, body))
				}
			})
			.catch(error => {
				dispatch(buildError(action, resource, error.message as string))
			})
	}
}

/**
 * Fetch one of the the resource from the repository. It's expected
 * that `route` will be provided. If `route` is not provided then the
 * default route used will be `/api/v2/${resource}`. Remember that this
 * means the value in the `RESOURCE` enum is expected to equal the name
 * of the route for that resource.
 */
export function fetchOne(resource: RESOURCE, id: string, route?: string) {
	return (dispatch: any) => {
		const action = ACTION.GET_ONE
		dispatch(buildPending(action, resource, id))
		get(route != null ? route : `/api/v2/${resource}/${id}`)
			.then(res => {
				if (res.status == 500) {
					dispatch(buildError(action, resource, 'Status 500'))
					return
				}
				if (res.status !== 200)
					dispatch(buildError(action, resource, 'Status not 200', id))
				return res.json()
			})
			.then(body => {
				if (body.error != null)
					dispatch(
						buildError(action, resource, body.error as string, id)
					)
				else {
					if (
						body.data != null &&
						(body.data.id != null || body.data._id != null)
					)
						dispatch(buildSuccess(action, resource, body.data, id))
					else dispatch(buildSuccess(action, resource, body, id))
				}
			})
			.catch(error => {
				dispatch(
					buildError(action, resource, error.message as string, id)
				)
			})
	}
}

/**
 * Create one resource by posting it to the repository.
 */
export function createOne(
	resource: RESOURCE,
	resourceDTO: any,
	route?: string
) {
	return (dispatch: any) => {
		const action = ACTION.CREATE_ONE
		dispatch(buildPending(action, resource))
		post(route == null ? `/api/v2/${resource}` : route, resourceDTO)
			.then(res => {
				if (res.status !== 201)
					dispatch(buildError(action, resource, 'Status not 201'))
				return res.json()
			})
			.then(body => {
				if (body.error != null)
					dispatch(buildError(action, resource, body.error as string))
				else dispatch(buildSuccess(action, resource, body))
			})
			.catch(error => {
				dispatch(buildError(action, resource, error.message as string))
			})
	}
}

/**
 * Create one resource by posting it to the repository.
 */
export function deleteOne(resource: RESOURCE, id: string, route?: string) {
	return (dispatch: any) => {
		const action = ACTION.DELETE_ONE
		dispatch(buildPending(action, resource))
		get(route == null ? `/api/v2/${resource}/${id}/delete` : route)
			.then(res => {
				if (res.status !== 200)
					dispatch(buildError(action, resource, 'Status not 200'))
				return res.json()
			})
			.then(body => {
				if (body.error != null)
					dispatch(buildError(action, resource, body.error as string))
				else dispatch(buildSuccess(action, resource, body))
			})
			.catch(error => {
				dispatch(buildError(action, resource, error.message as string))
			})
	}
}

/**
 * Update one resource. Doesn't matter if the
 * resource already exists in the repository or
 * not, both cases are handled and the resource
 * DTO is dispatched in a UPDATE_ONE action.
 */
export function updateOne(
	resource: RESOURCE,
	resourceDTO: any,
	route?: string
) {
	return (dispatch: any) => {
		const action = ACTION.UPDATE_ONE
		dispatch(buildPending(action, resource))
		put(route == null ? `/api/v2/${resource}` : route, resourceDTO)
			.then(res => {
				if (res.status === 200 || res.status === 201) return res.json()
				dispatch(
					buildError(action, resource, 'Error updating ' + resource)
				)
			})
			.then(body => {
				if (body.error != null)
					dispatch(buildError(action, resource, body.error as string))
				else dispatch(buildSuccess(action, resource, body))
			})
			.catch(error => {
				dispatch(buildError(action, resource, error.message as string))
			})
	}
}

/**
 * Add one resource to that resource's redux store.
 */
export function addOne(resourceType: RESOURCE, resource: BaseDTO) {
	return (dispatch: any) => dispatch(buildAddOne(resourceType, resource))
}

/**
 * Remove one resource from that resource's redux store.
 */
export function removeOne(resourceType: RESOURCE, resource: BaseDTO) {
	return (dispatch: any) => dispatch(buildRemoveOne(resourceType, resource))
}

export function buildResourceReducer(
	initialState: BaseResourceState<BaseDTO>,
	resource: RESOURCE
) {
	return (
		state: BaseResourceState<BaseDTO> = initialState,
		action: ResourceAction
	): BaseResourceState<BaseDTO> => {
		switch (action.type) {
			case storeActionType(ACTION.ADD_ONE, resource):
				return {
					...state,
					resources: [
						...state.resources,
						(action as AddOneAction).resource,
					],
				}

			case storeActionType(ACTION.REMOVE_ONE, resource):
				return {
					...state,
					resources: state.resources.filter(
						res =>
							res.id !== (action as RemoveOneAction).resource.id
					),
				}

			case actionType(ACTION.GET_ALL, resource, REQUEST_STATUS.PENDING):
				return {
					...state,
					getAll: {
						errorMessage: null,
						isPending: true,
						lastFetched: Date.now(),
					},
				}

			case actionType(ACTION.GET_ALL, resource, REQUEST_STATUS.ERROR):
				return {
					...state,
					getAll: {
						...state.getAll,
						errorMessage: (action as RequestErrorAction).error,
						isPending: false,
					},
				}

			case actionType(ACTION.GET_ALL, resource, REQUEST_STATUS.SUCCESS):
				const type =
					resource == RESOURCE.ASSETS &&
					action.responseBody.length > 0
						? action.responseBody[0].resource.type
						: null
				return {
					...state,
					resources:
						resource == RESOURCE.ASSETS
							? state.resources
									.filter(r => r.resource.type != type)
									.concat(action.responseBody)
							: ((action as RequestSuccessAction)
									.responseBody as BaseDTO[]),
					fetchResourceState: {
						...state.fetchResourceState,
						...(
							(action as RequestSuccessAction)
								.responseBody as BaseDTO[]
						).reduce(
							(
								prev: Record<string, FetchResourceState>,
								curr: BaseDTO
							): Record<string, FetchResourceState> => {
								prev[curr.id] = {
									lastFetched: Date.now(),
									errorMessage: null,
									fetchPending: false,
								}
								return prev
							},
							{}
						),
					},
					getAll: {
						...state.getAll,
						errorMessage: null,
						isPending: false,
					},
				}

			case actionType(ACTION.GET_ONE, resource, REQUEST_STATUS.PENDING):
				return {
					...state,
					fetchResourceState: {
						...state.fetchResourceState,
						[action.resourceId]: {
							lastFetched: Date.now(),
							fetchPending: true,
							errorMessage: null,
						},
					},
					getOne: {
						errorMessage: null,
						isPending: true,
						lastFetched: Date.now(),
					},
				}

			case actionType(ACTION.GET_ONE, resource, REQUEST_STATUS.ERROR):
				return {
					...state,
					fetchResourceState: {
						...state.fetchResourceState,
						[action.resourceId]: {
							...state.fetchResourceState[action.resourceId],
							fetchPending: false,
							errorMessage: (action as RequestErrorAction).error,
						},
					},
					getOne: {
						...state.getOne,
						errorMessage: (action as RequestErrorAction).error,
						isPending: false,
					},
				}

			case actionType(ACTION.GET_ONE, resource, REQUEST_STATUS.SUCCESS):
				// Get the fetched resource from the response body
				const fetchedResource = (action as RequestSuccessAction)
					.responseBody as BaseDTO

				// If the resource store for this resource type hasn't been initialized
				if (state.resources == null) state.resources = []

				// If the resource with this id already exists replace
				// it with the newly fetched version. Other, add it to
				// the list of resources.
				const resourceExists = state.resources.find(
					r => r.id === fetchedResource.id
				)
				if (resourceExists != null) {
					state.resources = [
						...state.resources.filter(
							r => r.id !== fetchedResource.id
						),
						fetchedResource,
					]
				} else {
					state.resources = [...state.resources, fetchedResource]
				}

				// Return the updated state with the new list of resources
				return {
					...state,
					resources: [...state.resources],
					fetchResourceState: {
						...state.fetchResourceState,
						[action.resourceId]: {
							...state.fetchResourceState[action.resourceId],
							fetchPending: false,
							errorMessage: null,
						},
					},
					getOne: {
						...state.getOne,
						errorMessage: null,
						isPending: false,
					},
				}

			case actionType(
				ACTION.DELETE_ONE,
				resource,
				REQUEST_STATUS.SUCCESS
			):
				const deletedResource = (action as RequestSuccessAction)
					.responseBody as BaseDTO
				const deletedResourceId = deletedResource._id
				// If the resource store for this resource type hasn't been initialized
				if (state.resources == null) state.resources = []
				// Return the updated state with the new list of resources
				return {
					...state,
					resources: state.resources.filter(
						r =>
							r.id != deletedResourceId &&
							r._id != deletedResourceId
					),
				}

			case actionType(
				ACTION.UPDATE_ONE,
				resource,
				REQUEST_STATUS.PENDING
			):
				return {
					...state,
					updateResourceState: {
						...state.updateResourceState,
						[action.resourceId]: {
							timeUpdated: Date.now(),
							updatePending: true,
							errorMessage: null,
						},
					},
				}

			case actionType(ACTION.UPDATE_ONE, resource, REQUEST_STATUS.ERROR):
				return {
					...state,
					updateResourceState: {
						...state.updateResourceState,
						[action.resourceId]: {
							...state.updateResourceState[action.resourceId],
							updatePending: false,
							errorMessage: (action as RequestErrorAction).error,
						},
					},
				}

			case actionType(
				ACTION.UPDATE_ONE,
				resource,
				REQUEST_STATUS.SUCCESS
			):
				const updatedResource = action.responseBody
				return {
					...state,
					resources: state.resources
						.filter(resource => {
							if (updatedResource.id != null)
								return resource.id != updatedResource.id
							if (updatedResource._id != null)
								return resource._id != updatedResource._id
						})
						.concat(
							(action as RequestSuccessAction)
								.responseBody as BaseDTO
						),
					updateResourceState: {
						...state.updateResourceState,
						[action.resourceId]: {
							...state.updateResourceState[action.resourceId],
							updatePending: false,
							errorMessage: null,
						},
					},
				}

			case actionType(
				ACTION.CREATE_ONE,
				resource,
				REQUEST_STATUS.PENDING
			):
				return {
					...state,
					createOne: {
						errorMessage: null,
						isPending: true,
					},
				}

			case actionType(ACTION.CREATE_ONE, resource, REQUEST_STATUS.ERROR):
				return {
					...state,
					createOne: {
						errorMessage: (action as RequestErrorAction).error,
						isPending: false,
					},
				}

			case actionType(
				ACTION.CREATE_ONE,
				resource,
				REQUEST_STATUS.SUCCESS
			):
				const createdResource = (action as RequestSuccessAction)
					.responseBody as BaseDTO
				return {
					...state,
					createOne: {
						errorMessage: null,
						isPending: false,
					},
					resources: state.resources
						.filter(resource => {
							if (createdResource.id != null)
								return resource.id != createdResource.id
							if (createdResource._id != null)
								return resource._id != createdResource._id
						})
						.concat(createdResource),
				}

			default:
				return state
		}
	}
}
