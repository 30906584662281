import type {
	BApplicationDTO,
	CreateBApplicationDTO,
	UpdateBApplicationDTO,
} from '@asta/repository-dtos'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import BApplicationService from './BApplicationService'

export const bApplicationInitialState: BApplicationDTO[] = []

export const createBApplication = createAsyncThunk(
	'bApplication/create',
	async (app: CreateBApplicationDTO) => {
		const res = await BApplicationService.create(app)
		return res.data
	}
)

export const updateBApplication = createAsyncThunk(
	'bApplication/update',
	async (up: { id: string; app: UpdateBApplicationDTO }) => {
		const res = await BApplicationService.update(up.id, up.app)
		return res.data
	}
)

export const fetchBApplications = createAsyncThunk(
	'bApplication/fetchAll',
	async () => {
		const res = await BApplicationService.fetchAll()
		return res.data
	}
)

export const fetchBApplication = createAsyncThunk(
	'bApplication/fetch',
	async (id: string) => {
		const res = await BApplicationService.fetch(id)
		return res.data
	}
)

const bApplicationSlice = createSlice({
	name: 'bApplication',
	initialState: bApplicationInitialState,
	extraReducers: builder => {
		builder.addCase(createBApplication.fulfilled, (state, action) => {
			return [...state, action.payload]
		}),
			builder.addCase(fetchBApplications.fulfilled, (state, action) => {
				return [...action.payload]
			}),
			builder.addCase(fetchBApplication.fulfilled, (state, action) => {
				const index = state.find(st => st._id === action.payload.id)

				if (index) {
					return state.map(st => {
						if (st._id === action.payload._id) return action.payload

						return st
					})
				}

				return [...state, action.payload]
			}),
			builder.addCase(updateBApplication.fulfilled, (state, action) => {
				return state.map(st =>
					st._id === action.payload._id ? action.payload : st
				)
			})
	},
	reducers: undefined,
})

const { reducer } = bApplicationSlice
export default reducer
