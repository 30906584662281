import {
	horizontalListSortingStrategy,
	SortableContext,
} from '@dnd-kit/sortable'
import { styled, TableHead, TableRow } from '@mui/material'
import type { Table } from '@tanstack/react-table'

import { DraggableHeader } from './DraggableHeader'

const TH = styled(TableHead)`
	position: sticky;
	margin: 0;
	top: 0;
	display: grid;
	width: fit-content;
	height: 30px;
	z-index: 1;
`

const HeaderRow = styled(TableRow)(({ theme }) => ({
	background: theme.palette.background.default,
	display: 'flex',
	width: 'fit-content',
	height: '30px',
}))

interface TableHeadersProps<T> {
	table: Table<T>
	columnOrder: string[]
	sort?: string
	setSort?: (sort: string) => void
}

export function TableHeaders<T>({
	table,
	columnOrder,
	sort,
	setSort,
}: TableHeadersProps<T>) {
	return (
		<TH>
			{table.getHeaderGroups().map(headerGroup => (
				<HeaderRow key={headerGroup.id}>
					<SortableContext
						items={columnOrder}
						strategy={horizontalListSortingStrategy}
					>
						{headerGroup.headers.map(header => (
							<DraggableHeader
								key={header.id}
								header={header}
								sort={sort}
								setSort={setSort}
							/>
						))}
					</SortableContext>
				</HeaderRow>
			))}
		</TH>
	)
}
