import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
	closestCenter,
	DndContext,
	type DragEndEvent,
	KeyboardSensor,
	MouseSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core'
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers'
import { arrayMove } from '@dnd-kit/sortable'
import { ExpandLessSharp, ExpandMoreSharp } from '@mui/icons-material'
import { Box, Grid, IconButton, styled, Typography } from '@mui/material'
import type { ColumnDef, Row } from '@tanstack/react-table'
import {
	getCoreRowModel,
	getExpandedRowModel,
	getFilteredRowModel,
	useReactTable,
} from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import type { AssetTableEntryDTO } from 'api/repository/repositorySchemas'
import Loader from 'deprecated/components/Loader'
import { TextInput } from 'evergreen-ui'
import useLocalStorage from 'hooks/useLocalStorage'
import { useSelectedRowsContext } from 'pages/App/Assets/context/SelectedRowsContext'
import { useLocation } from 'react-router-dom'

import TableToolbar from './AstaTableToolbar'
import { TableBody as TB } from './TableBody'
import { TableHeaders } from './TableHeaders'

const Table = styled('table', {
	shouldForwardProp: prop => prop !== 'width',
})<{ width: string | number }>(({ width }) => ({
	display: 'grid',
	width,
}))

const TableContainer = styled('div')(() => ({
	overflow: 'auto',
	position: 'relative', //needed for sticky header
	height: 'calc(100vh - 100px)',
	maxHeight: '100vh',
}))

export const ExpanderCell: React.FC<{ children; row; depth }> = ({
	children,
	row,
	depth,
}) => {
	return (
		<div style={expanderStyle}>
			{row.getCanExpand() && (
				<ExpandWrapper depth={depth}>
					<IconButton
						onClick={row.getToggleExpandedHandler()}
						sx={expanderButtonSx}
					>
						{row.getIsExpanded() ? (
							<ExpandMoreSharp />
						) : (
							<ExpandLessSharp style={expandLessStyle} />
						)}
					</IconButton>
				</ExpandWrapper>
			)}
			{children}
		</div>
	)
}
const expanderStyle = {
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
} as const
const expanderButtonSx = {
	width: '16px',
	height: '16px',
	padding: '0px',
	color: '#8c8c8c',
}
const expandLessStyle = {
	transform: 'rotate(90deg)',
} as const
const ExpandWrapper = styled('div', {
	shouldForwardProp: prop => prop !== 'depth',
})<{ depth: number }>`
	width: 20px;
	margin-right: 6px;
	margin-left: ${props => 20 * props.depth}px;
	margin-bottom: 2.5px;
`

const EmptyTableView = () => {
	return (
		<Grid container width="100%">
			<Box
				width="100%"
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyItems="center"
				p="5% 25%"
				textAlign="center"
			>
				<Typography variant="h1">Table is empty.</Typography>
				<Typography variant="h2">
					There are no resources available for this table.
				</Typography>
			</Box>
		</Grid>
	)
}

interface TableProps<T> {
	data: T[]
	columns: ColumnDef<T>[]
	actions: React.ReactNode[]
	rowOnClick?: (data: T) => void
	disableToolbar?: boolean
	fetchNextPage?: any
	hasNextPage?: boolean
	isFetching?: boolean
	contextMenuOptions?: {
		label: string
		onClick: (rows: any[]) => void
	}[]
	columnVisibility?: Record<string, boolean>
	setColumnVisibility?: React.Dispatch<React.SetStateAction<{}>>
	columnOrder?: string[]
	setColumnOrder?: (columnOrder: string[]) => void
	selectedRowId?: string | number
	isLoading?: boolean
	textFilter?: string
	areRowsExpanded?: boolean
	sort?: string
	setSort?: (value: string) => void
	containerRef?: React.MutableRefObject<HTMLDivElement>
}

export function AstaTableV2<T>({
	data,
	columns,
	actions,
	rowOnClick,
	disableToolbar,
	isFetching = false,
	hasNextPage = false,
	fetchNextPage,
	contextMenuOptions,
	columnVisibility,
	setColumnVisibility,
	columnOrder: _columnOrder,
	setColumnOrder,
	selectedRowId,
	isLoading = false,
	textFilter,
	areRowsExpanded,
	sort,
	setSort,
	containerRef,
}: TableProps<T>) {
	const { setSelectedRows } = useSelectedRowsContext()
	const tableParentRef = React.useRef()
	const [expanded, setExpanded] = React.useState<
		true | Record<string, boolean>
	>(areRowsExpanded || {})
	const [globalFilter, setGlobalFilter] = React.useState<any>(null)
	const [rowSelection, setRowSelection] = useState({})

	const { pathname } = useLocation()
	const LSKey = pathname.split('/').at(-1)

	//Using LS to persist column order for tables that don't use views
	const [internalColumnOrder, setInternalColumnOrder] = useLocalStorage<
		string[]
	>(`columnOrder-${LSKey}`, () => columns.map(c => c.id!))

	const columnOrder = useMemo(
		() => (_columnOrder?.length > 0 ? _columnOrder : internalColumnOrder),
		[_columnOrder, internalColumnOrder]
	)

	const table = useReactTable<T>({
		data,
		columns,
		enableExpanding: true,
		filterFromLeafRows: true,
		state: {
			expanded,
			globalFilter: textFilter ?? globalFilter,
			columnVisibility,
			rowSelection,
			columnOrder,
		},
		getSubRows(row, _rows) {
			const subRows = row?.subRows
			return subRows == null
				? []
				: subRows.map(r => ({ ...r, parent: row }))
		},
		onExpandedChange: setExpanded,
		getCoreRowModel: getCoreRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		debugTable: false,
		columnResizeMode: 'onChange',
		enableRowSelection: true,
		onRowSelectionChange: setRowSelection,
		onColumnVisibilityChange: setColumnVisibility,
	})

	useEffect(() => {
		const columnSizing = JSON.parse(
			localStorage.getItem('columnSizing') ?? null
		)

		if (!!columnSizing && columnSizing[LSKey]) {
			table.setColumnSizing(columnSizing[LSKey])
		}
	}, [])

	//Sync row selection for export in test assets table if export context exists
	useEffect(() => {
		setSelectedRows &&
			setSelectedRows(
				table.getSelectedRowModel()
					.flatRows as Row<AssetTableEntryDTO>[]
			)
	}, [table.getSelectedRowModel().flatRows])

	const { rows } = table.getRowModel()
	const rowVirtualizer = useVirtualizer({
		count: rows.length,
		estimateSize: () => 30,
		getScrollElement: () => containerRef?.current ?? tableParentRef.current,
		measureElement:
			typeof window !== 'undefined' &&
			navigator.userAgent.indexOf('Firefox') === -1
				? element => element?.getBoundingClientRect().height
				: undefined,
		overscan: 30,
	})
	const { getVirtualItems } = rowVirtualizer
	const virtualRows = getVirtualItems()
	// When the global filter is updated, expand all rows, so everything
	// can be truly searched
	const setFilter = useCallback(
		(filter: string) => {
			const allExpanded = table.getIsAllRowsExpanded()
			if (!allExpanded) table.getToggleAllRowsExpandedHandler()(true)
			setGlobalFilter(filter)
		},
		[table]
	)

	const { columnSizing } = table.getState()

	//TODO: REMOVE, INSTEAD UPDATE USING EVENT HANDLERS
	useEffect(() => {
		if (columnSizing && Object.keys(columnSizing).length > 0) {
			const columnSizingLS = JSON.parse(
				localStorage.getItem('columnSizing') ?? null
			)
			localStorage.setItem(
				'columnSizing',
				JSON.stringify(
					columnSizingLS
						? { ...columnSizingLS, [LSKey]: columnSizing }
						: { [LSKey]: columnSizing }
				)
			)
		}
	}, [columnSizing])

	const fetchMoreOnBottomReached = useCallback(
		(e: React.UIEvent<HTMLDivElement, UIEvent>) => {
			const containerRefElement = e?.target as HTMLDivElement
			if (containerRefElement) {
				const { scrollHeight, scrollTop, clientHeight } =
					containerRefElement
				//once the user has scrolled within 500px of the bottom of the table, fetch more data if we can
				if (
					scrollHeight - scrollTop - clientHeight < 500 &&
					!isFetching &&
					hasNextPage &&
					fetchNextPage
				) {
					fetchNextPage()
				}
			}
		},
		[fetchNextPage, isFetching, hasNextPage]
	)

	// reorder columns after drag & drop
	const handleDragEnd = useCallback(
		(event: DragEndEvent) => {
			const { active, over } = event
			if (active && over && active.id !== over.id) {
				const oldIndex = columnOrder.indexOf(active.id as string)
				const newIndex = columnOrder.indexOf(over.id as string)
				const newColumnOrder = arrayMove(
					columnOrder,
					oldIndex,
					newIndex
				) satisfies string[]
				setColumnOrder
					? setColumnOrder(newColumnOrder)
					: setInternalColumnOrder(newColumnOrder)
			}
		},
		[setColumnOrder, columnOrder, setInternalColumnOrder]
	)

	const sensors = useSensors(
		useSensor(MouseSensor, {}),
		useSensor(TouchSensor, {}),
		useSensor(KeyboardSensor, {})
	)

	if (isLoading) return <Loader />
	if (!isLoading && !isFetching && data.length === 0)
		return (
			<>
				{disableToolbar !== true && (
					<TableToolbar
						filter={globalFilter}
						setFilter={setFilter}
						actions={actions}
						buttons={[
							<TextInput
								key="search-table-input"
								margin="6px"
								width="200px"
								id="search"
								placeholder="Search"
								onChange={e => setFilter(e.target.value)}
							/>,
						]}
					/>
				)}
				<EmptyTableView />
			</>
		)

	return (
		<>
			{disableToolbar !== true && (
				<TableToolbar
					filter={globalFilter}
					setFilter={setFilter}
					actions={actions}
					buttons={[
						<TextInput
							key="search-table-input"
							margin="6px"
							width="200px"
							id="search"
							placeholder="Search"
							onChange={e => setFilter(e.target.value)}
						/>,
					]}
				/>
			)}
			<DndContext
				key={LSKey}
				collisionDetection={closestCenter}
				modifiers={[restrictToHorizontalAxis]}
				onDragEnd={handleDragEnd}
				sensors={sensors}
			>
				<TableContainer
					onScroll={fetchMoreOnBottomReached}
					ref={containerRef ?? tableParentRef}
				>
					{/* Even though we're still using sematic table tags, we must use CSS grid and flexbox for dynamic row heights */}
					<Table
						key={LSKey}
						width={
							!isNaN(table.getCenterTotalSize())
								? table.getCenterTotalSize()
								: 'auto'
						}
					>
						<TableHeaders
							table={table}
							columnOrder={columnOrder}
							sort={sort}
							setSort={setSort}
						/>
						<TB
							table={table}
							rows={virtualRows}
							rowOnClick={rowOnClick}
							setRowSelection={setRowSelection}
							contextMenuOptions={contextMenuOptions}
							//Add 30px so that the table has enough space for context menu
							height={
								contextMenuOptions?.length > 0
									? rowVirtualizer.getTotalSize() + 30
									: rowVirtualizer.getTotalSize()
							}
							rowVirtualizer={rowVirtualizer}
							selectedRowId={selectedRowId}
						/>
					</Table>
				</TableContainer>
			</DndContext>
		</>
	)
}
