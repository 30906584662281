import { createContext } from 'react'

import type { RunStatusDTO } from '@asta/repository-dtos'

interface ContextProps {
	runState: RunStatusDTO['runningState']
	formattedTime: string
	updateRunState: (state: RunStatusDTO['runningState']) => void
	setInitialTime: (initialTime: number) => void
}

export const RunTimerContext = createContext({} as ContextProps)

if (process.env.NODE_ENV !== 'production') {
	RunTimerContext.displayName = 'RunTimerContext'
}
