import type {
	CreatePermissionDto,
	UpdatePermissionRoleDto,
} from '@asta/repository-dtos'

import axios from './axios-base'

const create = async (permission: CreatePermissionDto) => {
	const { data } = await axios.post('/api/v2/permissions', permission)
	return data
}

const fetchAll = () => axios.get('/api/v2/permissions/')

const fetch = async (id: string) => {
	const { data } = await axios.get('/api/v2/permissions/' + id)
	return data
}

const fetchResourcePermissions = async (resourceId: string) => {
	const { data } = await axios.get(
		`/api/v2/permissions/resource/${resourceId}`
	)
	return data
}

const fetchUserPermissions = async (userId: string) => {
	const { data } = await axios.get(`/api/v2/permissions/user/${userId}`)
	return data
}
const updatePermissionRole = async (
	id: string,
	payload: UpdatePermissionRoleDto
) => {
	const { data } = await axios.put('/api/v2/permissions/' + id, payload)
	return data
}

const removePermission = async (permissionId: string) => {
	const { data } = await axios.delete(`/api/v2/permissions/${permissionId}`)
	return data
}

const PermissionsService = {
	create,
	fetch,
	fetchAll,
	fetchResourcePermissions,
	fetchUserPermissions,
	updatePermissionRole,
	removePermission,
}

export default PermissionsService
