import React from 'react'

import { Badge, Box, Link, styled, Typography } from '@mui/material'
import type { Row } from '@tanstack/react-table'
import type { AssetTableEntryDTO } from 'api/repository/repositorySchemas'
import { ExpanderCell } from 'components/shared/AstaTable/AstaTable'
import type { FC } from 'react'
import type { AssetWithParent } from 'shared/types'

const StyledLink = styled(Link)(({ theme }) => ({
	cursor: 'pointer',
	color: theme.palette.text.primary,
}))

const GROUP_NAME_TO_LABEL: Record<string, string> = {
	'functional-rule': 'Functional',
	'performance-rule': 'Performance',
	'accessibility-rule': 'Accessibility',
	'resource-rule': 'Resource',
}

interface Props {
	row: Row<AssetTableEntryDTO>
	rowOnClick: (asset: AssetWithParent) => void
}

export const NameCell: FC<Props> = ({ row, rowOnClick }) => {
	const { isGroup, asset, groupName } = row.original

	const gName =
		isGroup && Array.isArray(groupName)
			? groupName.length > 0
				? groupName.join(' - ')
				: 'Untagged'
			: typeof groupName === 'string' && groupName
				? groupName
				: 'Untagged'

	return (
		<div>
			<ExpanderCell row={row} depth={0}>
				{isGroup ? (
					<Box
						display="flex"
						justifyContent="flex-start"
						alignItems="center"
						width="100%"
					>
						<Badge>{GROUP_NAME_TO_LABEL[gName] || gName}</Badge>
						<Typography pl={2}>
							{row.original.subRows.length}
						</Typography>
					</Box>
				) : (
					<StyledLink onClick={() => rowOnClick(row.original.asset)}>
						{asset.name}
					</StyledLink>
				)}
			</ExpanderCell>
		</div>
	)
}
