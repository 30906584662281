import React from 'react'

import { Button, Grid, Stack, styled, Typography } from '@mui/material'

const ErrorPage = () => {
	return (
		<FullGrid
			container
			direction="column"
			justifyContent="center"
			alignItems="center"
		>
			<Stack spacing={2} mb={4}>
				<Typography variant="h1">Something went WRONG!</Typography>
			</Stack>
			<Button variant="contained" color="primary" href="/">
				Back to Homepage
			</Button>
		</FullGrid>
	)
}
const FullGrid = styled(Grid)`
	text-align: center;
	height: 100vh;
`

export default ErrorPage
