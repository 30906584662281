import React, { useEffect } from 'react'

import { Button, Grid, Stack, Typography } from '@mui/material'
import Loader from 'components/shared/loader'
import { useSelectedApp } from 'contexts/selectedApp'
import { useWorkspaceContext } from 'contexts/workspace'
import { useNavigate } from 'react-router-dom'
import { ADMIN_ROUTES } from 'routes/routes'

const ConfigPage = () => {
	const { workspaces } = useWorkspaceContext()
	const { selectableVariants } = useSelectedApp()
	const navigate = useNavigate()

	useEffect(() => {
		if (selectableVariants?.length > 0) {
			navigate(`/app/${selectableVariants[0]._id}`)
		}
	}, [selectableVariants])

	if (!workspaces || workspaces.length === 0) {
		return (
			<Grid
				container
				direction="column"
				justifyContent="center"
				alignItems="center"
				sx={{ textAlign: 'center', height: '100vh' }}
			>
				<Stack spacing={2} mb={4} width="50%">
					<Typography variant="h1">
						There are no workspaces available, purchase a
						subscription to get one
					</Typography>
				</Stack>
			</Grid>
		)
	}
	if (!selectableVariants || selectableVariants.length === 0) {
		return (
			<Grid
				container
				direction="column"
				justifyContent="center"
				alignItems="center"
				sx={{ textAlign: 'center', height: '100vh' }}
			>
				<Stack spacing={2} mb={4} width="50%">
					<Typography variant="h1">
						You have not configured your application yet, please
						create an application and a variant to start using ASTA.
					</Typography>
				</Stack>
				<Button
					variant="contained"
					color="primary"
					onClick={() =>
						navigate(ADMIN_ROUTES.workspace.display().url)
					}
				>
					Configure Application and Variant
				</Button>
			</Grid>
		)
	}

	return (
		<Grid
			container
			direction="column"
			justifyContent="center"
			alignItems="center"
			sx={{ textAlign: 'center', height: '100vh' }}
		>
			<Loader />
		</Grid>
	)
}

export default ConfigPage
