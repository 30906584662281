import type {
	CreateBApplicationDTO,
	UpdateBApplicationDTO,
	UpdateTagDto,
} from '@asta/repository-dtos'

import axios from './axios-base'

const create = async (app: CreateBApplicationDTO) => {
	const { data } = await axios.post('/api/v2/applications/', app)
	return data
}

const fetchAll = async () => {
	const { data } = await axios.get('/api/v2/applications/')
	return data
}

const fetchAppsWithWorkspaceId = async (workspaceId: string) => {
	const { data } = await axios.get(
		'/api/v2/applications/parent/' + workspaceId
	)
	return data
}

const fetch = async (id: string) => {
	const { data } = await axios.get('/api/v2/applications/' + id)
	return data
}

const update = async (id: string, app: UpdateBApplicationDTO) => {
	const { data } = await axios.put('/api/v2/applications/' + id, app)
	return data
}

const addTag = async (id: string, tag: string) => {
	const { data } = await axios.post(`/api/v2/applications/${id}/tag`, {
		name: tag,
	})
	return data
}

const updateTag = async (id: string, tag: UpdateTagDto) => {
	const { data } = await axios.put(`/api/v2/applications/${id}/tag`, tag)
	return data
}

const removeTag = async (appId: string, tagId: string) => {
	const { data } = await axios.delete(
		`/api/v2/applications/${appId}/tag/${tagId}`
	)
	return data
}

const BApplicationService = {
	create,
	fetch,
	fetchAll,
	update,
	fetchAppsWithWorkspaceId,
	addTag,
	updateTag,
	removeTag,
}

export default BApplicationService
