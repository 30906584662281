import type { Variant } from 'shared/types'

import type { SelectedAppState } from './'

type SelectedAppActionType =
	| { type: '[SelectedApp] - Set selected app'; payload: Variant }
	| { type: '[SelectedApp] - Remove selected app' }

export const selectedAppReducer = (
	state: SelectedAppState,
	action: SelectedAppActionType
): SelectedAppState => {
	switch (action.type) {
		case '[SelectedApp] - Set selected app':
			return {
				...state,
				selectedApp: action.payload,
			}

		case '[SelectedApp] - Remove selected app':
			return {
				...state,
				selectedApp: undefined,
			}

		default:
			return state
	}
}
