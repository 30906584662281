import type { ApplicationModelUpdate, ModelNode } from '@asta/core'

import { get, post } from '../base'

/******************************* STATE ****************************************/
export type AppModelState = {
	models: ApplicationModel[]
	appModel: ApplicationModel | null
	pending: boolean
	indexesAreStale: boolean
	error: any
	selectedEntry: any | null
	createComponent: {
		displayCreateComponentPanel: boolean
		parentComponent: any | null
	}
}

export const modelInitialState: AppModelState = {
	models: [],
	appModel: null,
	indexesAreStale: false,
	pending: false,
	error: null,
	selectedEntry: null,
	createComponent: {
		displayCreateComponentPanel: false,
		parentComponent: null,
	},
}

export type ApplicationModelDTO = {
	applicationId: string
	nodes: NodeDTO[]
	edges: EdgeDTO[]
	nodeIndex: Record<string, NodeDTO>
	edgeIndex: Record<string, EdgeDTO>
}

type NodeDTO = {
	id: string
	applicationId: string
	name: string
	type: string
	data: Record<string, string>
}

type EdgeDTO = {
	id: string
	applicationId: string
	type: string
	from: string
	to: string
	data: Record<string, string>
}

export type ApplicationModel = {
	applicationId: string
	nodes: any[]
	edges: any[]
}

/***************************** SELECTORS **************************************/

export const modelsSelector = (state: any) => state.model.models
export const appModelSelector = (state: any) => state.model.appModel
export const componentSelector = (
	state: any,
	appId: string,
	compId: string
): NodeDTO | null =>
	state.model.models
		.find(m => m.applicantId == appId)
		.nodes.find(n => n.id == compId)
export const coverageSelector = (state: any) => state.model.coverage
export const getAppModelPendingSelector = (state: any) => state.model.pending
export const getAppModelErrorSelector = (state: any) => state.model.error
export const selectedEntrySelector = (state: any) => state.model.selectedEntry
export const displayCreateComponentPanel = (state: any) =>
	state.model.createComponent.displayCreateComponentPanel
export const createComponentParentComponent = (state: any) =>
	state.model.createComponent.parentComponent

/****************************** ACTIONS ***************************************/

export type GetAppModelActionTypes =
	| GetAppModelPendingAction
	| GetAppModelSuccessAction
	| GetAppModelErrorAction
	| SetSelectedEntryAction
	| SetShowCreateComponentPanel
	| SetCreateComponentParent
	| UpdateModelAction

interface GetAppModelPendingAction {
	type: typeof GET_APP_MODEL_PENDING
}
interface GetAppModelSuccessAction {
	type: typeof GET_APP_MODEL_SUCCESS
	appModel: ApplicationModel
}
interface GetAppModelErrorAction {
	type: typeof GET_APP_MODEL_ERROR
	error: any
}

interface SetSelectedEntryAction {
	type: typeof SET_SELECTED_ENTRY
	e: any
}

interface SetShowCreateComponentPanel {
	type: typeof SET_SHOW_CREATE_COMPONENT_PANEL
	show: boolean
}
interface SetCreateComponentParent {
	type: typeof SET_CREATE_COMPONENT_PARENT
	parent: any
}

export const GET_APP_MODEL_PENDING = 'GET_APP_MODEL_PENDING'
export const GET_APP_MODEL_SUCCESS = 'GET_APP_MODEL_SUCCESS'
export const GET_APP_MODEL_ERROR = 'GET_APP_MODEL_ERROR'

export const SET_SELECTED_ENTRY = 'SET_SELECTED_ENTRY'

export const SET_SHOW_CREATE_COMPONENT_PANEL = 'SET_SHOW_CREATE_COMPONENT_PANEL'
export const SET_CREATE_COMPONENT_PARENT = 'SET_CREATE_COMPONENT_PARENT'

export function getAppModelPendingAction() {
	return {
		type: GET_APP_MODEL_PENDING,
	}
}
export function getAppModelSuccessAction(appModel: ApplicationModel[]) {
	return {
		type: GET_APP_MODEL_SUCCESS,
		appModel,
	}
}
export function getAppModelErrorAction(error: any) {
	return {
		type: GET_APP_MODEL_ERROR,
		error,
	}
}

export function setEntry(e: any) {
	return async (dispatch: any) => {
		dispatch({ type: SET_SELECTED_ENTRY, e })
	}
}

/**
 * UPDATE_MODEL ACTION
 */

export interface UpdateModelAction {
	type: typeof UPDATE_MODEL
	updates: ModelUpdate[]
}
export type ModelUpdate = {
	type: 'delete' | 'update'
	componentId: string | null
	relationshipId: string | null
	component?: ModelNode
}
export const UPDATE_MODEL = 'UPDATE_MODEL'
export function updateModel(updates: ModelUpdate[]) {
	return { type: UPDATE_MODEL, updates }
}

/***************************** REQUESTS ***************************************/
export function getAppModelRequest() {
	return async (dispatch: any) => {
		const appId = '0'
		dispatch(getAppModelPendingAction())
		get(`/api/v2/variants/${appId}/model`)
			.then(res => res.json())
			.then(model => {
				if (model.error) throw model.error
				dispatch(getAppModelSuccessAction(model))
			})
			.catch(error => dispatch(getAppModelErrorAction(error)))
	}
}

export function updateApplicationModel(
	appId: string,
	updates: ApplicationModelUpdate[]
) {
	return async () => {
		const req = { applicationId: appId, updates }
		post(`/api/v2/variants/${appId}/model/update`, req)
			.then(res => res.json())
			.then(body => {
				if (body.error) throw body.error
			})
	}
}

export function hideCreateComponentPanel() {
	return async (dispatch: any) => {
		dispatch({ type: SET_SHOW_CREATE_COMPONENT_PANEL, show: false })
	}
}
export function showCreateComponentPanel() {
	return async (dispatch: any) => {
		dispatch({ type: SET_SHOW_CREATE_COMPONENT_PANEL, show: true })
	}
}
export function setCreateComponentParent(parent: any) {
	return async (dispatch: any) => {
		dispatch({ type: SET_CREATE_COMPONENT_PARENT, parent })
	}
}

/****************************** REDUCER ***************************************/
export function modelReducer(
	state = modelInitialState,
	action: GetAppModelActionTypes
): AppModelState {
	let createComponent = state.createComponent

	switch (action.type) {
		case GET_APP_MODEL_PENDING:
			return { ...state, pending: true }

		case GET_APP_MODEL_SUCCESS:
			return {
				...state,
				pending: false,
				appModel: action.appModel,
				error: null,
			}

		case GET_APP_MODEL_ERROR:
			return { ...state, pending: false, error: action.error }

		case UPDATE_MODEL:
			const model = { ...state.appModel }
			// Assume all updates are deletes
			if (
				action.updates.length > 0 &&
				action.updates[0].type === 'delete'
			) {
				const edgesToDelete = action.updates
					.filter(u => u.relationshipId != null)
					.map(u => u.relationshipId)
				const nodesToDelete = action.updates
					.filter(u => u.componentId != null)
					.map(u => u.componentId)
				model.edges = model.edges.filter(
					e => !edgesToDelete.includes(e.id)
				)
				model.nodes = model.nodes.filter(
					n => !nodesToDelete.includes(n.id)
				)
			}
			// Assume all updates are component updates
			if (
				action.updates.length > 0 &&
				action.updates[0].type === 'update'
			) {
				const updatedComponent = action.updates[0].component
				model.nodes = model.nodes.map(n => {
					if (n.id === updatedComponent.id) return updatedComponent
					return n
				})
			}
			return { ...state, appModel: model, indexesAreStale: true }

		case SET_SELECTED_ENTRY:
			return { ...state, selectedEntry: action.e }

		case SET_SHOW_CREATE_COMPONENT_PANEL:
			createComponent = state.createComponent
			createComponent.displayCreateComponentPanel = action.show
			return { ...state, createComponent }

		case SET_CREATE_COMPONENT_PARENT:
			createComponent = state.createComponent
			createComponent.parentComponent = action.parent
			return { ...state, createComponent }

		default:
			return state
	}
}
