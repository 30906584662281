import type { Issue } from '@asta/core'

import type { BaseResourceState } from './base/base'
import {
	buildResourceReducer,
	createOne,
	deleteOne,
	fetchAll,
	fetchOne,
	initialBaseResourceState,
	RESOURCE,
	updateOne,
} from './base/base'

type IssuesState = BaseResourceState<Issue>

export const initialState: IssuesState = {
	...initialBaseResourceState,
}

export const selectIssues = {
	all: (state: any): Issue[] => state.issues.resources,
	byId: (state: any, id: string): Issue | null =>
		state.issues.resources.find(i => i._id == id),
	forEntry: (state: any, run: string, entry: number): Issue[] =>
		state.issues.resources.filter((iss: Issue) =>
			iss.logs?.find(l => l.run == run && l.entry == entry)
		),
}

export const IssuesService = {
	fetchAll: () => fetchAll(RESOURCE.ISSUES),
	fetch: (id: string) => fetchOne(RESOURCE.ISSUES, id),
	delete: (id: string) => deleteOne(RESOURCE.ISSUES, id),
	create: (issue: Issue) => createOne(RESOURCE.ISSUES, issue),
	update: (issue: Issue) => updateOne(RESOURCE.ISSUES, issue),
}

export const issuesReducer = buildResourceReducer(initialState, RESOURCE.ISSUES)
