import React, { useCallback, useContext, useEffect, useMemo } from 'react'

import { useMediaQuery, useTheme } from '@mui/material'
import type {
	FormSpec,
	FormSpecWithPopulatedParentAndTags,
	UpdateAssetDto,
} from 'api/repository/repositorySchemas'
import TestAssetsTable from 'components/feature/TestAsset/TestAssetsTable'
import { useBreadcrumbs } from 'contexts/breadcrumbs'
import { SelectedAppContext } from 'contexts/selectedApp'
import { usePageTitle } from 'hooks'
import { canCreate } from 'pages/Admin/helpers'
import { TEST_CENTER_ROUTES } from 'routes/routes'
import type { AssetWithParent, CreateAsset, Resource, Tag } from 'shared/types'

import { SelectedRowsContextProvider } from './context/SelectedRowsContext'
import FormSpecEditorContainer from './FormSpec/FormSpecEditorContainer'
import { useAssetPageState } from './hooks/useAssetPageState'
import AssetIconButton from './shared/AssetIconButton'

const _FormSpecsPage: React.FC = () => {
	usePageTitle('Form Specs')

	const theme = useTheme()
	const matchDownMD = useMediaQuery(theme.breakpoints.down('md'))

	const { setBreadcrumbs } = useBreadcrumbs()
	useEffect(() => {
		setBreadcrumbs(TEST_CENTER_ROUTES['form-specs'].breadcrumbs())
	}, [])

	const { selectedApp } = useContext(SelectedAppContext)

	const {
		selectedAssets,
		editingAssetId,
		isCreating,
		create,
		update,
		deleteAsset,
		asset: formSpec,
		onRowClick,
		onCreate,
		onClose,
		tags,
	} = useAssetPageState({
		selectedAppId: selectedApp?._id,
		assetType: 'form-spec',
	})

	const createFormSpec = useCallback(
		(newAsset: CreateAsset) => {
			create({
				pathParams: {
					appId:
						typeof newAsset.parent === 'string'
							? newAsset.parent
							: (newAsset.parent as Resource)._id,
				},
				body: newAsset,
			})
		},
		[create]
	)

	const updateFormSpec = useCallback(
		(asset: FormSpec, tags?: string[]) => {
			const formattedAsset = tags
				? {
						...asset,
						tags,
					}
				: { ...asset, tags: asset.tags.map((tag: Tag) => tag._id) }
			update({
				pathParams: {
					id: formattedAsset._id,
					appId:
						typeof asset.parent === 'string'
							? asset.parent
							: asset.parent._id,
				},
				body: {
					...formattedAsset,
					parent:
						typeof asset.parent === 'string'
							? asset.parent
							: asset.parent._id,
				} satisfies UpdateAssetDto,
			})
		},
		[update]
	)

	const deleteFormSpec = useCallback(
		(formSpec: AssetWithParent) => {
			deleteAsset({
				pathParams: {
					id: formSpec._id,
					appId: formSpec.parent._id,
				},
			})
		},
		[deleteAsset]
	)

	const tableActions = useMemo(
		() => [
			canCreate(selectedApp?.role) && (
				<AssetIconButton
					key="create-form-spec-button"
					variant="contained"
					onClick={onCreate}
					type="create"
					text={matchDownMD ? 'Form Spec' : 'New Form Spec'}
				/>
			),
		],
		[onCreate, selectedApp?.role, matchDownMD]
	)

	return (
		<>
			{(isCreating || !!editingAssetId) && (
				<FormSpecEditorContainer
					key={formSpec?._id ?? 'rule-editor-container'}
					asset={formSpec as FormSpecWithPopulatedParentAndTags}
					update={updateFormSpec}
					create={createFormSpec}
					isVisible={isCreating || !!editingAssetId}
					isEditing={!!editingAssetId}
					onClose={onClose}
					selectedApp={selectedApp}
					availableTags={tags}
				/>
			)}
			<TestAssetsTable
				key="form-specs-table"
				type="form-spec"
				update={updateFormSpec}
				rowOnClick={onRowClick}
				actions={tableActions}
				deleteAsset={deleteFormSpec}
				tags={tags}
				selectedAssets={selectedAssets}
			/>
		</>
	)
}

const FormSpecsPage: React.FC = () => {
	return (
		<SelectedRowsContextProvider>
			<_FormSpecsPage />
		</SelectedRowsContextProvider>
	)
}

export default FormSpecsPage
