import type {
	CreatePermissionDto,
	RoleType,
	UpdatePermissionRoleDto,
} from '@asta/repository-dtos'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import PermissionsService from './PermissionService'

interface Permission {
	_id: string
	resource: {
		_id: string
	}
	user: {
		_id: string
		email: string
	}
	role: RoleType
}

interface PermissionsState {
	permissions: Permission[]
	status: 'loading' | 'finished' | 'error'
}

export const permissionsInitialState: PermissionsState = {
	permissions: [],
	status: 'loading',
}

export const createPermission = createAsyncThunk(
	'permission/create',
	async (permission: CreatePermissionDto) => {
		const res = await PermissionsService.create(permission)
		return res.data.data
	}
)

export const updatePermissionRole = createAsyncThunk(
	'permission/updateRole',
	async (up: { id: string; payload: UpdatePermissionRoleDto }) => {
		const res = await PermissionsService.updatePermissionRole(
			up.id,
			up.payload
		)
		return res.data.data
	}
)

export const fetchResourcePermissions = createAsyncThunk(
	'permission/resource',
	async (resourceId: string) => {
		const res =
			await PermissionsService.fetchResourcePermissions(resourceId)
		return res.data.data
	}
)

export const fetchPermissions = createAsyncThunk(
	'permission/getAll',
	async () => {
		const res = await PermissionsService.fetchAll()
		return res.data.data
	}
)

export const fetchUserPermissions = createAsyncThunk(
	'permission/user',
	async (userId: string) => {
		const res = await PermissionsService.fetchUserPermissions(userId)
		return res.data.data
	}
)

export const removePermission = createAsyncThunk(
	'permission/remove',
	async (permissionId: string) => {
		const res = await PermissionsService.removePermission(permissionId)
		return res.data.data
	}
)

const permissionSlice = createSlice({
	name: 'permission',
	initialState: permissionsInitialState,
	extraReducers: builder => {
		builder.addCase(createPermission.fulfilled, (state, action) => {
			return {
				...state,
				permissions: [...state.permissions, action.payload],
				status: 'finished',
			}
		}),
			builder.addCase(createPermission.pending, (state, _action) => {
				return {
					...state,
					status: 'loading',
				}
			})

		builder.addCase(updatePermissionRole.fulfilled, (state, action) => {
			return {
				...state,
				permissions: state.permissions.map(el =>
					el._id === action.payload._id ? action.payload : el
				),
				status: 'finished',
			}
		}),
			builder.addCase(updatePermissionRole.pending, (state, _action) => {
				return {
					...state,
					status: 'loading',
				}
			})

		builder.addCase(fetchResourcePermissions.fulfilled, (state, action) => {
			return {
				...state,
				permissions: [...action.payload],
				status: 'finished',
			}
		}),
			builder.addCase(fetchResourcePermissions.pending, state => {
				return {
					...state,
					status: 'loading',
				}
			})

		builder.addCase(fetchUserPermissions.fulfilled, (state, action) => {
			return {
				...state,
				permissions: [...action.payload],
				status: 'finished',
			}
		}),
			builder.addCase(fetchUserPermissions.pending, (state, _action) => {
				return {
					...state,
					status: 'loading',
				}
			})

		builder.addCase(removePermission.fulfilled, (state, action) => {
			return {
				...state,
				permissions: state.permissions.filter(
					el => el._id !== action.payload._id
				),
				status: 'finished',
			}
		})

		builder.addCase(fetchPermissions.fulfilled, (state, action) => {
			return {
				...state,
				permissions: action.payload,
				status: 'finished',
			}
		})
		builder.addCase(fetchPermissions.pending, (state, _action) => {
			return {
				...state,
				status: 'loading',
			}
		})
	},
	reducers: undefined,
})

const { reducer } = permissionSlice
export default reducer
