import { useMemo } from 'react'

import { isEqual } from 'lodash'

import type { CustomFilterShape } from '../CustomFilter'
import type { OperatorAndValue } from '../TableFilters'

export const parseBoolean = (
	obj: Record<string, string | boolean>
): Record<string, boolean> => {
	const objCopy: Record<string, boolean> = {}
	for (const prop in obj) {
		objCopy[prop] = obj[prop] === 'true' || obj[prop] === true
	}

	return objCopy
}

/**
 * Formats the columnVisibility according to the following priority:
 * Overrides from URL > ViewColumnVisibility > Default
 */
export const useFormatColumnVisibilityState = <T extends { id?: string }>(
	defaultColumns: T[],
	columnVisibility: Record<string, boolean>
): Record<string, boolean> =>
	useMemo(
		() => formatColumnVisibilityState(defaultColumns, columnVisibility),
		[defaultColumns, columnVisibility]
	)
/**
 * Formats the columnVisibility according to the following priority:
 * Overrides from URL > ViewColumnVisibility > Default
 */
export const formatColumnVisibilityState = <T extends { id?: string }>(
	defaultColumns: T[],
	columnVisibility: Record<string, boolean>
): Record<string, boolean> =>
	defaultColumns.reduce((acc, { id }) => {
		if (id in columnVisibility) {
			acc[id] = columnVisibility[id]
		} else {
			acc[id] = true
		}
		return acc
	}, {})

/**
 * Remove object entries with value = to '*'
 */
export const removeEmptyOverrides = (
	obj: CustomFilterShape
): CustomFilterShape => {
	const objCopy = { ...obj }
	for (const prop in objCopy) {
		if (Object.values(obj[prop])[0] === '*') {
			delete objCopy[prop]
		}
	}

	return objCopy
}

/**
 * Returns null if string === '*'
 */
export const removeStringOverride = (value: string): string => {
	if (value === '*') return null
	return value
}

/**
 * Remove object properties with value = to '*'
 */
export const removeEmptyOperatorAndValueOverrides = <
	T extends Record<string, unknown>,
>(
	obj: T
): T =>
	Object.fromEntries(Object.entries(obj).filter(([_k, v]) => v !== '*')) as T

/**
 * Remove true values from obj\
 */

export const removeTrueValues = (obj: Record<string, boolean>) => {
	const objCopy = { ...obj }

	for (const prop in objCopy) {
		if (objCopy[prop]) {
			delete objCopy[prop]
		}
	}

	return objCopy
}

/**
 * Handles filter changes, comparing the newValues to the viewValues
 * and returning an object ready to set the URLValues to add overrides if needed
 */
export const handleFilterChanges = (
	newValues: CustomFilterShape,
	viewFilters: CustomFilterShape
): CustomFilterShape => {
	const cleanOverrides = { ...newValues }

	//Remove duplicate values, to only include in the URL different values (overrides)
	for (const prop in cleanOverrides) {
		if (
			viewFilters[prop] &&
			isEqual(cleanOverrides[prop], viewFilters[prop])
		) {
			delete cleanOverrides[prop]
		}
	}

	//Add remove overrides
	for (const prop in viewFilters) {
		if (!newValues[prop]) {
			const arrayObj = Object.entries(viewFilters[prop])[0]

			cleanOverrides[prop] = Object.fromEntries([[arrayObj[0], '*']])
		}
	}

	return cleanOverrides
}

/**
 * Handles column visibility changes, comparing the newValues to the viewValues
 * and returning an object ready to set the URLValues to add overrides if needed
 */
export const handleColumnVisibilityChanges = (
	newValues: Record<string, boolean>,
	viewColumnVisibility: Record<string, boolean>
): Record<string, boolean> => {
	const cleanOverrides = { ...newValues }

	//Only set overrides to the url
	for (const prop in cleanOverrides) {
		if (
			(!(prop in viewColumnVisibility) && cleanOverrides[prop]) ||
			cleanOverrides[prop] === viewColumnVisibility[prop]
		) {
			delete cleanOverrides[prop]
		}
	}

	return cleanOverrides
}

/**
 * Handles groupBy changes, comparing the newValues to the viewValues
 * and returning an object ready to set the URLValues to add overrides if needed
 */
export const handleGroupByChanges = (
	newValues: OperatorAndValue,
	viewGroupBy: OperatorAndValue
): OperatorAndValue => {
	const cleanOverrides = { ...newValues }

	//Remove duplicate values, to only include in the URL different values (overrides)
	for (const prop in cleanOverrides) {
		if (
			viewGroupBy &&
			viewGroupBy[prop] &&
			isEqual(cleanOverrides[prop], viewGroupBy[prop])
		) {
			delete cleanOverrides[prop]
		}
	}

	//Add remove overrides
	for (const prop in viewGroupBy) {
		if (!newValues || !(prop in newValues)) {
			cleanOverrides[prop] = '*'
		}
	}

	return cleanOverrides
}

/**
 * Handles sort changes, comparing the newValues to the viewValues
 * and returning a string ready to set the URLValues to add overrides if needed
 */
export const handleSortChanges = (newValue: string, viewSort: string) => {
	if (!newValue && viewSort) {
		return '*'
	} else if (newValue === viewSort) return
	return newValue
}
