import type { BaseResourceState } from '../base/base'
import {
	buildResourceReducer,
	fetchAll,
	initialBaseResourceState,
	RESOURCE,
} from '../base/base'

export type DriverDTO = {
	id: string
	name: string
	state: string
}

type DriversState = BaseResourceState<DriverDTO>

export const initialState: DriversState = {
	...initialBaseResourceState,
}

export const driversSelector = (state: any) => state.drivers.resources

export const getAllDrivers = () => fetchAll(RESOURCE.DRIVERS)
export const fetchDrivers = () => fetchAll(RESOURCE.DRIVERS)

export const driversReducer = buildResourceReducer(
	initialState,
	RESOURCE.DRIVERS
)
