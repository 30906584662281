import type { Workspace } from 'shared/types'

import type { WorkspaceState } from './'

type WorkspaceActionType = {
	type: '[Workspace] - Set selected workspace'
	payload: Workspace
}

export const workspaceReducer = (
	state: WorkspaceState,
	action: WorkspaceActionType
): WorkspaceState => {
	switch (action.type) {
		case '[Workspace] - Set selected workspace':
			return {
				workspace: action.payload,
			}

		default:
			return state
	}
}
