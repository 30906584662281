export type AlertsState = { text: string; level: string; ms: number }[]

export const alertsInitialState: AlertsState = []

const ADD_ALERT = 'ADD_ALERT'
const CLEAR_ALERTS = 'CLEAR_ALETRS'

export const alertsSelector = (
	s: any
): { text: string; level: string; ms: number }[] => s.alerts

export const alertsReducer = (
	state = alertsInitialState,
	action: any
): AlertsState => {
	switch (action.type) {
		case ADD_ALERT:
			return state.concat(action.alert)

		case CLEAR_ALERTS:
			return []

		default:
			return state
	}
}
