import React, { useMemo, useRef } from 'react'

import { useOutsideClick } from '@asta/react-component-library'
import { Box } from '@mui/material'
import type { FC } from 'react'

import type {
	SelectFilterDefinition,
	TextFilterDefinition,
} from '../TableFilters'
import { FilterList } from './FilterList'
import { OpenDialogButton } from './OpenDialogButton'
import type { CustomFilterShape } from './useFilters'
import { useFilters } from './useFilters'

interface Props {
	options: (SelectFilterDefinition | TextFilterDefinition)[]
	setTableFilters: (filters: CustomFilterShape) => void
	value: CustomFilterShape
}

const CustomFilter: FC<Props> = ({ setTableFilters, options, value }) => {
	const {
		filters,
		resetFilters,
		closeAndRemoveEmpty,
		open,
		toggleOpen,
		onFilterChange,
	} = useFilters(value, setTableFilters)

	const ref = useRef(null)
	useOutsideClick(closeAndRemoveEmpty, ref)

	const filteredByColumns = useMemo(
		() =>
			options
				.map(({ column }) => column)
				.reduce(
					(acc, curr) => ({ ...acc, [curr.value]: curr.label }),
					{}
				),
		[options]
	)

	return (
		<Box>
			<OpenDialogButton
				resetFilters={resetFilters}
				toggleOpen={toggleOpen}
				filters={value}
				columns={filteredByColumns}
			/>
			{open && (
				<Box ref={ref}>
					<FilterList
						open={open}
						closeAndRemoveEmpty={closeAndRemoveEmpty}
						filters={filters}
						options={options}
						onFilterChange={onFilterChange}
					/>
				</Box>
			)}
		</Box>
	)
}

export default CustomFilter
export { CustomFilterShape }
