import {
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
} from 'react'

import type { Row } from '@tanstack/react-table'
import type { AssetTableEntryDTO } from 'api/repository/repositorySchemas'
import type { Context, FC, PropsWithChildren } from 'react'
import type { Asset } from 'shared/types'

export interface SelectedRowsContextProps {
	setSelectedRows: (rows: Row<AssetTableEntryDTO>[]) => void
	getSelectedAssets: () => Asset[]
}

export const SelectedRowsContext = createContext({} as SelectedRowsContextProps)

export const SelectedRowsContextProvider: FC<PropsWithChildren> = ({
	children,
}) => {
	const [selectedRows, setSelectedRows] =
		useState<Row<AssetTableEntryDTO>[]>(null)

	const getSelectedAssets: () => Asset[] = useCallback(() => {
		if (!selectedRows) return []

		return selectedRows
			.map(({ original }) => {
				if (!original || original.isGroup) return null
				return original.asset
			})
			.filter(Boolean)
	}, [selectedRows])

	const contextValue = useMemo(
		() => ({
			setSelectedRows,
			getSelectedAssets,
		}),
		[getSelectedAssets]
	)

	return (
		<SelectedRowsContext.Provider value={contextValue}>
			{children}
		</SelectedRowsContext.Provider>
	)
}

export const useSelectedRowsContext = (): SelectedRowsContextProps => {
	return useContext(SelectedRowsContext as Context<SelectedRowsContextProps>)
}
