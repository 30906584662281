//TODO (Camilo): We should import the enum from our repository.
//This is currently not possible because of an issue with nestjs, maybe the generated types can fix this??
export enum RoleType {
	none,
	viewer,
	reviewer,
	member,
	owner,
	admin,
}

export const canEdit = (role: RoleType): boolean => role >= RoleType.member
export const canManage = (role: RoleType): boolean => role >= RoleType.owner
export const canCreate = (role: RoleType): boolean => role >= RoleType.member
//Members can remove in test center but not in admin center
export const canRemove = (role: RoleType): boolean => role >= RoleType.member
//Only owners can remove in admin center
export const canRemoveAdminCenter = (role: RoleType): boolean =>
	role >= RoleType.owner
export const isOwner = (role: RoleType): boolean => role === RoleType.owner
