import React, { useCallback, useMemo } from 'react'

import { FilterList } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Typography } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import type { FC } from 'react'

import type { CustomFilterShape } from './useFilters'

const MainButton = styled(IconButton, {
	shouldForwardProp: prop =>
		prop !== 'showSuccessColor' && prop !== 'successColor',
})<{ showSuccessColor: boolean; successColor: string }>(
	({ showSuccessColor, successColor }) => ({
		margin: 4,
		width: 'auto',
		...(showSuccessColor && {
			background: successColor,
		}),
	})
)

const ResetFilterButton = styled(CloseIcon)(() => ({
	ml: 4,
	fontSize: 12,
	color: '#0000008A',
}))

interface OpenDialogButtonProps {
	filters: CustomFilterShape
	toggleOpen: () => void
	resetFilters: () => void
	columns: Record<string, string>
}

export const OpenDialogButton: FC<OpenDialogButtonProps> = ({
	filters,
	toggleOpen,
	resetFilters: _resetFilters,
	columns,
}) => {
	const { palette } = useTheme()

	const hasFilters = useMemo(() => Object.keys(filters).length > 0, [filters])
	const filteredBy = useMemo(
		() =>
			Object.keys(filters)
				.map(filter => columns[filter] ?? filter)
				.join(', '),
		[filters, columns]
	)

	const resetFilters = useCallback(
		(e: React.MouseEvent) => {
			e.stopPropagation()
			_resetFilters()
		},
		[_resetFilters]
	)

	return (
		<MainButton
			role="button"
			aria-label="Open filter dialog"
			size="small"
			color="default"
			showSuccessColor={hasFilters}
			successColor={palette.success['50']}
			onClick={toggleOpen}
		>
			<FilterList />
			<Typography variant="body1" px={0.5}>
				{hasFilters ? `Filtered by ${filteredBy}` : 'Filter'}
			</Typography>
			{hasFilters && (
				<ResetFilterButton
					role="button"
					aria-hidden="false"
					aria-label="remove applied filters"
					onClick={resetFilters}
				/>
			)}
		</MainButton>
	)
}
