import React, { useCallback, useRef } from 'react'

import {
	SelectInput,
	useOutsideClick,
	useToggle,
} from '@asta/react-component-library'
import CloseIcon from '@mui/icons-material/Close'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import { Box, Collapse, IconButton, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { capitalize } from 'lodash'
import type { FC } from 'react'

import type { SortFilterShape } from '../TableFilters/filter-shapes'

const MainButton = styled(IconButton)(({ value }: { value: string }) => ({
	margin: 4,
	width: 'auto',
	color: '#0000008a',
	...(value && {
		background: '#FFE0CC',
	}),
}))

const ResetFilterButton = styled(CloseIcon)(() => ({
	ml: 6,
	fontSize: 12,
	color: '#0000008A',
}))

const Dialog = styled(Collapse)(() => ({
	display: 'flex',
	flexDirection: 'column',
	position: 'absolute',
	marginTop: 0,
	background: 'white',
	borderRadius: 4,
	padding: 20,
	boxShadow:
		'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
	zIndex: 11,
	minWidth: 300,
}))

const RemoveIconContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: 34,
	width: 34,
	border: `1px solid ${theme.palette.grey[400]}`,
	borderRadius: theme.shape.borderRadius,
	padding: 4,
}))

const RemoveRowButton = styled(DeleteOutlineOutlinedIcon)(() => ({
	fontSize: 16,
	cursor: 'pointer',
}))

export interface SortFilterOptions {
	label: string
	value: string
}

const SORT_OPTIONS = [
	{ label: 'A -> Z', value: null },
	{ label: 'Z -> A', value: '-' },
]

export const SortFilter: FC<Omit<SortFilterShape, 'type'>> = ({
	value,
	onChange,
	options,
}) => {
	const [open, toggleOpen, _on, close] = useToggle()
	const ref = useRef(null)
	useOutsideClick(close, ref)

	const isZtoA = value?.includes('-')
	const rawValue = value?.replace('-', '')
	const selectedOptionLabel =
		value && options?.length > 0
			? options.find(option => option.value === rawValue)?.label
			: null

	const resetFilters = useCallback(
		e => {
			e.stopPropagation()
			onChange(null)
			close()
		},
		[onChange, close]
	)

	const onSelect = useCallback(
		(column: string) => {
			onChange(column)
		},
		[onChange]
	)

	// Adds - if it's Z -> A
	const onSortChange = useCallback(
		(sortOption: string) => {
			if (!rawValue) return
			const valueWithSort = sortOption ? sortOption + rawValue : rawValue
			onChange(valueWithSort)
		},
		[onChange, rawValue]
	)

	if (!options || options.length === 0) return null

	return (
		<Box id="sort-filter" ref={ref} position="relative">
			<MainButton size="small" value={value} onClick={toggleOpen}>
				<SwapVertIcon />
				<Typography variant="body1" px={0.5}>
					{value
						? `Sorted by ${capitalize(selectedOptionLabel)}`
						: 'Sort'}
				</Typography>
				{value && (
					<ResetFilterButton
						id="remove-sort-filter"
						name="Remove sort filter"
						aria-label="Remove sort filter"
						aria-hidden="false"
						role="button"
						onClick={resetFilters}
					/>
				)}
			</MainButton>
			{open && (
				<Dialog role="dialog" in={open} timeout={75}>
					<Box key={`asta-hide-column-option-${value}`}>
						<Typography variant="body2" pb={2}>
							In this table, sort by
						</Typography>
						<Stack direction="row" spacing={1}>
							<SelectInput
								ariaLabel="Select option to sort"
								value={rawValue}
								options={options}
								onChange={onSelect}
							/>
							{value && (
								<>
									<SelectInput
										ariaLabel="Select order"
										value={isZtoA ? '-' : null}
										options={SORT_OPTIONS}
										onChange={onSortChange}
									/>
									<RemoveIconContainer
										role="button"
										aria-label="Remove current sort filter"
										onClick={resetFilters}
									>
										<RemoveRowButton />
									</RemoveIconContainer>
								</>
							)}
						</Stack>
					</Box>
				</Dialog>
			)}
		</Box>
	)
}
