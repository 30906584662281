// types
import assert from 'assert'

import type { DefaultConfigProps } from 'deprecated/types/config'

export const drawerWidth = 260

export const twitterColor = '#1DA1F2'
export const facebookColor = '#3b5998'
export const linkedInColor = '#0e76a8'

// this line must appear exactly like this. `process.env` is inlined by webpack,
// so every time it's referenced there's just like this massive object, so we
// stick it here once, and access what we need
// const env = process.env
// export const AWS_API = {
// 	poolId: 'us-east-1_AOfOTXLvD',
// 	appClientId: '3eau2osduslvb7vks3vsh9t7b0',
// }
export type Config = {
	auth: {
		/**
		 * The AWS Cognito User Pool ID
		 */
		poolId: string
		/**
		 * The AWS Cognito App Client ID
		 */
		appClientId: string
	}
	/**
	 * `NODE_ENV` value
	 */
	nodeEnv: string
	deployment: string
	stripePublicApiKey: string
	stripeCustomerPortalLink: string
	services: {
		oss: {
			url: string
		}
		repository: {
			url: string
		}
	}
}
const config: Config = {
	auth: {
		poolId: process.env.PUBLIC_AWS_COGNITO_POOL_ID,
		appClientId: process.env.PUBLIC_AWS_COGNITO_APP_CLIENT_ID,
	},
	nodeEnv: process.env.NODE_ENV,
	deployment: process.env.DEPLOYMENT,
	stripePublicApiKey: process.env.PUBLIC_STRIPE_PUBLIC_KEY,
	stripeCustomerPortalLink: process.env.PUBLIC_STRIPE_CUSTOMER_PORTAL_LINK,
	services: {
		oss: {
			url: process.env.PUBLIC_OSS_URL,
		},
		repository: {
			url: process.env.PUBLIC_REPOSITORY_URL,
		},
	},
}

// accessing `process.env.NODE_ENV` directly is fine because it's inlined
// separately from `process.env`
if (process.env.NODE_ENV === 'development') {
	assert(config.auth.poolId, 'PUBLIC_AWS_COGNITO_POOL_ID is required')
	assert(
		config.auth.appClientId,
		'PUBLIC_AWS_COGNITO_APP_CLIENT_ID is required'
	)
	assert(config.services.oss.url, 'PUBLIC_OSS_URL is required')
	assert(config.services.repository.url, 'PUBLIC_REPOSITORY_URL is required')
	assert(
		config.stripeCustomerPortalLink,
		'STRIPE_CUSTOMER_PORTAL_LINK is required'
	)
	// todo: runtime checks
}

// ==============================|| THEME CONFIG  ||============================== //

const mantisConfig: DefaultConfigProps = {
	defaultPath: '/',
	fontFamily: "'Public Sans', sans-serif",
	i18n: 'en',
	miniDrawer: false,
	container: true,
	mode: 'light',
	presetColor: 'default',
	themeDirection: 'ltr',
}

export { config, mantisConfig }
