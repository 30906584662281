import React, { useEffect, useReducer, useState } from 'react'

import type { RunStatusDTO } from '@asta/repository-dtos'
import type { FC, PropsWithChildren } from 'react'

import { RunTimerContext } from './RunTimerContext'
import { runTimerReducer } from './RunTimerReducer'

export interface RunTimerState {
	initialTime: number
	runState: RunStatusDTO['runningState']
	formattedTime: string
}

const RUN_TIMER_INITIAL_STATE: RunTimerState = {
	initialTime: 0,
	runState: 'stopped',
	formattedTime: '0',
}

export type RunTimerProviderProps = {}

export const RunTimerProvider: FC<PropsWithChildren<RunTimerProviderProps>> = ({
	children,
}) => {
	const [state, dispatch] = useReducer(
		runTimerReducer,
		RUN_TIMER_INITIAL_STATE
	)

	const { runState, initialTime } = state

	const [time, setTime] = useState(initialTime)
	const [isRunning, setIsRunning] = useState(false)

	const shouldPauseTimer = ['stopped', 'finished', 'paused'].includes(
		runState
	)

	useEffect(() => {
		setIsRunning(!shouldPauseTimer)
	}, [shouldPauseTimer])

	useEffect(() => {
		let intervalId
		if (isRunning) {
			intervalId = setInterval(() => setTime(time + 1), 10)
		}
		return () => clearInterval(intervalId)
	}, [isRunning, time])

	const hours = Math.floor(time / 360000)
	const minutes = Math.floor((time % 360000) / 6000)
	const seconds = Math.floor((time % 6000) / 100)

	const updateRunState = (state: RunStatusDTO['runningState']) => {
		//Reset timer for new runs
		if (state === 'running' && ['stopped', 'finished'].includes(runState)) {
			setTime(0)
		}
		dispatch({ type: '[RunTimer] - Update run status', payload: state })
	}

	const setInitialTime = (initialTime: number) => {
		setTime(initialTime)
		dispatch({
			type: '[RunTimer] - Set initial time',
			payload: initialTime,
		})
	}

	return (
		<RunTimerContext.Provider
			value={{
				...state,
				formattedTime: `${hours.toString().padStart(2, '0')}:${minutes
					.toString()
					.padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`,
				updateRunState,
				setInitialTime,
			}}
		>
			{children}
		</RunTimerContext.Provider>
	)
}
