import {
	useDatasetControllerFindAllTableData,
	useFlowControllerFindAllTableData,
	useFormSpecControllerFindAllTableData,
	useRuleControllerFindAllTableData,
	useRunTemplateControllerFindAllTableData,
} from 'api/repository/repositoryComponents'

type DatasetHook = typeof useDatasetControllerFindAllTableData
type FlowHook = typeof useFlowControllerFindAllTableData
type FormSpecHook = typeof useFormSpecControllerFindAllTableData
type RuleHook = typeof useRuleControllerFindAllTableData
type RunTemplateHook = typeof useRunTemplateControllerFindAllTableData

type HookTypeMap = {
	dataset: DatasetHook
	'form-spec': FormSpecHook
	rule: RuleHook
	'run-template': RunTemplateHook
	flow: FlowHook
}

export const typeToHook: HookTypeMap = {
	dataset: useDatasetControllerFindAllTableData,
	'form-spec': useFormSpecControllerFindAllTableData,
	rule: useRuleControllerFindAllTableData,
	'run-template': useRunTemplateControllerFindAllTableData,
	flow: useFlowControllerFindAllTableData,
}

export const useAssetTableData = <K extends keyof HookTypeMap>(
	type: K
): HookTypeMap[K] => {
	const hook = typeToHook[type]
	if (!hook) throw new Error('There is no hook for this asset type')
	return hook
}
