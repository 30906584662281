import { useQueryClient } from '@tanstack/react-query'
import {
	useDatasetControllerCreate,
	useDatasetControllerFindAll,
	useDatasetControllerFindOne,
	useDatasetControllerRemove,
	useDatasetControllerUpdate,
	useDatasetControllerUpdateParent,
	useFlowControllerCreate,
	useFlowControllerFindAll,
	useFlowControllerFindOne,
	useFlowControllerRemove,
	useFlowControllerUpdate,
	useFlowControllerUpdateParent,
	useFormSpecControllerCreate,
	useFormSpecControllerFindAll,
	useFormSpecControllerFindOne,
	useFormSpecControllerRemove,
	useFormSpecControllerUpdate,
	useFormSpecControllerUpdateParent,
	useRuleControllerCreate,
	useRuleControllerFindAll,
	useRuleControllerFindOne,
	useRuleControllerRemove,
	useRuleControllerUpdate,
	useRuleControllerUpdateParent,
	useRunTemplateControllerCreate,
	useRunTemplateControllerFindAll,
	useRunTemplateControllerFindOne,
	useRunTemplateControllerRemove,
	useRunTemplateControllerUpdate,
	useRunTemplateControllerUpdateParent,
} from 'api/repository/repositoryComponents'
import { toast } from 'react-hot-toast'
import type { Asset } from 'shared/types'

const typeToFormatted: Record<Asset['type'], string> = {
	dataset: 'Dataset',
	'form-spec': 'Form Spec',
	rule: 'Rule',
	'run-template': 'Run Template',
	flow: 'Flow',
}

type AssetHooks = {
	dataset: {
		findAll: typeof useDatasetControllerFindAll
		findOne: typeof useDatasetControllerFindOne
		create: typeof useDatasetControllerCreate
		update: typeof useDatasetControllerUpdate
		remove: typeof useDatasetControllerRemove
		updateParent: typeof useDatasetControllerUpdateParent
	}
	'form-spec': {
		findAll: typeof useFormSpecControllerFindAll
		findOne: typeof useFormSpecControllerFindOne
		create: typeof useFormSpecControllerCreate
		update: typeof useFormSpecControllerUpdate
		remove: typeof useFormSpecControllerRemove
		updateParent: typeof useFormSpecControllerUpdateParent
	}
	rule: {
		findAll: typeof useRuleControllerFindAll
		findOne: typeof useRuleControllerFindOne
		create: typeof useRuleControllerCreate
		update: typeof useRuleControllerUpdate
		remove: typeof useRuleControllerRemove
		updateParent: typeof useRuleControllerUpdateParent
	}
	'run-template': {
		findAll: typeof useRunTemplateControllerFindAll
		findOne: typeof useRunTemplateControllerFindOne
		create: typeof useRunTemplateControllerCreate
		update: typeof useRunTemplateControllerUpdate
		remove: typeof useRunTemplateControllerRemove
		updateParent: typeof useRunTemplateControllerUpdateParent
	}
	flow: {
		findAll: typeof useFlowControllerFindAll
		findOne: typeof useFlowControllerFindOne
		create: typeof useFlowControllerCreate
		update: typeof useFlowControllerUpdate
		remove: typeof useFlowControllerRemove
		updateParent: typeof useFlowControllerUpdateParent
	}
}

const typeToHook: AssetHooks = {
	dataset: {
		findAll: useDatasetControllerFindAll,
		findOne: useDatasetControllerFindOne,
		create: useDatasetControllerCreate,
		update: useDatasetControllerUpdate,
		remove: useDatasetControllerRemove,
		updateParent: useDatasetControllerUpdateParent,
	},
	'form-spec': {
		findAll: useFormSpecControllerFindAll,
		findOne: useFormSpecControllerFindOne,
		create: useFormSpecControllerCreate,
		update: useFormSpecControllerUpdate,
		remove: useFormSpecControllerRemove,
		updateParent: useFormSpecControllerUpdateParent,
	},
	rule: {
		findAll: useRuleControllerFindAll,
		findOne: useRuleControllerFindOne,
		create: useRuleControllerCreate,
		update: useRuleControllerUpdate,
		remove: useRuleControllerRemove,
		updateParent: useRuleControllerUpdateParent,
	},
	'run-template': {
		findAll: useRunTemplateControllerFindAll,
		findOne: useRunTemplateControllerFindOne,
		create: useRunTemplateControllerCreate,
		update: useRunTemplateControllerUpdate,
		remove: useRunTemplateControllerRemove,
		updateParent: useRunTemplateControllerUpdateParent,
	},
	flow: {
		findAll: useFlowControllerFindAll,
		findOne: useFlowControllerFindOne,
		create: useFlowControllerCreate,
		update: useFlowControllerUpdate,
		remove: useFlowControllerRemove,
		updateParent: useFlowControllerUpdateParent,
	},
}

const getHook = (type: Asset['type']): AssetHooks[Asset['type']] => {
	if (!typeToHook[type]) {
		throw new Error('Invalid asset type')
	}
	return typeToHook[type]
}

export const useAssetsData = <K extends keyof AssetHooks>(
	type: K
): AssetHooks[K]['findAll'] => {
	const hook = getHook(type).findAll

	return hook
}

export const useAssets = (type: Asset['type']) => {
	const queryClient = useQueryClient()
	const hooks = getHook(type)

	const useOne = (params: { id: string; appId: string }) => {
		return hooks.findOne({ pathParams: params })
	}

	const createAsset = hooks.create({
		onSuccess: () => {
			queryClient.invalidateQueries()
			toast.success(`${typeToFormatted[type]} created`)
		},
		onError: error => {
			toast.error(error.stack.message)
		},
	})

	const updateAsset = hooks.update({
		onSuccess: () => {
			queryClient.invalidateQueries()
			toast.success(`${typeToFormatted[type]} edited`)
		},
		onError: error => {
			toast.error(error.stack.message)
		},
	})

	const updateAssetParent = hooks.updateParent({
		onSuccess: () => {
			queryClient.invalidateQueries()
			toast.success(`Asset's parent updated`)
		},
		onError: error => {
			toast.error(error.stack.message)
		},
	})

	const deleteAsset = hooks.remove({
		onSuccess: () => {
			queryClient.invalidateQueries()
			toast.success(`${typeToFormatted[type]} deleted`)
		},
		onError: error => {
			toast.error(error.stack.message)
		},
	})

	return {
		useOne,
		createAsset,
		updateAsset,
		deleteAsset,
		updateAssetParent,
	}
}
