import React, { useEffect, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { OutlinedInput } from '@mui/material'
import type { ChangeEvent, FC } from 'react'

import useDebounce from '../hooks/useDebounce'
import type { OperatorAndValue } from '../TableFilters'

interface Props {
	value: OperatorAndValue
	onChange: (searchChange: OperatorAndValue) => void
	operator?: '$regex' | '$search' | '$eq'
}

const SearchInput: FC<Props> = ({ value, onChange, operator = '$regex' }) => {
	const [_value, set_value] = useState(value ? value[operator] : '')
	const debouncedValue = useDebounce(_value, 500)

	const _onChange = (event: ChangeEvent<HTMLInputElement>) => {
		set_value(event.target.value)
	}

	const onReset = () => {
		set_value('')
	}

	useEffect(() => {
		onChange(
			debouncedValue
				? ({ [operator]: debouncedValue } as OperatorAndValue)
				: null
		)
	}, [debouncedValue])

	return (
		<OutlinedInput
			id="search-input"
			endAdornment={
				_value && (
					<CloseIcon
						onClick={onReset}
						sx={{
							ml: '4px',
							fontSize: '12px',
							color: '#0000008A',
							cursor: 'pointer',
						}}
					/>
				)
			}
			aria-describedby="clear-search-input-text"
			aria-label="Search input"
			placeholder="Search"
			onChange={_onChange}
			value={_value}
			style={{
				margin: '6px',
				width: '200px',
			}}
			size="small"
		/>
	)
}

export default SearchInput
