import { post } from '../base'
import type { ModelUpdate } from './model'
import { updateModel } from './model'

/***************************** SELECTORS **************************************/

export type DeleteComponentState = {}

export const deleteComponentInitialState = {}

/****************************** ACTIONS ***************************************/

export type DeleteModelComponentActionTypes =
	| DeleteComponentPending
	| DeleteComponentError
	| DeleteComponentSuccess

interface DeleteComponentPending {
	type: typeof DELETE_COMPONENT_PENDING
}
interface DeleteComponentError {
	type: typeof DELETE_COMPONENT_ERROR
	errorMessage: string
}
interface DeleteComponentSuccess {
	type: typeof DELETE_COMPONENT_SUCCESS
	appId: string
	updates: { componentsDeleted: string[]; relationshipsDeleted: string[] }
}

export const DELETE_COMPONENT_PENDING = 'DELETE_COMPONENT_PENDING'
export const DELETE_COMPONENT_ERROR = 'DELETE_COMPONENT_PENDING'
export const DELETE_COMPONENT_SUCCESS = 'DELETE_COMPONENT_SUCCESS'

/***************************** REQUESTS ***************************************/
export function deleteComponent(appId: string, componentId: string) {
	return async (dispatch: any) => {
		dispatch({ type: DELETE_COMPONENT_PENDING })
		post(
			`/api/v2/applications/${appId}/model/components/${componentId}/delete`,
			{}
		)
			.then(res => res.json())
			.then(deleteBody => {
				const updates: ModelUpdate[] = []
				deleteBody.relationshipsDeleted.forEach(r => {
					updates.push({
						type: 'delete',
						relationshipId: r,
						componentId: null,
					})
				})
				deleteBody.componentsDeleted.forEach(c => {
					updates.push({
						type: 'delete',
						relationshipId: null,
						componentId: c,
					})
				})
				dispatch(updateModel(updates))
				dispatch({
					type: DELETE_COMPONENT_SUCCESS,
					appId,
					updates: deleteBody,
				})
			})
			.catch(error =>
				dispatch({
					type: DELETE_COMPONENT_ERROR,
					errorMessage: error.message,
				})
			)
	}
}

/****************************** REDUCER ***************************************/
export function deleteComponentReducer(
	state = deleteComponentInitialState,
	action: DeleteModelComponentActionTypes
): DeleteComponentState {
	switch (action.type) {
		case DELETE_COMPONENT_PENDING:
			return state

		case DELETE_COMPONENT_ERROR:
			return state

		case DELETE_COMPONENT_SUCCESS:
			return state

		default:
			return state
	}
}
