import React from 'react'

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import type { TagWithColor } from 'components/feature/TestAsset/utils'
import AutoCompleteTags from 'components/shared/AstaTable/AutoCompleteTags'
import type { FC } from 'react'
import type { Tag } from 'shared/types'

export interface EditorTagAutocompleteProps {
	tags: Tag[]
	availableTags: TagWithColor[]
	setTags: (tags: string[]) => void
	disabled?: boolean
}

export const EditorTagAutocomplete: FC<EditorTagAutocompleteProps> = ({
	tags,
	availableTags,
	setTags,
	disabled = true,
}) => {
	const theme = useTheme()
	const matchDownMD = useMediaQuery(theme.breakpoints.down('md'))

	return (
		<Box
			mb={2}
			width="100%"
			display="flex"
			alignItems="center"
			flexDirection="row"
			justifyContent="flex-end"
		>
			<Typography>Tags:</Typography>
			<Box width={matchDownMD ? '90%' : '30%'} display="flex" pl={2}>
				<AutoCompleteTags
					tags={tags}
					availableTags={availableTags}
					setTags={setTags}
					disabled={disabled}
				/>
			</Box>
		</Box>
	)
}
