import { config } from '../../config'

export const API_URL = config.services.repository.url

/**
 * Base Request class that represents the state of an outgoing request. Used to
 * track the state of various requests.
 */
export type Request = {
	pending: boolean
	error: any
}

/**
 * Get the current user's token. Very simple implementation because I am not
 * worried about authentication and a whole user management system yet.
 */
export function getToken(): string | null {
	return localStorage.getItem('jwt')
}

/**
 * Make an authorized get request by adding the user's token as a header in
 * the request.
 *
 * @param {string} endpoint
 */
export function get(endpoint: string) {
	let token = getToken()
	if (token == null) token = ''

	return fetch(API_URL + endpoint, {
		method: 'GET',
		mode: 'cors',
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json',
			'x-api-key': token,
		},
	})
}

/**
 * Make an authorized post request by adding the user's token as a header in
 * the request.
 *
 * @param {string} endpoint
 * @param {*} data
 */
export function post(endpoint: string, data: any) {
	let token = getToken()
	if (token == null) token = ''

	return fetch(API_URL + endpoint, {
		method: 'POST',
		mode: 'cors',
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json',
			'x-api-key': token,
		},
		body: JSON.stringify(data),
	})
}

/**
 * Make an authorized put request by adding the user's token as a header in
 * the request.
 *
 * @param {string} endpoint
 * @param {*} data
 */
export function put(endpoint: string, data: any) {
	let token = getToken()
	if (token == null) token = ''

	return fetch(API_URL + endpoint, {
		method: 'PUT',
		mode: 'cors',
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json',
			'x-api-key': token,
		},
		body: JSON.stringify(data),
	})
}
