import { useCallback, useMemo } from 'react'

import { useToggle } from '@asta/react-component-library'
import type {
	DatasetControllerCreateVariables,
	DatasetControllerRemoveVariables,
	DatasetControllerUpdateVariables,
	FlowControllerCreateVariables,
	FlowControllerRemoveVariables,
	FlowControllerUpdateVariables,
	FormSpecControllerCreateVariables,
	FormSpecControllerRemoveVariables,
	FormSpecControllerUpdateVariables,
	RuleControllerCreateVariables,
	RuleControllerRemoveVariables,
	RuleControllerUpdateVariables,
	RunTemplateControllerCreateVariables,
	RunTemplateControllerRemoveVariables,
	RunTemplateControllerUpdateVariables,
} from 'api/repository/repositoryComponents'
import { useAssetTableData } from 'components/feature/TestAsset/hooks/useAssetTableData'
import { getAvailableTagsWithColor } from 'components/feature/TestAsset/utils'
import { useQueryState } from 'components/shared/QueryState/useQueryState'
import { useAssets } from 'hooks/App/useAssets'
import { useTags } from 'pages/Admin/tags/hooks'
import type { Asset, AssetWithParent } from 'shared/types'

import { useSelectedRowsContext } from '../context/SelectedRowsContext'

export interface UseAssetPageStateProps {
	selectedAppId: string
	assetType: Asset['type']
}

type CreateVariables =
	| RuleControllerCreateVariables
	| DatasetControllerCreateVariables
	| FlowControllerCreateVariables
	| RunTemplateControllerCreateVariables
	| FormSpecControllerCreateVariables

type UpdateVariables =
	| RuleControllerUpdateVariables
	| DatasetControllerUpdateVariables
	| FlowControllerUpdateVariables
	| RunTemplateControllerUpdateVariables
	| FormSpecControllerUpdateVariables

type RemoveVariables =
	| RuleControllerRemoveVariables
	| DatasetControllerRemoveVariables
	| FlowControllerRemoveVariables
	| RunTemplateControllerRemoveVariables
	| FormSpecControllerRemoveVariables

export const useAssetPageState = ({
	selectedAppId,
	assetType,
}: UseAssetPageStateProps) => {
	const { getSelectedAssets = () => [] } = useSelectedRowsContext()
	const [editingAssetId, setEditingAssetId] =
		useQueryState<string>('editingAssetId')
	const [isCreating, setIsCreating] = useQueryState<boolean>('isCreating')
	const [isExporting, , enableExport, disableExport] = useToggle(false)
	const [isImporting, , enableImport, disableImport] = useToggle(false)
	const { tags } = useTags(selectedAppId)

	const { createAsset, deleteAsset, updateAsset } = useAssets(assetType)

	const { data = [], isLoading } = useAssetTableData(assetType)(
		{ pathParams: { appId: selectedAppId } },
		{ enabled: !!selectedAppId, refetchOnWindowFocus: false }
	)

	const assets = useMemo(() => data.map(asset => asset.asset), [data])
	const asset = useMemo(
		() =>
			assets && editingAssetId
				? assets.find(asset => asset._id === editingAssetId)
				: null,
		[editingAssetId, assets]
	)

	if (editingAssetId && assets?.length > 0 && !asset) {
		setEditingAssetId(null)
	}

	const create = useCallback(
		(variables: CreateVariables) => {
			createAsset.mutate(variables)
		},
		[createAsset]
	)

	const update = useCallback(
		(variables: UpdateVariables) => {
			updateAsset.mutate(variables)
		},
		[updateAsset]
	)

	const _deleteAsset = useCallback(
		(variables: RemoveVariables) => {
			deleteAsset.mutate(variables)
		},
		[deleteAsset]
	)

	const onRowClick = useCallback(
		(clickedAsset: AssetWithParent) => {
			setEditingAssetId(clickedAsset._id)
		},
		[setEditingAssetId]
	)

	const onCreate = useCallback(() => {
		setIsCreating(true)
	}, [setIsCreating])

	const onClose = useCallback(() => {
		setEditingAssetId(null)
		setIsCreating(false)
	}, [setEditingAssetId, setIsCreating])

	const selectedAssets = useMemo(
		() => (getSelectedAssets ? getSelectedAssets() : []),
		[getSelectedAssets]
	)

	return {
		editingAssetId,
		setEditingAssetId,
		isCreating,
		setIsCreating,
		create,
		update,
		deleteAsset: _deleteAsset,
		assets,
		asset,
		isLoading,
		onRowClick,
		onCreate,
		onClose,
		tags: getAvailableTagsWithColor(tags),
		isExporting,
		enableExport,
		disableExport,
		selectedAssets,
		isImporting,
		enableImport,
		disableImport,
	}
}
