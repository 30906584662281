import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import UserService from './UserService'

interface User {
	email: string
	_id: string
}

interface UsersState {
	users: User[]
	status: 'loading' | 'finished' | 'error'
}

export const usersInitialState: UsersState = {
	users: [],
	status: 'loading',
}

export const fetchUsers = createAsyncThunk(
	'users/fetchAll',
	async (filter?: string) => {
		const res = await UserService.fetchAll(filter)
		return res.data.data
	}
)

const userSlice = createSlice({
	name: 'user',
	initialState: usersInitialState,
	extraReducers: builder => {
		builder.addCase(fetchUsers.fulfilled, (state, action) => {
			return {
				users: [...action.payload],
				status: 'finished',
			}
		})

		builder.addCase(fetchUsers.pending, state => {
			return {
				...state,
				status: 'loading',
			}
		})

		builder.addCase(fetchUsers.rejected, state => {
			return {
				...state,
				status: 'error',
			}
		})
	},
	reducers: undefined,
})

const { reducer } = userSlice
export default reducer
