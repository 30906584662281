import { useCallback, useMemo, useState } from 'react'

import { useToggle } from '@asta/react-component-library'

import {
	convertFilterArrayToObject,
	convertFilterObjectToArray,
	filterIncompleteFilters,
} from './util'

export interface CustomFilterShape {
	//Column
	[key: string]: {
		[key: string]: string | string[] | boolean // Operator: Value
	}
}

/**
 * - Converts filters from object to array to modify them
 * - After filters are changed converts array of filters to object again
 * - Uses two arrays of filters, existing filters and incomplete filters
 * 	 an existing filter can become an incomplete filter if the column or the operator changes
 */
export const useFilters = (
	tableFilters: CustomFilterShape,
	setTableFilters: (filters: CustomFilterShape) => void
) => {
	const [open, _toggleOpen, _on, close] = useToggle()

	/**
	 * Converts table filters to an array of filters
	 * Removes incomplete filters
	 */
	const filters: Array<CustomFilterShape> = useMemo(
		() => convertFilterObjectToArray(tableFilters),
		[tableFilters]
	)

	const [incompleteFilters, setIncompleteFilters] = useState([])

	//Existing filters and incomplete filters
	const allFilters = useMemo(
		() => filters.concat(incompleteFilters),
		[filters, incompleteFilters]
	)
	const setFilters = useCallback(
		(val: CustomFilterShape[]) => {
			const filtersToObject = convertFilterArrayToObject(val)
			setTableFilters(filtersToObject)
		},
		[setTableFilters]
	)

	const resetFilters = useCallback(() => {
		setFilters([])
		setIncompleteFilters([])
	}, [setFilters, setIncompleteFilters])

	const closeAndRemoveEmpty = useCallback(() => {
		setFilters(filters)
		setIncompleteFilters([])
		close()
	}, [setIncompleteFilters, filters, setFilters, close])

	const toggleOpen = useCallback(() => {
		if (open) {
			closeAndRemoveEmpty()
			return
		}
		_toggleOpen()
	}, [_toggleOpen, closeAndRemoveEmpty, open])

	const onFilterChange = useCallback(
		(newValue: CustomFilterShape, index: number) => {
			const filtersCopy = [...filters]
			if (newValue === null) {
				if (index >= filters.length) {
					setIncompleteFilters(prev => prev.splice(index, 1))
					return
				}
				filtersCopy.splice(index, 1)
				setFilters(filtersCopy)
				return
			}

			const isComplete = filterIncompleteFilters([newValue]).length > 0

			if (index >= filters.length && !isComplete) {
				setIncompleteFilters([newValue])
			} else {
				if (!isComplete) {
					setIncompleteFilters([newValue])
					filtersCopy.splice(index, 1)
					setFilters(filtersCopy)
					return
				}

				setFilters([...filters, newValue])
				setIncompleteFilters([])
			}
		},
		[filters, setFilters, setIncompleteFilters]
	)

	return {
		filters: allFilters,
		resetFilters,
		closeAndRemoveEmpty,
		open,
		toggleOpen,
		onFilterChange,
	}
}
