import React, { useCallback, useState } from 'react'

import { styled, TableBody as _TB, TableRow } from '@mui/material'
import { flexRender, type Row, type Table } from '@tanstack/react-table'
import type { VirtualItem, Virtualizer } from '@tanstack/react-virtual'

import AstaRightClickMenu from '../AstaRightClickMenu'
import { useContextMenu } from '../AstaRightClickMenu/useContextMenu'
import { TableCell } from './cell'

const TB = styled(_TB, {
	shouldForwardProp: prop => prop !== 'height',
})<{ height: string | number }>(({ height }) => ({
	display: 'grid',
	height: `${height}px`, //tells scrollbar how big the table is
	position: 'relative', //needed for absolute positioning of rows
}))

const TR = styled(TableRow, {
	shouldForwardProp: prop =>
		prop !== 'isClickable' && prop !== 'isSelected' && prop !== 'transform',
})<{
	isClickable?: boolean
	isSelected?: boolean
	transform: string
}>(({ theme, isClickable, isSelected, transform }) => ({
	backgroundColor: isSelected
		? theme.palette.action.hover
		: theme.palette.background.paper,
	cursor: isClickable ? 'pointer' : 'default',
	display: 'flex',
	position: 'absolute',
	width: '100%',
	transform,
}))

type ContextMenuOption = {
	label: string
	onClick: (rows: unknown[]) => void
}
interface TableBodyProps<T> {
	table: Table<T>
	rows: Row<T>[] | VirtualItem[]
	height: number
	selectedRowId?: string | number
	rowOnClick?: (data: T) => void
	setRowSelection?: (value: unknown) => void
	contextMenuOptions?: ContextMenuOption[]
	rowVirtualizer: Virtualizer<undefined, Element>
}

const NO_OPTIONS: ContextMenuOption[] = []
export function TableBody<T>({
	table,
	rows,
	height,
	rowOnClick,
	setRowSelection,
	contextMenuOptions = NO_OPTIONS,
	rowVirtualizer,
	selectedRowId,
}: TableBodyProps<T>): JSX.Element {
	const { clicked, setClicked, coords, setCoords } = useContextMenu()
	const [clickedRow, setClickedRow] = useState<VirtualItem>(null)
	const tableRows = table.getRowModel().rows

	const handleContextMenu = useCallback(
		(row: Row<T>) => {
			if (!row.getIsSelected()) {
				setRowSelection({})
			}
		},
		[setRowSelection]
	)

	const handleRowClick = useCallback(
		(row: Row<T>) => {
			if (rowOnClick) {
				rowOnClick(row.original)
			}
		},
		[rowOnClick]
	)

	return (
		<TB height={height}>
			{rows.map(row => (
				<TR
					key={row.key}
					data-index={row.index}
					ref={rowVirtualizer.measureElement} //measure dynamic row height
					// eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
					onContextMenu={e => {
						if (contextMenuOptions.length === 0) return

						// prevent default right click behavior
						e.preventDefault()

						// set our click state to true when a user right clicks
						setClicked(true)

						// set the x and y coordinates of our users right click
						setCoords({ x: e.pageX, y: e.pageY })
						setClickedRow(row)
						handleContextMenu(tableRows[row.index])
					}}
					isClickable={Boolean(rowOnClick)}
					isSelected={
						tableRows[row.index].getIsSelected() ||
						(!!selectedRowId &&
							(
								tableRows[row.index].original as unknown as {
									id: string
								}
							)?.id === selectedRowId)
					}
					onClick={() => handleRowClick(tableRows[row.index])}
					transform={`translateY(${row.start}px)`}
				>
					{tableRows[row.index].getVisibleCells().map(cell => {
						const size = cell.column.getSize()
						const width = isNaN(size) ? 'auto' : size
						return (
							<TableCell key={cell.id} width={width}>
								{flexRender(
									cell.column.columnDef.cell,
									cell.getContext()
								)}
							</TableCell>
						)
					})}
				</TR>
			))}
			{clicked && contextMenuOptions.length > 0 && (
				<AstaRightClickMenu
					left={coords.x}
					transform={`translateY(${clickedRow.start + 30}px)`}
					contextMenuOptions={contextMenuOptions.map(op => ({
						...op,
						label: op.label,
						onClick: () => {
							if (!tableRows[clickedRow.index].getIsSelected()) {
								op.onClick([
									tableRows[clickedRow.index].original,
								])
								return
							}
							op.onClick(
								table
									.getSelectedRowModel()
									?.rows?.map(row => row.original)
							)
							setRowSelection({})
						},
					}))}
				/>
			)}
		</TB>
	)
}
