import type { ConcreteActivity } from '@asta/core'

export type ActivitiesPageState = {
	activityToEdit: ConcreteActivity | null
}

export const activitiesPageInitialState: ActivitiesPageState = {
	activityToEdit: null,
}

export const activityToEditSelector = (state: any) =>
	state.activitiesPage.activityToEdit

// ACTIONS
export type ActivitiesPageActions = SetActivityToEdit

export const SET_ACTIVITY_TO_EDIT = 'SET_ACTIVITY_TO_EDIT'
interface SetActivityToEdit {
	type: typeof SET_ACTIVITY_TO_EDIT
	activity: ConcreteActivity | null
}
export function setActivity(activity: ConcreteActivity | null) {
	return {
		type: SET_ACTIVITY_TO_EDIT,
		activity,
	}
}
export function setActivityToEdit(activity: ConcreteActivity | null) {
	return (dispatch: any) => {
		dispatch(setActivity(activity))
	}
}

// REDUCER
export const activitiesPageReducer = (
	state = activitiesPageInitialState,
	action: ActivitiesPageActions
): ActivitiesPageState => {
	switch (action.type) {
		case SET_ACTIVITY_TO_EDIT:
			return { ...state, activityToEdit: action.activity }

		default:
			return state
	}
}

// REQUESTS
