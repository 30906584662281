import axios from './axios-base'

const whoami = async () => {
	const { data } = await axios.get('/api/v2/auth/whoami')
	return data
}

const logout = async () => {
	const { data } = await axios.post('/api/v2/auth/logout')
	return data
}

const AuthService = {
	whoami,
	logout,
}

export default AuthService
