import type { BaseResourceState } from '../base/base'
import {
	buildResourceReducer,
	fetchAll,
	initialBaseResourceState,
	RESOURCE,
} from '../base/base'

type AgentDTO = {
	id: string
	name: string
	state: string
}

type AgentsState = BaseResourceState<AgentDTO>

export const initialState: AgentsState = {
	...initialBaseResourceState,
}

// Selectors
export const agentsSelector = (state: any) => state.agents.agents

// Requests
export const getAllAgents = () => fetchAll(RESOURCE.AGENTS)

// Reducer
export const agentsReducer = buildResourceReducer(initialState, RESOURCE.AGENTS)
