import { post } from './base'

/**
 * Stores details about the currently active run, if there is one. If the user
 * starts a run, or there is a run already active in the system, then it should
 * appear here.
 */
export type ReportIssueState = {
	displayReportIssueCard: boolean
	entry: any | null
}

// INITIAL STATE
export const reportIssueInitialState = {
	displayReportIssueCard: false,
	entry: null,
}

// SELECTORS
export const displayReportIssueCardSelector = (state: any) =>
	state.reportIssue.displayReportIssueCard
export const issueEntrySelector = (state: any) => state.reportIssue.entry

// ACTIONS
export type ReportIssueActionTypes = ShowReportIssueCard | HideReportIssueCard

const SHOW_REPORT_ISSUE_CARD = 'SHOW_REPORT_ISSUE_CARD'
interface ShowReportIssueCard {
	type: typeof SHOW_REPORT_ISSUE_CARD
	entry: any
}
export function showReportIssueCard(entry: any) {
	return (dispatch: any) => {
		dispatch({ type: SHOW_REPORT_ISSUE_CARD, entry })
	}
}

const HIDE_REPORT_ISSUE_CARD = 'HIDE_REPORT_ISSUE_CARD'
interface HideReportIssueCard {
	type: typeof HIDE_REPORT_ISSUE_CARD
}
export function hideReportIssueCard() {
	return (dispatch: any) => {
		dispatch({ type: HIDE_REPORT_ISSUE_CARD })
	}
}

// REDUCER
export const reportIssueReducer = (
	state = reportIssueInitialState,
	action: ReportIssueActionTypes
): ReportIssueState => {
	switch (action.type) {
		case SHOW_REPORT_ISSUE_CARD:
			return {
				...state,
				displayReportIssueCard: true,
				entry: action.entry,
			}

		case HIDE_REPORT_ISSUE_CARD:
			return { ...state, displayReportIssueCard: false, entry: null }

		default:
			return state
	}
}

// REQUESTS
export function postIssue(entry: any, description: string) {
	const body = { runId: entry.runId, entryId: entry.entryId, description }
	post('/api/v2/issue', body)
		.then(res => res.json())
		.then(res => {
			if (res.error) throw res.error
		})
}
