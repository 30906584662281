import React, { memo, useCallback, useMemo } from 'react'

import { useStableCallback } from '@asta/react-component-library'
import type { AutocompletePill } from 'components/common/Autocomplete/AstaAutocomplete'
import AstaAutocomplete from 'components/common/Autocomplete/AstaAutocomplete'
import type { Tag } from 'shared/types'

type Props = {
	availableTags: Tag[]
	tags: Tag[]
	setTags: (tags: string[]) => void
	disabled?: boolean
}

const AutoCompleteTags: React.FC<Props> = memo(function TagsCell({
	availableTags,
	tags,
	setTags,
	disabled = false,
}) {
	const tagData: Record<string, Tag> = useMemo(() => {
		const data: Record<string, Tag> = {}
		availableTags.forEach(tag => {
			data[tag._id] = tag
		})
		return data
	}, [availableTags])

	const tagToPill: (tag: Tag) => AutocompletePill = useCallback(
		tag => ({
			...tag,
			label: tag.name,
			value: tag._id,
			color: tagData[tag._id]?.color,
		}),
		[tagData]
	)

	const autoCompleteOptions = useMemo(
		() => availableTags.map(tag => tagToPill(tag)),
		[availableTags, tagToPill]
	)

	const currentTags = useMemo(
		() => tags.map(tag => tagToPill(tag)),
		[tags, tagToPill]
	)

	const onChange = useCallback(
		(value: AutocompletePill[]) =>
			setTags(
				availableTags
					.filter(t => value.find(v => v._id === t._id))
					.map(t => t._id)
			),
		[setTags, availableTags]
	)

	return (
		<AstaAutocomplete
			key="tags"
			label=""
			placeholder=""
			initialValue={currentTags}
			options={autoCompleteOptions}
			onChange={onChange}
			disabled={disabled}
		/>
	)
})

const MemoTags: React.FC<Props> = props => {
	const setTags = useStableCallback(props.setTags)
	return <AutoCompleteTags {...props} setTags={setTags} />
}
export default MemoTags
