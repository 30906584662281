import React, { useCallback, useMemo } from 'react'

import { SelectInput } from '@asta/react-component-library'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { Box, Stack, TextField, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import type { FC } from 'react'

import type {
	SelectFilterDefinition,
	TextFilterDefinition,
} from '../TableFilters/filter-shapes'
import type { CustomFilterShape } from './useFilters'
import type { OperatorDefinition } from './util'
import { getFlatFilter, selectSx } from './util'

const TextInput = styled(TextField)(() => ({ width: '125px' }))

const RemoveIconContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: 32,
	width: 32,
	border: `1px solid ${theme.palette.grey[400]}`,
	borderRadius: theme.shape.borderRadius,
}))

const RemoveRowButton = styled(DeleteOutlineOutlinedIcon)(() => ({
	fontSize: 16,
	cursor: 'pointer',
}))

interface FilterRowProps {
	filter: CustomFilterShape
	options: Array<
		(SelectFilterDefinition | TextFilterDefinition) & {
			operators: Array<OperatorDefinition>
		}
	>
	index: number
	onChange: (newValue: CustomFilterShape, index: number) => void
}

export const FilterRow: FC<FilterRowProps> = ({
	filter,
	options,
	index,
	onChange,
}) => {
	const [column, operator, value] = getFlatFilter(filter)
	const findOperators = useCallback(
		(column: string) =>
			column && options.find(option => option.column.value === column),
		[options]
	)

	const filterConfig = useMemo(
		() => findOperators(column),
		[findOperators, column]
	)

	const columns = useMemo(
		() => options.map(option => option.column),
		[options]
	)

	const onFilterChange = useCallback(
		(
			newFilterValue: string,
			valueType: 'column' | 'operator' | 'value'
		) => {
			let newFilter: CustomFilterShape = {}
			switch (valueType) {
				case 'column':
					const operators = findOperators(newFilterValue)?.operators
					if (!operators || operators.length === 0) return

					newFilter = {
						[newFilterValue]: {
							[operators[0].value]: null,
						},
					} satisfies CustomFilterShape
					break
				case 'operator':
					if (newFilterValue === operator) return

					newFilter = {
						[column]: {
							[newFilterValue]: value,
						},
					} satisfies CustomFilterShape
					break
				case 'value':
					if (newFilterValue === value) return

					newFilter = {
						[column]: {
							[operator]: newFilterValue,
						},
					} satisfies CustomFilterShape
					break
			}

			onChange(newFilter, index)
		},
		[index, onChange, column, value, operator, findOperators]
	)

	const setColumn = useCallback(
		(col: string) => {
			if (col === column) return
			onFilterChange(col, 'column')
		},
		[onFilterChange, column]
	)

	const setOperator = useCallback(
		(newOperator: string) => {
			if (newOperator === operator) return
			onFilterChange(newOperator, 'operator')
		},
		[onFilterChange, operator]
	)

	const setValue = useCallback(
		(newValue: string) => {
			if (newValue === value) return
			onFilterChange(newValue, 'value')
		},
		[onFilterChange, value]
	)

	const setTextValue = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value),
		[setValue]
	)

	const onFilterRemove = useCallback(() => {
		onChange(null, index)
	}, [onChange, index])

	if (!column) return null
	return (
		<Stack
			role="row"
			key={`${column}-${value}-${operator}-filter`}
			display="flex"
			flexDirection="row"
			spacing={2}
			px="1rem"
			justifyContent="center"
			justifyItems="flex-start"
			alignItems="center"
		>
			<Typography
				variant="body1"
				mr={1}
				pt={2}
				mt={1}
				width={50}
				height={32}
				textAlign="center"
			>
				{index === 0 ? 'Where' : 'And'}
			</Typography>
			<SelectInput
				ariaLabel="Select column"
				value={column}
				options={columns}
				sx={selectSx}
				onChange={setColumn}
			/>
			<SelectInput
				ariaLabel="Select operator"
				value={operator}
				options={filterConfig.operators}
				sx={selectSx}
				onChange={setOperator}
			/>
			{filterConfig?.type === 'select' && (
				<SelectInput
					ariaLabel="Select value"
					value={value as string}
					options={filterConfig.values}
					sx={selectSx}
					onChange={setValue}
					disabled={!operator || !column}
				/>
			)}

			{filterConfig?.type === 'text' && (
				<TextInput
					// Disabling the rule is needed because when a filter changes the input loses the focus
					autoFocus // eslint-disable-line jsx-a11y/no-autofocus
					aria-label="text filter value"
					value={value}
					size="small"
					placeholder="Enter a value"
					onChange={setTextValue}
				/>
			)}
			<RemoveIconContainer>
				<RemoveRowButton
					role="button"
					aria-label="Remove current filter"
					aria-hidden="false"
					onClick={onFilterRemove}
				/>
			</RemoveIconContainer>
		</Stack>
	)
}
