import 'shepherd.js/dist/css/shepherd.css'

import {
	ColorSchemeProvider,
	ErrorBoundary,
	ThemeCustomizationWrapper,
} from '@asta/react-component-library'
import Snackbar from 'components/@extended/Snackbar'
import { AuthProvider } from 'contexts/auth'
import { BreadcrumbsProvider } from 'contexts/breadcrumbs'
import { SelectedAppProvider } from 'contexts/selectedApp'
import { WorkspaceProvider } from 'contexts/workspace'
import RTLLayout from 'deprecated/components/RTLLayout'
import ScrollTop from 'deprecated/components/ScrollTop'
import { RunTimerProvider } from 'pages/App/Status/RunDetails/RunTimerContext'
import ErrorPage from 'pages/ErrorPage'
import { Toaster } from 'react-hot-toast'
import Routes from 'routes'
import ThemeCustomization from 'themes'

const App = () => (
	<ColorSchemeProvider>
		<RTLLayout>
			<ThemeCustomization>
				<ThemeCustomizationWrapper>
					<AuthProvider>
						<WorkspaceProvider>
							<SelectedAppProvider>
								<ScrollTop>
									<BreadcrumbsProvider>
										<ErrorBoundary Fallback={ErrorPage}>
											<RunTimerProvider>
												<Toaster />

												<Routes />

												<Snackbar />
											</RunTimerProvider>
										</ErrorBoundary>
									</BreadcrumbsProvider>
								</ScrollTop>
							</SelectedAppProvider>
						</WorkspaceProvider>
					</AuthProvider>
				</ThemeCustomizationWrapper>
			</ThemeCustomization>
		</RTLLayout>
	</ColorSchemeProvider>
)

export default App
