import { createContext } from 'react'

import type { Workspace } from 'shared/types'

export interface WorkspaceContextProps {
	workspace: Workspace | undefined
	workspaces: Workspace[]
	setWorkspace: (workspaceId: string) => void
}

export const WorkspaceContext = createContext({} as WorkspaceContextProps)
