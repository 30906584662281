import React, { Suspense } from 'react'

import { CircularProgress, Stack } from '@mui/material'
import Loader from 'deprecated/components/Loader'
import type { ComponentProps, ComponentType, LazyExoticComponent } from 'react'

import styles from './loader.module.scss'

// todo: should we make a loader page?
const CircularLoader: React.FC = () => {
	return (
		<Stack
			display="flex"
			justifyContent="center"
			alignItems="center"
			height="100vh"
			width="100vw"
		>
			<CircularProgress className={styles.loader} />
		</Stack>
	)
}

/**
 * Wraps a lazy component in a {@link Suspense} boundary with {@link Loader}
 * as a fallback
 *
 * @example
 * ```ts
 * import React, { lazy } from 'react'
 *
 * const LazyComponent = lazy(() => import('path/to/component'))
 * const SuspendedComponent = WithLoaderSuspense(LazyComponent)
 * ```
 *
 * @param Component The lazy component
 * @returns the wrapped component
 */
const WithLoaderSuspense = <C extends ComponentType<any>>(
	Component: LazyExoticComponent<C>
) => {
	const Suspended = (props: ComponentProps<C>) => (
		<Suspense fallback={<Loader />}>
			<Component {...props} />
		</Suspense>
	)
	Suspended.displayName = `WithLoaderSuspense(${
		(Component as LazyExoticComponent<C> & { displayName?: string })
			.displayName || Component.name
	})`
	return Suspended
}

export default CircularLoader
export { WithLoaderSuspense }
