import { useQueryClient } from '@tanstack/react-query'
import {
	useViewControllerCreateView,
	useViewControllerDeleteView,
	useViewControllerGetViews,
	useViewControllerUpdateView,
} from 'api/repository/repositoryComponents'
import { DEFAULT_STALE_TIME } from 'deprecated/services/constants'
import { toast } from 'react-hot-toast'

export const useViews = (workspaceId: string, urlKey?: string) => {
	const queryClient = useQueryClient()

	const { data, isLoading, error } = useViewControllerGetViews(
		{
			pathParams: { parentId: workspaceId },
			queryParams: {
				...(urlKey && { url: urlKey }),
			},
		},
		{ staleTime: DEFAULT_STALE_TIME, enabled: !!workspaceId }
	)

	const createView = useViewControllerCreateView({
		onSuccess: () => {
			queryClient.invalidateQueries()
			toast.success(`View created`)
		},
		onError: error => {
			toast.error(error.stack.message)
		},
	})

	const updateView = useViewControllerUpdateView({
		onSuccess: () => {
			queryClient.invalidateQueries()
			toast.success(`View updated`)
		},
		onError: error => {
			toast.error(error.stack.message)
		},
	})

	const deleteView = useViewControllerDeleteView({
		onSuccess: () => {
			queryClient.invalidateQueries()
			toast.success(`View deleted`)
		},
		onError: error => {
			toast.error(error.stack.message)
		},
	})

	return {
		data,
		isLoading,
		error,
		createView,
		updateView,
		deleteView,
	}
}
