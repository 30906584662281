/**
 * Manage's the WorkQueueDTOs fetched for runs, not the local work queue that
 * is constructed by a user when they are starting a run.
 */

import type { WorkQueueDTO } from '@asta/repository-dtos'

import type { BaseResourceState } from '../../base/base'
import {
	buildResourceReducer,
	fetchOne,
	initialBaseResourceState,
	RESOURCE,
} from '../../base/base'

type WorkQueueState = BaseResourceState<WorkQueueDTO>

export const initialState: WorkQueueState = {
	...initialBaseResourceState,
}

export const workQueueSelector = (
	state: any,
	runId: string
): WorkQueueDTO | null =>
	state.runWorkQueues.resources.find(r => r.runId === runId)

export const fetchWorkQueue = (runId: string, windowSize = 10) =>
	fetchOne(
		RESOURCE.WORK_QUEUE,
		runId,
		`/api/v2/run/${runId}/workQueue?windowSize=${windowSize}`
	)

export const runWorkQueueReducer = buildResourceReducer(
	initialState,
	RESOURCE.WORK_QUEUE
)
