import React, { useContext, useEffect, useState } from 'react'

import { SelectInput } from '@asta/react-component-library'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { Box, Tooltip, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import type { ViewDTO } from 'api/repository/repositorySchemas'
import { AuthContext } from 'contexts/auth'
import { useSelectedApp } from 'contexts/selectedApp'
import { useViews } from 'contexts/views/useViews'
import AdminModal from 'pages/Admin/AdminModal'
import { canCreate, canEdit, canRemove } from 'pages/Admin/helpers'
import type { FC } from 'react'
import { useLocation } from 'react-router-dom'

import type { CustomFilterShape } from '../CustomFilter'
import { useQueryState } from '../QueryState/useQueryState'
import type { OperatorAndValue } from '../TableFilters'
import {
	removeEmptyOperatorAndValueOverrides,
	removeEmptyOverrides,
	removeTrueValues,
} from './helpers'
import ViewsForm from './ViewsForm'

export const DEFAULT_GLOBAL_VIEWS_NAME = 'Default View'

export interface ViewManagerProps {
	filters: CustomFilterShape
	setViewFilters: (newFilters: CustomFilterShape) => void
	groupBy?: OperatorAndValue
	setViewGroupBy?: (newGroupBy: OperatorAndValue) => void
	columnVisibility?: Record<string, boolean>
	setViewColumnVisibility?: (
		newColumnVisibility: Record<string, boolean>
	) => void
	sort?: string
	setViewSort?: (newSort: string) => void
	columnOrder?: string[]
	setViewColumnOrder?: (newColumnOrder: string[]) => void
	resetUrlParams: () => void
}

const NO_COLUMN_ORDER: string[] = []
const ViewsManager: FC<ViewManagerProps> = ({
	filters,
	setViewFilters,
	groupBy,
	setViewGroupBy,
	sort,
	setViewSort,
	columnVisibility,
	setViewColumnVisibility,
	columnOrder = NO_COLUMN_ORDER,
	setViewColumnOrder,
	resetUrlParams,
}) => {
	const theme = useTheme()
	const matchDownMD = useMediaQuery(theme.breakpoints.down('md'))

	const { user: loggedInUser } = useContext(AuthContext)
	const [isRedirected, setIsRedirected] = useQueryState('redirected')

	const location = useLocation()
	const urlKey = location.pathname.split('/').at(-1)

	const { selectedApp: variant } = useSelectedApp()
	const { _id: variantId } = variant ?? { _id: '' }
	const appId = variant?.parent._id

	const hasOverrides =
		sort ||
		columnOrder?.length > 0 ||
		Object.entries({
			...filters,
			...groupBy,
			...columnVisibility,
		}).length > 0

	const {
		data: views,
		createView: createViewRequest,
		deleteView,
		updateView: editView,
	} = useViews(appId, urlKey)

	const [isModalOpen, setIsModalOpen] = useState(false)
	const [isDuplicating, setIsDuplicating] = useState(false)
	const [viewId, setViewId] = useQueryState<number>('viewId')

	const LSKey = `${variantId}:${urlKey}`
	const LSSelectedViewKey = `${LSKey}:selectedView`

	const lastSelectedViewId = localStorage.getItem(LSSelectedViewKey)

	const lastSelectedView =
		lastSelectedViewId && views?.length > 0
			? views.find(view => view._id === lastSelectedViewId) ?? views[0]
			: views?.length > 0
				? views[0]
				: null

	const [selectedView, setSelectedView] = useState<ViewDTO>(null)

	/**
	 * Load default view config if it's not a redirect
	 * If it's a redirect add default global view column config
	 */
	useEffect(() => {
		if (views && views.length > 0 && !selectedView && !viewId) {
			setView(lastSelectedView, !!isRedirected, false)
		}
	}, [views, selectedView, lastSelectedView])

	// Sync viewId with selectedView in case it's not synced
	useEffect(() => {
		if (selectedView && !viewId) {
			setViewId(selectedView.viewNumber)
		}
	}, [selectedView, viewId])

	// Handle url viewNumber (viewId) change
	useEffect(() => {
		if (
			views &&
			views.length > 0 &&
			(!selectedView || selectedView.viewNumber !== viewId)
		) {
			const view = views.find(v => v.viewNumber === viewId * 1)
			if (!view) {
				setView(lastSelectedView ?? views[0], !!isRedirected, false)
				return
			}
			setView(view, !!isRedirected, false)
		}
	}, [viewId, views])

	const setView = (view: ViewDTO, ignoreConfig = false, reset = true) => {
		if (!view) return
		setSelectedView(view)
		!ignoreConfig && setViewFilters(view.config?.filters ?? {})
		!ignoreConfig &&
			setViewGroupBy &&
			setViewGroupBy((view.config?.groupBy as OperatorAndValue) ?? null)
		!ignoreConfig && setViewSort && setViewSort(view.config?.sort)
		setViewColumnVisibility &&
			setViewColumnVisibility(view.config?.columnVisibility ?? {})
		setViewColumnOrder && setViewColumnOrder(view.config?.columnOrder ?? [])
		!ignoreConfig && reset && resetUrlParams()
		localStorage.setItem(LSSelectedViewKey, view._id)
		setViewId(view.viewNumber ?? 0)
		setIsRedirected(undefined)
	}

	const createView = ({
		name,
		config = {},
	}: {
		name: string
		config?: ViewDTO['config']
	}) => {
		createViewRequest.mutate(
			{
				body: {
					name,
					url: urlKey,
					parentId: appId,
					config: config ?? {},
				},
				pathParams: {
					parentId: appId,
				},
			},
			{
				onSuccess: (justCreatedView: ViewDTO) => {
					setView(justCreatedView)
				},
			}
		)
	}

	const updateView = (view: ViewDTO) => {
		if (view.url !== urlKey) return

		editView.mutate(
			{
				pathParams: {
					parentId: view.parentId,
					viewId: view._id,
				},
				body: view,
			},
			{
				onSuccess: (justEditedView: ViewDTO) => {
					setView(justEditedView)
				},
			}
		)
	}

	const removeView = (view: ViewDTO) => {
		deleteView.mutate(
			{
				pathParams: {
					parentId: view.parentId,
					viewId: view._id,
				},
			},
			{
				onSuccess: () => {
					if (view._id === selectedView?._id)
						onViewSelect(views[0]._id)
				},
			}
		)
	}

	/**
	 * Checks if there's an existing view with the same name to edit it instead of creating a new one with
	 * the same name, if not, creates a new view based on the selected view and the overrides
	 */
	const onViewSave = (name: string) => {
		const existingView = views.find(
			view => view.name.toLowerCase().trim() === name.toLowerCase().trim()
		)

		const filtersWithoutOverrides = removeEmptyOverrides({
			...selectedView?.config?.filters,
			...filters,
		})
		const groupByWithoutOverrides = removeEmptyOperatorAndValueOverrides({
			...selectedView?.config?.groupBy,
			...groupBy,
		} as OperatorAndValue)

		const hiddenColumns = removeTrueValues({
			...selectedView?.config?.columnVisibility,
			...columnVisibility,
		})

		const newColumnOrder =
			(columnOrder?.length > 0
				? columnOrder
				: selectedView?.config?.columnOrder) ?? []

		const config = {
			...selectedView?.config,
			filters: filtersWithoutOverrides,
			groupBy: groupByWithoutOverrides,
			...(sort && { sort }),
			columnVisibility: hiddenColumns,
			columnOrder: newColumnOrder,
		}

		if (existingView?._id) {
			updateView({
				...existingView,
				//@ts-ignore todo: fix
				config,
			})
		} else {
			createView({
				name,
				//@ts-ignore todo: fix
				config: config,
			})
		}
	}

	const onViewSelect = (viewId: string) => {
		const view = views.find(currentView => currentView._id === viewId)

		if (!view) return
		setView(view)
	}

	const handleClose = () => {
		setIsModalOpen(false)
		setIsDuplicating(false)
	}

	const onSave = ({ name }: { name: string }) => {
		onViewSave(name)
		setIsModalOpen(false)
	}

	if (!views || views.length === 0) return null

	return (
		<Box component="div" sx={{ width: matchDownMD ? '100%' : '20%' }}>
			<AdminModal
				onClose={handleClose}
				isOpen={isModalOpen || isDuplicating}
			>
				<ViewsForm
					onSave={onSave}
					onClose={handleClose}
					name={
						selectedView?.name === DEFAULT_GLOBAL_VIEWS_NAME
							? loggedInUser?.isAdmin
								? selectedView?.name
								: ''
							: selectedView?.name
					}
					isAdmin={loggedInUser?.isAdmin}
				/>
			</AdminModal>
			<Box
				p={1}
				display="flex"
				flexDirection="row"
				alignItems="center"
				justifyItems="center"
			>
				{hasOverrides && (
					<Tooltip
						aria-hidden="false"
						title="The view has unsaved changes"
					>
						<FiberManualRecordIcon
							fontSize="small"
							sx={{
								color: theme.palette.primary.main,
								fontSize: '0.75rem',
								marginRight: 0.5,
							}}
						/>
					</Tooltip>
				)}
				<SelectInput
					ariaLabel="Select view"
					value={selectedView?._id}
					options={views.map(({ name, _id }) => ({
						label: name,
						value: _id,
					}))}
					sx={{
						maxHeight: '32.25px',
						maxWidth: '200px',
					}}
					onChange={onViewSelect}
					extraOptions={[
						...(canCreate(variant?.role) || canEdit(variant?.role)
							? [
									{
										label: 'Save as...',
										onClick: () => {
											setIsModalOpen(true)
										},
									},
								]
							: []),
						...(canRemove(variant?.role)
							? [
									{
										label: 'Delete',
										onClick: () => {
											removeView(selectedView)
										},
									},
								]
							: []),
					]}
				/>
			</Box>
		</Box>
	)
}

export default ViewsManager
