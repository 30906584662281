import React from 'react'

import { Box, darken, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'

const StyledToolbar = styled(Box)({
	display: 'flex',
	alignItems: 'center',
	'& .MuiButton-root': {
		padding: '4px 16px',
		height: '32px',
		'&:hover': {
			backgroundColor: darken('#fff', 0.1),
		},
		'& .MuiButton-startIcon': {
			marginRight: 8,
			fontSize: 24,
		},
	},
})

interface TableToolbarProps {
	filter: string | null
	setFilter: (filter: string | null) => void
	actions: React.ReactNode[]
	buttons: React.ReactNode[]
}

const TableToolbar: React.FC<TableToolbarProps> = ({ buttons, actions }) => {
	return (
		<Container>
			<StyledToolbar>{...buttons}</StyledToolbar>
			<Stack spacing={1} direction="row">
				{actions}
			</Stack>
		</Container>
	)
}
const Container = styled('div')`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	background-color: #fff;
	height: 44px;
	width: calc(100vw - 91.61px);
`

export default TableToolbar
