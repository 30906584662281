import { useQueryClient } from '@tanstack/react-query'
import {
	useTagsControllerCreate,
	useTagsControllerGetAppTags,
	useTagsControllerRemoveTag,
	useTagsControllerUpdateParent,
	useTagsControllerUpdateTag,
} from 'api/repository/repositoryComponents'
import { DEFAULT_STALE_TIME } from 'deprecated/services/constants'
import { toast } from 'react-hot-toast'
import type { TagWithParent } from 'shared/types'

export const useTags = (appId: string) => {
	const queryClient = useQueryClient()

	const { data, isLoading } = useTagsControllerGetAppTags(
		{
			pathParams: {
				id: appId,
			},
		},
		{
			staleTime: DEFAULT_STALE_TIME,
			enabled: !!appId,
		}
	)

	const tags: Array<TagWithParent> = data?.data ?? []

	const create = useTagsControllerCreate({
		onSuccess: () => {
			queryClient.invalidateQueries()
			toast.success(`Tag created`)
		},
		onError: error => {
			toast.error(error.stack.message)
		},
	})

	const update = useTagsControllerUpdateTag({
		onSuccess: () => {
			queryClient.invalidateQueries()
			toast.success(`Tag edited`)
		},
		onError: error => {
			toast.error(error.stack.message)
		},
	})

	const deactivate = useTagsControllerRemoveTag({
		onSuccess: () => {
			queryClient.invalidateQueries()
			toast.success(`Tag Removed`)
		},
		onError: error => {
			toast.error(error.stack.message)
		},
	})

	const updateParent = useTagsControllerUpdateParent({
		onSuccess: () => {
			queryClient.invalidateQueries()
			toast.success(`Tag edited`)
		},
		onError: error => {
			toast.error(error.stack.message)
		},
	})

	return { tags, isLoading, create, update, deactivate, updateParent }
}
