import type { ApplicationDTO } from '@asta/repository-dtos'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import ApplicationsService from './ApplicationsService'

const initialState: ApplicationDTO[] = []

export const fetchApplications = createAsyncThunk(
	'applicationsV2/fetch',
	async () => {
		const res = await ApplicationsService.fetchAll()
		return res.data
	}
)

const activitySetSlice = createSlice({
	name: 'applicationsV2',
	initialState,
	extraReducers: {
		[fetchApplications.fulfilled]: (state, action) => {
			return [...action.payload]
		},
	},
	reducers: undefined,
})

const { reducer } = activitySetSlice
export default reducer
