import type {
	CreateVariantDTO,
	UpdateVariantDto,
	VariantDTO,
} from '@asta/repository-dtos'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import VariantService from './VariantService'

export const variantInitialState: VariantDTO[] = []

export const createVariant = createAsyncThunk(
	'variant/create',
	async (variant: CreateVariantDTO) => {
		const res = await VariantService.create(variant)
		return res.data.data
	}
)

export const updateVariant = createAsyncThunk(
	'variant/update',
	async (up: { id: string; variant: UpdateVariantDto }) => {
		const res = await VariantService.update(up.id, up.variant)
		return res.data.data
	}
)

export const fetchVariants = createAsyncThunk('variant/fetchAll', async () => {
	const res = await VariantService.fetchAll()
	return res.data.data
})

export const fetchVariant = createAsyncThunk(
	'variant/fetch',
	async (id: string) => {
		const res = await VariantService.fetch(id)
		return res.data.data
	}
)

const variantSlice = createSlice({
	name: 'variant',
	initialState: variantInitialState,
	extraReducers: builder => {
		builder.addCase(createVariant.fulfilled, (state, action) => {
			return [...state, action.payload]
		}),
			builder.addCase(fetchVariants.fulfilled, (state, action) => {
				return [...action.payload]
			})
		builder.addCase(fetchVariant.fulfilled, (state, action) => {
			const index = state.find(st => st._id === action.payload.id)

			if (index) {
				return state.map(st => {
					if (st._id === action.payload._id) return action.payload

					return st
				})
			}

			return [...state, action.payload]
		}),
			builder.addCase(updateVariant.fulfilled, (state, action) => {
				return state.map(st =>
					st._id === action.payload._id ? action.payload : st
				)
			})
	},
	reducers: undefined,
})

const { reducer } = variantSlice
export default reducer
