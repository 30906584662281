import React from 'react'

import { Box, darken, useMediaQuery, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import type { FC } from 'react'

import CustomFilter from '../CustomFilter'
import GroupFilter from '../GroupFilter'
import HideColumnFilter from '../HideColumnFilter'
import SearchInput from '../SearchFilter'
import { SortFilter } from '../SortFilter'
import type { FilterShape } from './filter-shapes'

const StyledToolbar = styled(Box)({
	display: 'flex',
	alignItems: 'center',
	'& .MuiButton-root': {
		padding: '4px 16px',
		height: '32px',
		'&:hover': {
			backgroundColor: darken('#fff', 0.1),
		},
		'& .MuiButton-startIcon': {
			marginRight: 8,
			fontSize: 24,
		},
	},
})

export interface TableFiltersProps {
	filters: FilterShape[]
	actions?: React.ReactNode[]
}

export const TableFilters: FC<React.PropsWithChildren<TableFiltersProps>> = ({
	filters,
	actions,
	children,
}) => {
	const theme = useTheme()
	const matchDownMD = useMediaQuery(theme.breakpoints.down('md'))

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
				backgroundColor: '#fff',
				height: matchDownMD ? 'auto' : '50px',
				width: matchDownMD ? '100%' : 'calc(100vw - 91.61px)',
				flexWrap: 'wrap-reverse',
			}}
		>
			<StyledToolbar>
				{filters.map(filter => {
					switch (filter.type) {
						case 'search':
							return (
								<SearchInput
									key={filter.value?.toString() || filter.key}
									value={filter.value}
									onChange={filter.onChange}
									operator={filter.operator}
								/>
							)

						case 'custom':
							return (
								<CustomFilter
									key={filter.key}
									options={filter.options}
									setTableFilters={filter.onChange}
									value={filter.value}
								/>
							)

						case 'group':
							return (
								<GroupFilter
									key={filter.key}
									options={filter.options}
									onChange={filter.onChange}
									value={filter.value}
								/>
							)

						case 'hide-columns':
							return (
								<HideColumnFilter
									key={filter.key}
									onChange={filter.onChange}
									value={filter.value}
									options={filter.options}
								/>
							)

						case 'sort':
							return (
								<SortFilter
									key={filter.key}
									options={filter.options}
									onChange={filter.onChange}
									value={filter.value}
								/>
							)

						default:
							return null
					}
				})}
				{actions}
			</StyledToolbar>
			{children}
		</Box>
	)
}
