import React, { useEffect, useRef, useState } from 'react'

import { SelectInput } from '@asta/react-component-library'
import CloseIcon from '@mui/icons-material/Close'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined'
import { Box, IconButton, Typography } from '@mui/material'
import type { FC } from 'react'

import type { OperatorAndValue } from '../TableFilters'

export interface GroupByOptions {
	label: string
	value: string
}

interface Props {
	value: OperatorAndValue
	options: GroupByOptions[]
	onChange: (filters: OperatorAndValue) => void
}

const GroupFilter: FC<Props> = ({ value, options, onChange }) => {
	const [open, setOpen] = useState(false)
	const ref = useRef(null)

	useEffect(() => {
		const close = () => setOpen(false)
		const handleClickOutside = event => {
			if (ref.current && !ref.current.contains(event.target)) {
				close()
			}
		}
		const onKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				close()
			}
		}

		document.addEventListener('click', handleClickOutside, true)
		document.addEventListener('keydown', onKeyDown)
		return () => {
			document.removeEventListener('click', handleClickOutside, true)
			document.removeEventListener('keydown', onKeyDown)
		}
	}, [ref])

	const onOptionSelect = (selectedOption: string) => {
		onChange({ $eq: selectedOption } as OperatorAndValue)
	}

	const reset = (e: React.MouseEvent) => {
		e.preventDefault()
		e.stopPropagation()
		onChange(null)
	}

	return (
		<Box>
			<IconButton
				size="small"
				sx={{
					margin: '4px',
					width: 'auto',
					color: '#0000008A',
					...(value && value.$eq && { background: '#E0DAFD' }),
				}}
				onClick={() => setOpen(prev => !prev)}
			>
				<FormatListBulletedOutlinedIcon />
				<Typography variant="body1" sx={{ px: '2px' }}>
					{value && value.$eq
						? `Grouped by ${
								options.find(
									option => option.value === value.$eq
								).label
							}`
						: 'Group'}
				</Typography>
				{value && value.$eq && (
					<CloseIcon
						onClick={reset}
						sx={{ ml: '4px', fontSize: '12px', color: '#0000008A' }}
					/>
				)}
			</IconButton>
			{open && (
				<Box
					ref={ref}
					sx={{
						display: 'flex',
						flexDirection: 'column',
						position: 'absolute',
						marginTop: 0,
						background: 'white',
						borderRadius: '4px',
						padding: '20px',
						boxShadow:
							'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
						zIndex: 11,
						minWidth: '300px',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
							width: '100%',
							marginBottom: '8px',
							paddingBottom: '4px',
						}}
					>
						<Typography
							variant="h6"
							color="hsl(0,0%,30%)"
							sx={{ fontWeight: 'bold' }}
						>
							Group By
						</Typography>
						<CloseOutlinedIcon
							sx={{ fontSize: '1rem', cursor: 'pointer' }}
							onClick={() => setOpen(false)}
						/>
					</Box>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
							alignItems: 'flex-start',
						}}
					>
						{(!value || !value.$eq) && (
							<>
								<Typography
									variant="body2"
									color="hsl(0,0%,30%)"
									sx={{ marginBottom: '5px' }}
								>
									Pick a field to group by
								</Typography>
								{options.map(option => (
									<Box
										key={`group-by-option-${option.value}`}
										sx={{
											cursor: 'pointer',
											width: '100%',
											p: 1,
											':hover': {
												backgroundColor: '#9FC3F870',
											},
										}}
										onClick={() =>
											onOptionSelect(option.value)
										}
									>
										{option.label}
									</Box>
								))}
							</>
						)}

						{value && value.$eq && (
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									width: '100%',
									cursor: 'pointer',
								}}
							>
								<SelectInput
									value={value.$eq}
									options={options}
									onChange={onOptionSelect}
								/>
								<Box
									sx={{
										p: 1,
										border: '1px solid #CCCCCC',
										borderRadius: '4px',
										alignItems: 'center',
									}}
									onClick={reset}
								>
									<DeleteOutlineOutlinedIcon
										sx={{
											fontSize: '16px',
											cursor: 'pointer',
										}}
									/>
								</Box>
							</Box>
						)}
					</Box>
				</Box>
			)}
		</Box>
	)
}

export default GroupFilter
