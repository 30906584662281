/**
 * This enables storing component associations with datasets and requirements
 * documents. Primarily useful for linking a webform to the requirements
 * documents for its FormSpec versions and the datasets generated from those.
 *
 * Looking forward, it's imaginable that components would have many other
 * associations (rules, activities, log entries, etc). Currently these
 * associations exist and are made in the UI via lookups.
 *
 * Example, to generate the summary statistics for a component over a set of
 * runs, all the runs' logs are scanned for rule-scenario-result entries with
 * that component and its summary statistics are incremented accordingly, plus
 * summing the statistics of its descendant components, whose statistics are
 * computed the same way.
 *
 * As the size of logs grow these computations become unfeasible, instead we
 * need to store precomputed things like summary statistics, the log entries,
 * performance statistics, rules, and activities associated with these
 * components.
 *
 * I am not sure what the best way to solve this is. One solution requires
 * more adding additional entities to the data-model to store these pre-computed
 * things and associations. Another requires adding only one entity to the
 * data-model, but making its schema very flexible so it can store each of
 * these associations. The ladder provides us a conveinent way to associate
 * additional unexpected things with components. The former provides an easier
 * to comprehend but more rigid data model.
 */

import type { BaseResourceState } from '../base/base'
import {
	buildResourceReducer,
	createOne,
	fetchAll,
	fetchOne,
	initialBaseResourceState,
	RESOURCE,
	updateOne,
} from '../base/base'

/**
 * Encapsulates the associations an application
 * component has to other entities in the system.
 *
 * This association may be between the application
 * component and a requirments document or dataset.
 */
export type ApplicationComponentsAssociationsDTO = {
	/**
	 * Id of the application component.
	 * Duplicated to enforce standards expected
	 * for DTOs on CRUD endpoints.
	 */
	id: string

	/**
	 * Id of the application.
	 */
	applicationId: string

	/**
	 * Id of the application component.
	 */
	componentId: string

	/**
	 * Entities associated with the component.
	 */
	associatedEntities: {
		/**
		 * The identifier for the associated entity.
		 * Not just a string id because I am imaging
		 * this supporting things like identifying a
		 * range of run log entries.
		 */
		identifier: {
			type: 'id'
			value: string
		}

		/**
		 * The type of entity being associated.
		 */
		type: 'dataset' | 'requirement-document'
	}[]
}

type ApplicationComponentAssociationsState =
	BaseResourceState<ApplicationComponentsAssociationsDTO>

export const applicationComponentAssocationsInitialState: ApplicationComponentAssociationsState =
	{
		...initialBaseResourceState,
	}

export const componentsAssociatedEntitiesSelector = (
	state: any,
	appId: string,
	compId: string
): ApplicationComponentsAssociationsDTO | null => {
	const sliceState =
		state.applicationComponentAssociations as ApplicationComponentAssociationsState

	return sliceState.resources.find(
		r => r.applicationId === appId && r.componentId === compId
	)
}

export const fetchAllApplicationComponentAssociations = (
	appId: string
): ((dispatch: any) => void) =>
	fetchAll(
		RESOURCE.APPLICATION_COMPONENT_ASSOCIATIONS,
		`/api/v2/variants/${appId}/model/componentAssociations`
	)

export const fetchApplicationComponentsAssociations = (
	appId: string,
	compId: string
): ((dispatch: any) => void) =>
	fetchOne(
		RESOURCE.APPLICATION_COMPONENT_ASSOCIATIONS,
		compId,
		`/api/v2/variants/${appId}/model/componentAssociations/${compId}`
	)

export const appendApplicationComponentsAssociations = (
	appId: string,
	compId: string,
	associationsDTO: ApplicationComponentsAssociationsDTO
): ((dispatch: any) => void) =>
	createOne(
		RESOURCE.APPLICATION_COMPONENT_ASSOCIATIONS,
		associationsDTO,
		`/api/v2/variants/${appId}/model/componentAssociations/${compId}`
	)

export const updateApplicationComponentsAssociations = (
	appId: string,
	compId: string,
	associationsDTO: ApplicationComponentsAssociationsDTO
): ((dispatch: any) => void) =>
	updateOne(
		RESOURCE.APPLICATION_COMPONENT_ASSOCIATIONS,
		associationsDTO,
		`/api/v2/variants/${appId}/model/componentAssociations/${compId}`
	)

export const applicationComponentAssociationsReducer = buildResourceReducer(
	applicationComponentAssocationsInitialState,
	RESOURCE.APPLICATION_COMPONENT_ASSOCIATIONS
)
