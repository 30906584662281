import type { RunParameters } from '@asta/core'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { initialState } from 'store'

import RunParametersService from './RunParametersService'

export const runParametersInitialState: RunParameters[] = []

export const createRunParameters = createAsyncThunk(
	'parameters/create',
	async (params: RunParameters) => {
		const res = await RunParametersService.create(params)
		return res.data
	}
)

export const deleteRunParameters = createAsyncThunk(
	'parameters/delete',
	async (id: string) => {
		await RunParametersService.delete(id)
		return id
	}
)

export const updateRunParameters = createAsyncThunk(
	'runParameters/update',
	async (up: { id: string; params: RunParameters }) => {
		const res = await RunParametersService.update(up.id, up.params)
		return res.data
	}
)

export const fetchRunParameters = createAsyncThunk(
	'runParameters/fetchAll',
	async (id: string) => {
		const res = await RunParametersService.fetch(id)
		return res.data
	}
)

export const fetchAllRunParameters = createAsyncThunk(
	'runParameters/fetch',
	async () => {
		const res = await RunParametersService.fetchAll()
		return res.data
	}
)

export const allRunParametersSelector = (
	state: typeof initialState
): RunParameters[] => state.runParameters

export const runParametersSelector = (
	state: typeof initialState,
	id: string
): RunParameters | null => state.runParameters.find(r => (r._id = id))

const runParametersSlice = createSlice({
	name: 'runParameters',
	initialState: runParametersInitialState,
	extraReducers: builder => {
		builder.addCase(createRunParameters.fulfilled, (state, action) => {
			return [...state, action.payload]
		}),
			builder.addCase(deleteRunParameters.fulfilled, (state, action) => {
				return state.filter(params => params._id != action.payload)
			}),
			builder.addCase(
				fetchAllRunParameters.fulfilled,
				(state, action) => {
					return [...action.payload]
				}
			),
			builder.addCase(fetchRunParameters.fulfilled, (state, action) => {
				const index = state.find(st => st._id === action.payload.id)
				if (index) {
					return state.map(st => {
						if (st._id === action.payload._id) return action.payload
						return st
					})
				}
				return [...state, action.payload]
			}),
			// @prettier-ignore
			builder.addCase(updateRunParameters.fulfilled, (state, action) => {
				return state.map(st =>
					st._id === action.payload._id ? action.payload : st
				)
			})
	},
	reducers: undefined,
})

const { reducer } = runParametersSlice
export default reducer
