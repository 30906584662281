import React, { useCallback, useContext } from 'react'

import { useToggle } from '@asta/react-component-library'
import { MenuItem } from '@mui/material'
import Modal from 'components/shared/AstaModal/Modal'
import CopyAndMoveForm from 'components/shared/MoveAndCopy/CopyAndMoveForm'
import { AuthContext } from 'contexts/auth'
import type { SelectedAppProps } from 'contexts/selectedApp'
import { WithSelectedApp } from 'contexts/selectedApp'
import { useAssets } from 'hooks/App/useAssets'
import { canCreate } from 'pages/Admin/helpers'
import type { FC } from 'react'
import type { Asset, AssetWithParent } from 'shared/types'

interface Props {
	type: Asset['type']
	asset: AssetWithParent
	onClose: () => void
}

const MoveAssetMenu: FC<Props & SelectedAppProps> = ({
	selectedApp,
	type,
	asset,
	onClose,
}) => {
	const [open, , on, close] = useToggle()

	const { user } = useContext(AuthContext)
	const { updateAssetParent } = useAssets(type)

	const onMove = useCallback(
		(name: string, newParent: string) => {
			updateAssetParent.mutate({
				pathParams: {
					id: asset._id,
					type,
					appId: asset.parent._id,
				},
				body: { newParent, name },
			})

			onClose()
		},
		[onClose, updateAssetParent, asset, type]
	)

	const _onClose = useCallback(() => {
		close()
		onClose()
	}, [close, onClose])

	if (!canCreate(asset.parent.role) && !user?.isAdmin) return null

	return (
		<>
			<MenuItem onClick={on}>Move to</MenuItem>
			<Modal onClose={_onClose} isOpen={open}>
				<CopyAndMoveForm
					selectedApp={selectedApp}
					onSave={onMove}
					onClose={_onClose}
					name={asset.name}
					isAdmin={user?.isAdmin}
					type="move"
					resource={asset}
				/>
			</Modal>
		</>
	)
}

export default WithSelectedApp({})(MoveAssetMenu)
