import React from 'react'

import { MoreVert } from '@mui/icons-material'
import { Box, Menu } from '@mui/material'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'

interface Props {
	children?: (args: { onClick: () => void }) => JSX.Element
}

const AstaKebabMenu: React.FC<Props> = ({ children }) => {
	return (
		<PopupState variant="popover" popupId="demo-popup-menu">
			{popupState => (
				<Box>
					<Box id="asta-kebab-menu" sx={{ cursor: 'pointer' }}>
						{children && (
							<MoreVert
								fontSize="small"
								{...bindTrigger(popupState)}
							/>
						)}
					</Box>
					<Menu {...bindMenu(popupState)}>
						{children &&
							children({ onClick: () => popupState.close() })}
					</Menu>
				</Box>
			)}
		</PopupState>
	)
}

export default AstaKebabMenu
