import type {
	CreateWorkspaceDTO,
	UpdateWorkspaceDTO,
} from '@asta/repository-dtos'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { Workspace } from 'shared/types'

import WorkspaceService from './WorkspaceService'

export const workspaceInitialState: Workspace[] = []

export const createWorkspace = createAsyncThunk(
	'workspace/create',
	async (workspace: CreateWorkspaceDTO) => {
		const res = await WorkspaceService.create(workspace)
		return res.data.data
	}
)

export const updateWorkspace = createAsyncThunk(
	'workspace/update',
	async (up: { id: string; workspace: UpdateWorkspaceDTO }) => {
		const res = await WorkspaceService.update(up.id, up.workspace)
		return res.data.data
	}
)

export const fetchWorkspaces = createAsyncThunk(
	'workspace/fetchAll',
	async () => {
		const res = await WorkspaceService.fetchAll()
		return res.data
	}
)

export const fetchWorkspace = createAsyncThunk(
	'workspace/fetch',
	async (id: string) => {
		const res = await WorkspaceService.fetch(id)
		return res.data.data
	}
)

const workspaceSlice = createSlice({
	name: 'workspace',
	initialState: workspaceInitialState,
	extraReducers: builder => {
		builder.addCase(createWorkspace.fulfilled, (state, action) => {
			return [...state, action.payload]
		}),
			builder.addCase(fetchWorkspaces.fulfilled, (state, action) => [
				...action.payload,
			]),
			builder.addCase(fetchWorkspace.fulfilled, (state, action) => {
				const index = state.find(st => st._id === action.payload.id)

				if (index) {
					return state.map(st => {
						if (st._id === action.payload._id) return action.payload

						return st
					})
				}

				return [...state, action.payload]
			}),
			builder.addCase(updateWorkspace.fulfilled, (state, action) => {
				return state.map(st =>
					st._id === action.payload._id ? action.payload : st
				)
			})
	},
	reducers: undefined,
})

const { reducer } = workspaceSlice
export default reducer
