import type { RunParameters } from '@asta/core'

import axios from './axios-base'

const create = (params: RunParameters) =>
	axios.post('/api/v2/run/parameters', params)

// prettier-ignore
const fetchAll = () => 
	axios.get('/api/v2/run/parameters')

// prettier-ignore
const fetch = (id: string) => 
	axios.get('/api/v2/run/parameters/' + id)

const update = (id: string, params: RunParameters) =>
	axios.put('/api/v2/run/parameters/' + id, params)

const RunParametersService = {
	create,
	fetch,
	fetchAll,
	update,
	delete: (id: string) => axios.post(`/api/v2/run/parameters/${id}/delete`),
}

export default RunParametersService
