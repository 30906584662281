import { useQueryClient } from '@tanstack/react-query'
import {
	useWorkspaceControllerAddUnlimitedPlan,
	useWorkspaceControllerCreateWorkspace,
	useWorkspaceControllerGetWorkspaces,
	useWorkspaceControllerUpdateWorkspace,
} from 'api/repository/repositoryComponents'
import { DEFAULT_STALE_TIME } from 'deprecated/services/constants'
import { toast } from 'react-hot-toast'
import type { Workspace } from 'shared/types'

export const useWorkspaces = () => {
	const queryClient = useQueryClient()
	const addUnlimitedPlan = useWorkspaceControllerAddUnlimitedPlan()

	const {
		data: initialData,
		isLoading,
		error,
	} = useWorkspaceControllerGetWorkspaces(
		{},
		{
			staleTime: DEFAULT_STALE_TIME,
		}
	)

	const workspaces: Array<Workspace> = initialData?.data

	const createWorkspace = useWorkspaceControllerCreateWorkspace({
		onSuccess: () => {
			queryClient.invalidateQueries()
			toast.success(`Workspace Created!`)
		},
		onError: error => {
			toast.error(error.stack.message)
		},
	})

	const updateWorkspace = useWorkspaceControllerUpdateWorkspace({
		onSuccess: () => {
			queryClient.invalidateQueries()
			toast.success(`Workspace Edited!`)
		},
		onError: error => {
			toast.error(error.stack.message)
		},
	})

	const upgradeToUnlimitedPlan = (workspaceId: string) => {
		return addUnlimitedPlan.mutate(
			{
				pathParams: {
					wsId: workspaceId,
				},
			},
			{
				onSuccess: () => {
					queryClient.invalidateQueries()
					toast.success(`Workspace upgraded to unlimited plan`)
				},
			}
		)
	}

	return {
		workspaces,
		isLoading,
		createWorkspace,
		updateWorkspace,
		error,
		upgradeToUnlimitedPlan,
	}
}
