import React, {
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useState,
} from 'react'

import { useWorkspaceContext } from 'contexts/workspace'
import { useAllVariants } from 'pages/Admin/_variants/hooks/useAllVariants'
import type { FC, PropsWithChildren } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import type { Variant } from 'shared/types'

import type { SelectedAppContextProps } from './'
import { SelectedAppContext, selectedAppReducer } from './'

export interface SelectedAppState {
	selectedApp: Variant | undefined
}

const SELECTED_APP_INITIAL_STATE: SelectedAppState = {
	selectedApp: undefined,
}

export const SelectedAppProvider: FC<PropsWithChildren<{}>> = ({
	children,
}) => {
	const { workspace, setWorkspace } = useWorkspaceContext()

	const [state, dispatch] = useReducer(
		selectedAppReducer,
		SELECTED_APP_INITIAL_STATE
	)
	const [displayName, setDisplayName] = useState('')
	const { variants, isLoading } = useAllVariants()
	const { appId } = useParams()
	const navigate = useNavigate()
	const location = useLocation()

	const availableVariants = useMemo(() => {
		if (!variants) return []
		if (workspace)
			return variants.filter(
				variant =>
					(variant.parent.parent as unknown as string) ===
					workspace?._id
			)
		return variants
	}, [workspace, variants])

	const setSelectedApp = (variant: Variant) => {
		const queryParams =
			!state.selectedApp || variant?._id === state.selectedApp._id
				? location.search
				: ''

		dispatch({
			type: '[SelectedApp] - Set selected app',
			payload: variant,
		})

		if (variant) {
			localStorage.setItem('selectedApp', variant._id)
			if (!workspace)
				setWorkspace(variant.parent.parent as unknown as string)
		}
		if (!location.pathname.includes('admin') && variant) {
			const path = location.pathname.startsWith('/app/')
				? location.pathname.replace(
						/app\/([0-9,a-f]+)/,
						`app/${variant._id}`
					)
				: `/app/${variant._id}`
			navigate(`${path}${queryParams}`)
		}
	}

	//Sets selected app if there's no appId in the URL
	useEffect(() => {
		//Select the first variant in the array if a variant has not been selected
		if (
			!isLoading &&
			availableVariants?.length > 0 &&
			!state.selectedApp &&
			!appId
		) {
			const lastSelectedVariant = localStorage.getItem('selectedApp')
			const urlApp = location.pathname.split('/')[2]

			/**
			 * This will use the app provided in the url over the last selected app stored in LS
			 * If the user doesn't have permissions on the selected app or it's not found, it will default to the
			 * last selected app in the LS, and then if not available, it will select the first app in the array
			 */
			const appIdToUse = urlApp ?? lastSelectedVariant

			const urlVariant = availableVariants.find(
				variant => variant._id === appIdToUse
			)

			/**
			 * Because the urlVariant wasn't found, use the lastSelectedVariant in the LS
			 */
			const variantToSelect =
				urlVariant ??
				availableVariants.find(
					variant => variant._id === lastSelectedVariant
				)

			setSelectedApp(variantToSelect ?? availableVariants[0])
		}

		if (
			!state.selectedApp &&
			appId &&
			!isLoading &&
			availableVariants.length > 0
		) {
			const app = availableVariants.find(variant => variant._id === appId)
			if (app) {
				setSelectedApp(app)
			} else {
				setSelectedApp(undefined)
			}
		}

		if (availableVariants && availableVariants.length === 0) {
			setSelectedApp(undefined)
		}
	}, [availableVariants, state.selectedApp, isLoading, appId])

	//Sets selected app according to the appId in the route params
	useEffect(() => {
		if (appId && availableVariants && availableVariants.length > 0) {
			const variantToSet = availableVariants.find(
				variant => variant._id === appId
			)
			setSelectedApp(variantToSet ?? undefined)
		}
	}, [appId, availableVariants])

	useEffect(() => {
		if (state.selectedApp) {
			React.startTransition(() => {
				setDisplayName(
					`${state.selectedApp.parent.name} (${state.selectedApp.name})`
				)
			})
		}
	}, [state.selectedApp])

	useEffect(() => {
		if (
			availableVariants &&
			availableVariants.length > 0 &&
			state.selectedApp
		) {
			const isSelectedAppPresent = availableVariants.find(
				variant => variant._id === state.selectedApp._id
			)
			if (!isSelectedAppPresent) {
				setSelectedApp(undefined)
			} else {
				setSelectedApp(isSelectedAppPresent)
			}
		}
	}, [availableVariants])

	const removeSelectedApp = useCallback(() => {
		dispatch({ type: '[SelectedApp] - Remove selected app' })
		localStorage.removeItem('selectedApp')
	}, [])

	const contextValue: SelectedAppContextProps = useMemo(
		() => ({
			...state,
			displayName,
			selectableVariants: availableVariants,

			// Methods
			setSelectedApp,
			removeSelectedApp,
		}),
		[
			state,
			displayName,
			setSelectedApp,
			removeSelectedApp,
			availableVariants,
		]
	)

	return (
		<SelectedAppContext.Provider value={contextValue}>
			{children}
		</SelectedAppContext.Provider>
	)
}
