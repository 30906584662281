import React from 'react'

import { Box, Dialog, IconButton, styled } from '@mui/material'
import { CrossIcon } from 'evergreen-ui'

const PaddedBox = styled(Box, { label: 'padded-box' })`
	padding: 2rem;
`

interface Props {
	onClose: () => void
	isOpen: boolean
	children: React.ReactNode
}

const Modal: React.FC<Props> = ({ isOpen, onClose, children }) => {
	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-start',
					width: 700,
					height: 'auto',
				}}
			>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						justifyContent: 'flex-end',
						cursor: 'pointer',
						fontSize: '1rem',
						padding: 2,
					}}
				>
					<IconButton aria-label="close-modal" onClick={onClose}>
						<CrossIcon scale="2x" />
					</IconButton>
				</Box>
				<PaddedBox>{children}</PaddedBox>
			</Box>
		</Dialog>
	)
}

export default Modal
