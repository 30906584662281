import React from 'react'

import { Box, styled } from '@mui/material'

export interface ContextMenuOption {
	label: string
	onClick: () => void
}

const MenuContainer = styled(Box, {
	shouldForwardProp: prop => prop !== 'transform',
})<{ transform: string }>(({ theme, transform }) => ({
	backgroundColor: theme.palette.background.paper,
	display: 'flex',
	flexDirection: 'column',
	position: 'absolute',
	width: 200,
	borderRadius: 4,
	transform,
	overflow: 'hidden',
	zIndex: 1000,
}))

const MenuItem = styled(Box)(({ theme }) => ({
	display: 'flex',
	width: '100%',
	padding: 4,
	cursor: 'pointer',
	':hover': {
		backgroundColor: theme.palette.action.hover,
	},
	minHeight: 34,
	alignItems: 'center',
}))

interface Props {
	contextMenuOptions: ContextMenuOption[]
	transform: string
	left: number
}

const AstaRightClickMenu: React.FC<Props> = ({
	contextMenuOptions,
	transform,
	left,
}) => {
	return (
		<MenuContainer transform={transform} left={left - 100}>
			{contextMenuOptions.map(option => (
				<MenuItem key={option.label} onClick={option.onClick}>
					{option.label}
				</MenuItem>
			))}
		</MenuContainer>
	)
}

export default AstaRightClickMenu
