import type { Request } from './base'
import { get } from './base'

/******************************* STATE ****************************************/
export type RunsState = {
	newRuns: {
		id: string
		applicationId: string
		modelId: string
		metadata: {
			runNumber: number
			parametersId: string
			rulesetId: string
			startTime: string
			endTime: string
			status: string
		}
		log: RunLog | null
	}[]

	runs: { [runId: string]: Run }
	runStatuses: Record<string, any>
	runLogs: { [runId: string]: RunLog }
	getRunsRequest: Request
	getRunLogsRequest: Request
	getRunStatusRequests: { [runId: string]: Request }
	getRunStatusPollings: { [runId: string]: boolean }
}

export const runsInitialState: RunsState = {
	newRuns: [],

	runs: {},
	runLogs: {},
	runStatuses: {},
	getRunsRequest: {
		pending: false,
		error: null,
	},
	getRunLogsRequest: {
		pending: false,
		error: null,
	},
	getRunStatusRequests: {},
	getRunStatusPollings: {},
}

/**
 * Data about an ASTA run, which may contain multiple tests.
 */
export type Run = {
	id: string
	applicationId: string
	parametersId: string
	rulsetId: string
	runNumber: string
	startTime: string
	endTime: string
	status: string

	// added to consolidate other stores
	stats: RunStatistics
	currPage: Page | null
	// status: RunStatus

	// added to consolidate (summary stats, current page, and details)
	detailedStatus: RunStatus
}

/**
 * A RunLog is a chronological list of the actions and rule results that
 * an agent executed during the course of a run.
 */
export type RunLog = {
	runId: string
	runNumber: string
	entries: RunLogEntry[]
}

export type RunLogEntry = {
	[x: string]: any
	id: string
	parentId: string
	type: string
	level: string
	timestamp: string
	context: any
	source: any
	result: string
	data: any
}

/**
 * Provides more detail about the current status of a run including the current
 * page that's being tested, statistics summarizing the number of components
 * tested, and the run's parameters.
 */
export type RunStatus = {
	stats: RunStatistics | null
	details: RunDetails | null
	currPage: Page | null
}

/**
 * Essentially the run parameters + some additional metadata created with the
 * run.
 */
export type RunDetails = {
	runId: string
	applicationName: string
	startingPageTitle: string
	depth: number
	startTime: string
	endTime: string
	currentPageTitle: string
	currentComponentLabel: string
	runningState: 'starting' | 'running' | 'paused' | 'stopped' | 'finished'
}

/**
 * Statistics enumerate each of the test types then include the number
 * of tests in each status for that type.
 */
export type RunStatistics = {
	stats: TestStatistics[]
}

export type TestStatistics = {
	/** The type of test (e.g., link, form, page) */
	type: string
	failed: number
	succeeded: number
	untested: number
}

/**
 * A page is the current page the agent is on during a run. It is a model
 * of the webpage.
 */
export type Page = {
	dimensions: Dimensions
	components: PageComponent[]
}
export type PageComponent = {
	label: string
	position: Position
	dimensions: Dimensions
}
export type Dimensions = { w: number; h: number }
export type Position = { x: number; y: number; z: number }

/***************************** SELECTORS **************************************/
export const runsSelector = (state: any) => state.runs.runs
export const runSelector = (state: any, id: string) => state.runs.runs[id]
export const getRunsPendingSelector = (state: any) =>
	state.runs.getRunsRequest.pending
export const getRunsErrorSelector = (state: any) =>
	state.runs.getRunsRequest.error

export const runStatusSelector = (state: any) => state.runs.runStatuses

export const runLogsSelector = (state: any) => state.runs.runLogs
export const getRunLogsPendingSelector = (state: any) =>
	state.runs.getRunLogsRequest.pending
export const getRunLogsErrorSelector = (state: any) =>
	state.runs.getRunLogsRequest.error

export const runLogEntrySelector = (
	state: any,
	runId: string,
	entryIdx: number
) => {
	const entries = state.runs.runLogs
		.filter((rl: any) => rl.runId == runId)
		.map(r => r.entries)
		.flat()
	if (entries == null || entries.length == 0) return null
	return entries[entryIdx]
}

export const getRunStatusRequestsSelector = (state: any) =>
	state.runs.getRunStatusRequests
export const getRunStatusPollingsSelector = (state: any) =>
	state.runs.getRunStatusPollings

/****************************** ACTIONS ***************************************/
export type GetRunsActionTypes =
	| GetRunsPendingAction
	| GetRunsSuccessAction
	| GetRunsErrorAction

export const GET_RUNS_PENDING = 'GET_RUNS_PENDING'
export const GET_RUNS_SUCCESS = 'GET_RUNS_SUCCESS'
export const GET_RUNS_ERROR = 'GET_RUNS_ERROR'

interface GetRunsPendingAction {
	type: typeof GET_RUNS_PENDING
}

interface GetRunsSuccessAction {
	type: typeof GET_RUNS_SUCCESS
	runs: {
		[index: string]: Run
	}
}

interface GetRunsErrorAction {
	type: typeof GET_RUNS_ERROR
	error: any
}

export function getRunsPending() {
	return {
		type: GET_RUNS_PENDING,
	}
}

export function getRunsSuccess(runs: { [index: string]: Run }) {
	return {
		type: GET_RUNS_SUCCESS,
		runs,
	}
}

export function getRunsError(error: any) {
	return {
		type: GET_RUNS_ERROR,
		error,
	}
}

// Get Run Logs Actions

export type GetRunLogsActionTypes =
	| GetRunLogsPendingAction
	| GetRunLogsSuccessAction
	| GetRunLogsErrorAction
	| GetRunLogSuccessAction

export const GET_RUN_LOGS_PENDING = 'GET_RUN_LOGS_PENDING'
export const GET_RUN_LOGS_SUCCESS = 'GET_RUN_LOGS_SUCCESS'
export const GET_RUN_LOGS_ERROR = 'GET_RUN_LOGS_ERROR'

interface GetRunLogsPendingAction {
	type: typeof GET_RUN_LOGS_PENDING
}

interface GetRunLogsSuccessAction {
	type: typeof GET_RUN_LOGS_SUCCESS
	runLogs: { [runId: string]: RunLog }
}

interface GetRunLogsErrorAction {
	type: typeof GET_RUN_LOGS_ERROR
	error: any
}

export function getRunLogsPending() {
	return {
		type: GET_RUN_LOGS_PENDING,
	}
}

export function getRunLogsSuccess(runLogs: RunLog[]) {
	return {
		type: GET_RUN_LOGS_SUCCESS,
		runLogs,
	}
}

export function getRunLogsError(error: any) {
	return {
		type: GET_RUN_LOGS_ERROR,
		error,
	}
}

export const GET_RUN_LOG_SUCCESS = 'GET_RUN_LOG_SUCCESS'

interface GetRunLogSuccessAction {
	type: typeof GET_RUN_LOG_SUCCESS
	runId: string
	log: RunLog
}

export function getRunLog(runId: string, log: RunLog) {
	return { type: GET_RUN_LOG_SUCCESS, runId, log }
}

// Get Run Status Actions

export type GetRunStatusActionTypes =
	| StartPollRunStatusAction
	| StopPollRunStatusAction
	| GetRunStatusPendingAction
	| GetRunStatusSuccessAction
	| GetRunStatusErrorAction

export const START_POLL_RUN_STATUS = 'START_POLL_RUN_STATUS'
export const STOP_POLL_RUN_STATUS = 'STOP_POLL_RUN_STATUS'
export const GET_RUN_STATUS_PENDING = 'GET_RUN_STATUS_PENDING'
export const GET_RUN_STATUS_SUCCESS = 'GET_RUN_STATUS_SUCCESS'
export const GET_RUN_STATUS_ERROR = 'GET_RUN_STATUS_ERROR'

interface RunStatusAction<T extends string> {
	type: T
	runId: string
}

type StartPollRunStatusAction = RunStatusAction<typeof START_POLL_RUN_STATUS>
type StopPollRunStatusAction = RunStatusAction<typeof STOP_POLL_RUN_STATUS>
type GetRunStatusPendingAction = RunStatusAction<typeof GET_RUN_STATUS_PENDING>
type GetRunStatusSuccessAction = RunStatusAction<
	typeof GET_RUN_STATUS_SUCCESS
> & { status: RunStatus }
type GetRunStatusErrorAction = RunStatusAction<typeof GET_RUN_STATUS_ERROR> & {
	error: any
}

export function startPollRunStatus(runId: string) {
	return { type: START_POLL_RUN_STATUS, runId }
}

export function stopPollRunStatus(runId: string) {
	return { type: STOP_POLL_RUN_STATUS, runId }
}

export function getRunStatusPending(runId: string) {
	return { type: GET_RUN_STATUS_PENDING, runId }
}

export function getRunStatusSuccess(runId: string, status: RunStatus) {
	return { type: GET_RUN_STATUS_SUCCESS, runId, status }
}

export function getRunStatusError(runId: string, error: any) {
	return { type: GET_RUN_STATUS_ERROR, runId, error }
}

/***************************** REQUESTS ***************************************/

/**
 * Get all of the runs that are available to the user.
 */
export function makeGetRunsRequest() {
	return (dispatch: any) => {
		dispatch(getRunsPending())

		get('/api/v2/run/')
			.then(res => res.json())
			.then(runs => {
				if (runs.error) throw runs.error

				// normalize the runs array to be an object
				// indexed by runId, makes redux updating easier
				// and access faster
				const runsNormalized: any = {}
				runs.forEach((run: any) => {
					runsNormalized[run.id] = run
				})
				dispatch(getRunsSuccess(runsNormalized))
			})
			.catch(error => {
				dispatch(getRunsError(error))
			})
	}
}

/**
 * Get all of the run logs available to the user.
 */
export function makeGetRunLogsRequest() {
	return (dispatch: any) => {
		dispatch(getRunLogsPending())

		get('/api/v2/run/log?app=1&items=1')
			.then(res => {
				if (res.status == 500) {
					throw new Error('500')
				}
				res.json()
			})
			.then(runLogs => {
				if (runLogs.error) throw runLogs.error
				dispatch(getRunLogsSuccess(runLogs))
			})
			.catch(error => {
				dispatch(getRunLogsError(error))
			})
	}
}

export function makeGetRunLogRequest(runId: string) {
	type RunLogDTO = {
		runId: string
		runNumber: string
		entries: any[]
	}
	return (dispatch: any) => {
		get(`/api/v2/run/${runId}/log`)
			.then(res => res.json())
			.then((log: RunLogDTO) => {
				dispatch(getRunLog(runId, log))
			})
			.catch(_error => {})
	}
}

/**
 * Make a request to get the current status of a run.
 * @param runId
 */
export function makeGetRunStatusRequest(runId: string) {
	return (dispatch: any) => {
		dispatch(getRunStatusPending(runId))

		get(`/api/v2/run/${runId}/status`)
			.then(res => res.json())
			.then(status => {
				if (status.error) throw status.error

				dispatch(getRunStatusSuccess(runId, status))
			})
			.catch(error => {
				dispatch(getRunStatusError(runId, error))
			})
	}
}

/****************************** REDUCER ***************************************/
export const runsReducer = (
	state = runsInitialState,
	action: GetRunsActionTypes | GetRunLogsActionTypes | GetRunStatusActionTypes
): RunsState => {
	const getRunsRequest: Request = {
		pending: false,
		error: null,
	}
	const getRunLogsRequest: Request = {
		pending: false,
		error: null,
	}
	const getRunStatusRequests = state.getRunStatusRequests
	const getRunStatusPollings = state.getRunStatusPollings

	switch (action.type) {
		// runs
		case GET_RUNS_PENDING:
			getRunsRequest.pending = true
			getRunsRequest.error = null
			return { ...state, getRunsRequest }

		case GET_RUNS_SUCCESS:
			getRunsRequest.pending = false
			getRunsRequest.error = null
			return { ...state, getRunsRequest, runs: action.runs }

		case GET_RUNS_ERROR:
			getRunsRequest.pending = false
			getRunsRequest.error = null
			return { ...state, getRunsRequest }

		// run logs
		case GET_RUN_LOGS_PENDING:
			getRunLogsRequest.pending = true
			getRunLogsRequest.error = null
			return { ...state, getRunLogsRequest }

		case GET_RUN_LOGS_SUCCESS:
			getRunLogsRequest.pending = false
			getRunLogsRequest.error = null
			return { ...state, getRunLogsRequest, runLogs: action.runLogs }

		case GET_RUN_LOGS_ERROR:
			getRunLogsRequest.pending = false
			getRunLogsRequest.error = action.error
			return { ...state, getRunLogsRequest }

		case GET_RUN_LOG_SUCCESS:
			state.runLogs[action.runId] = action.log
			return { ...state, runLogs: { ...state.runLogs } }

		// run status
		case START_POLL_RUN_STATUS:
			getRunStatusPollings[action.runId] = true
			return { ...state, getRunStatusPollings }

		case STOP_POLL_RUN_STATUS:
			getRunStatusPollings[action.runId] = false
			return { ...state, getRunStatusPollings }

		case GET_RUN_STATUS_PENDING:
			getRunStatusRequests[action.runId] = {
				pending: true,
				error: null,
			}
			return { ...state, getRunStatusRequests }

		case GET_RUN_STATUS_SUCCESS:
			getRunStatusRequests[action.runId] = {
				pending: false,
				error: null,
			}

			if (state.runs == null || state.runs[action.runId] == null) {
				getRunStatusRequests[action.runId].error =
					"Run doesn't exist yet."
				state.runStatuses[action.runId] = action.status
				const runStatuses = state.runStatuses
				return { ...state, getRunStatusRequests, runStatuses }
			}
			state.runs[action.runId].detailedStatus = action.status
			state.runStatuses[action.runId] = action.status
			const runStatuses = state.runStatuses
			return { ...state, getRunStatusRequests, runStatuses }

		case GET_RUN_STATUS_ERROR:
			getRunStatusRequests[action.runId] = {
				pending: false,
				error: action.error,
			}
			return { ...state, getRunStatusRequests }

		default:
			return state
	}
}
