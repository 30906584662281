import { createContext } from 'react'

import type { Variant } from 'shared/types'

export interface SelectedAppContextProps {
	selectedApp: Variant | undefined
	displayName: string | undefined

	selectableVariants: Variant[]
	setSelectedApp: (variant: Variant) => void
	removeSelectedApp: () => void
}

export const SelectedAppContext = createContext({} as SelectedAppContextProps)
