import React, { useEffect, useRef, useState } from 'react'

import { VisibilityOff } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Typography } from '@mui/material'
import type { FC } from 'react'

import styles from './HideColumnFilter.module.scss'

export interface HideColumnFilterOptions {
	label: string
	value: string
}

interface Props {
	onChange: (newValues: Record<string, boolean>) => void
	value: Record<string, boolean>
	options: HideColumnFilterOptions[]
}

const HideColumnFilter: FC<Props> = ({ onChange, options, value }) => {
	const [open, setOpen] = useState(false)
	const ref = useRef(null)
	const filteredColumns = Object.values(value).filter(val => !val)

	useEffect(() => {
		const handleClickOutside = event => {
			if (ref.current && !ref.current.contains(event.target)) {
				setOpen(false)
			}
		}

		document.addEventListener('click', handleClickOutside, true)
		return () => {
			document.removeEventListener('click', handleClickOutside, true)
		}
	}, [ref])

	const resetFilters = e => {
		e.stopPropagation()
		onChange(
			options.reduce((prev, curr) => {
				prev[curr.value] = true
				return prev
			}, {})
		)
	}

	return (
		<Box ref={ref} sx={{ position: 'relative' }}>
			<IconButton
				size="small"
				className={styles['icon-button']}
				sx={{
					...(filteredColumns.length > 0 && {
						background: '#C4ECFF',
					}),
				}}
				onClick={() => setOpen(prev => !prev)}
			>
				<VisibilityOff />
				<Typography variant="body1" sx={{ px: '2px' }}>
					{filteredColumns.length > 0
						? `${filteredColumns.length} hidden columns`
						: 'Hide columns'}
				</Typography>
				{filteredColumns.length > 0 && (
					<CloseIcon
						onClick={resetFilters}
						className={styles['close-icon']}
					/>
				)}
			</IconButton>
			{open && (
				<div className={styles['dropdown-menu']}>
					{options.map(option => (
						<Box
							key={`asta-hide-column-option-${option.value}`}
							className={styles['select-item']}
							onClick={() =>
								onChange({
									...value,
									[option.value]: !value[option.value],
								})
							}
						>
							{!value[option.value] && (
								<VisibilityOff fontSize="small" />
							)}
							<Typography
								sx={{
									marginLeft: !value[option.value]
										? '5px'
										: 0,
								}}
							>
								{option.label}
							</Typography>
						</Box>
					))}
				</div>
			)}
		</Box>
	)
}

export default HideColumnFilter
