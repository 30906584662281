import { useEffect } from 'react'

/**
 * Sets the page title, optionally providing a section name.
 *
 * @example
 * ```tsx
 * import React, { FC } from 'react'
 * import { usePageTitle } from 'hooks/usePageTitle'
 *
 * const MyPage: FC = () => {
 *   usePageTitle('Some Page') // Sets the page title to "ASTA | Some Page"
 *
 *   /// ...
 * }
 * ````
 * @param sectionName
 */
export const usePageTitle = (sectionName?: string) => {
	useEffect(() => {
		document.title = sectionName ? `ASTA | ${sectionName}` : 'ASTA'
	}, [sectionName])
}
