import { useCallback, useEffect, useState } from 'react'

// ----------------------------------------------------------------------

export default function useLocalStorage<
	T extends string | number | boolean | Record<string | number, any> | any[],
>(
	key: string,
	defaultValue: T | (() => T)
): [value: T, setValue: (newValue: T | ((curr: T) => T)) => void] {
	const [value, setValue] = useState<T>(() => {
		const storedValue =
			typeof window !== 'undefined' ? localStorage.getItem(key) : null

		return storedValue === null
			? typeof defaultValue === 'function'
				? defaultValue()
				: defaultValue
			: JSON.parse(storedValue)
	})

	useEffect(() => {
		const listener = (e: StorageEvent) => {
			if (
				typeof window !== 'undefined' &&
				e.storageArea === localStorage &&
				e.key === key
			) {
				setValue(e.newValue ? JSON.parse(e.newValue) : e.newValue)
			}
		}
		window.addEventListener('storage', listener)

		return () => {
			window.removeEventListener('storage', listener)
		}
	}, [key])

	const setValueInLocalStorage = useCallback(
		(newValue: T | ((curr: T) => T)) => {
			setValue(currentValue => {
				const result =
					typeof newValue === 'function'
						? newValue(currentValue)
						: newValue
				if (typeof window !== 'undefined')
					localStorage.setItem(key, JSON.stringify(result))
				return result
			})
		},
		[key]
	)

	return [value, setValueInLocalStorage]
}
