/* eslint-disable react-perf/jsx-no-jsx-as-prop */
import { useCallback, useContext, useRef, useState } from 'react'

import {
	Box,
	FormControl,
	Grid,
	ListItemText,
	TextField,
	Typography,
} from '@mui/material'
import type {
	FormSpec,
	FormSpecData,
	FormSpecWithPopulatedParentAndTags,
} from 'api/repository/repositorySchemas'
import type { TagWithColor } from 'components/feature/TestAsset/utils'
import AstaDialog from 'components/shared/AstaDialog'
import { AuthContext } from 'contexts/auth'
import { canCreate, canEdit } from 'pages/Admin/helpers'
import { SYSTEM_ID } from 'pages/Admin/system-settings/constants'
import type { FC } from 'react'
import type { CreateAsset, Resource, Tag, Variant } from 'shared/types'

import { EditorTagAutocomplete } from '../Tags/EditorTagAutocomplete'
import FormSpecCreator from './CreateFormSpecDialog'
import FormSpecFieldsTable from './FormSpecFieldsTable'

export const defaultSpec: FormSpecData = {
	fields: [],
	formTitle: null,
	formId: null,
	ombControlNumber: null,
	formVersion: null,
	ombExpirationDate: null,
	formFamilies: null,
}

const defaultFormSpecAsset: FormSpec = {
	_id: null,
	name: 'Default Form Spec',
	type: 'form-spec',
	description: '',
	tags: [],
	data: defaultSpec,
	parent: null,
	status: 'active',
}

interface Props {
	asset: FormSpecWithPopulatedParentAndTags
	create: (newFormSpec: CreateAsset) => void
	update: (formSpecToUpdate: FormSpec, tags?: string[]) => void
	isVisible: boolean
	onClose: () => void
	selectedApp: Variant
	isEditing?: boolean
	availableTags: TagWithColor[]
}

const FormSpecEditorContainer: FC<Props> = ({
	asset,
	create,
	update,
	isVisible,
	selectedApp,
	onClose,
	isEditing = false,
	availableTags,
}) => {
	const ref = useRef()
	const { user } = useContext(AuthContext)
	const [formSpec, setFormSpec] = useState<FormSpec>(
		isEditing ? asset : defaultFormSpecAsset
	)
	const [newTags, setNewTags] = useState<string[]>(
		isEditing ? (asset?.tags as Tag[])?.map(tag => tag._id) : []
	)

	const updateTags = useCallback(
		(updatedTags: string[]) => {
			if (isEditing) {
				update(asset, updatedTags)
				return
			}

			setNewTags(updatedTags)
		},
		[asset, isEditing, update]
	)

	const onFormSpecCreate = useCallback(
		(spec: FormSpec, globalAsset = false) => {
			const newFormSpec: CreateAsset = {
				...spec,
				tags: newTags,
				type: 'form-spec',
				parent: globalAsset ? SYSTEM_ID : selectedApp.parent._id,
				status: 'active',
			}

			create(newFormSpec)
			onClose()
		},
		[create, selectedApp, onClose, newTags]
	)

	const onFormSpecUpdate = useCallback(
		(spec: FormSpec) => {
			update(spec)
			onClose()
		},
		[update, onClose]
	)

	const onChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			setFormSpec(prev => ({
				...prev,
				[e.target.name]: e.target.value,
			}))
		},
		[setFormSpec]
	)

	const onDataChange = useCallback(
		(data: FormSpecData) => {
			setFormSpec(prev => ({
				...prev,
				name: `${data.formTitle} [V${data.formVersion}]`,
				description: `Generated for "${data.formTitle} [V${data.formVersion}]"`,
				data,
			}))
		},
		[setFormSpec]
	)

	if (!isVisible || !formSpec) return null

	return (
		<AstaDialog
			ref={ref}
			title={`${isEditing ? `Edit ${formSpec.name}` : 'Create Form Spec'}`}
			onClose={onClose}
			buttonText=""
			show={isVisible}
			setShow={onClose}
			buttonEnabled={true}
			contentSx={{ padding: 4, pb: 0 }}
			content={
				<Grid
					container
					direction="column"
					alignItems="center"
					justifyContent="center"
					spacing={3}
				>
					<Grid xs={12} pt={4}>
						{availableTags?.length > 0 && (
							<EditorTagAutocomplete
								tags={
									isEditing
										? (formSpec.tags as Tag[]) ?? []
										: []
								}
								availableTags={availableTags}
								setTags={updateTags}
								disabled={
									(isEditing
										? !canEdit(
												(formSpec.parent as Resource)
													?.role
											)
										: !canCreate(selectedApp?.role)) &&
									!user?.isAdmin
								}
							/>
						)}
						<Box height="80vh" width="90vw">
							<>
								<FormControl fullWidth>
									<ListItemText
										sx={{ minWidth: '200px' }}
										primary={
											<Typography
												variant="subtitle1"
												whiteSpace="nowrap"
												overflow="hidden"
											>
												Name
											</Typography>
										}
										secondary="Name to identify your asset"
									/>
									<TextField
										id="name"
										name="name"
										placeholder="Name..."
										fullWidth
										defaultValue={formSpec.name}
										value={formSpec.name}
										onChange={onChange}
									/>
								</FormControl>
								<FormControl fullWidth>
									<ListItemText
										sx={{ minWidth: '200px' }}
										primary={
											<Typography
												variant="subtitle1"
												whiteSpace="nowrap"
												overflow="hidden"
											>
												Description
											</Typography>
										}
										secondary="Briefly describe your asset"
									/>
									<TextField
										id="description"
										name="description"
										multiline
										rows={2}
										fullWidth
										value={formSpec.description}
										onChange={onChange}
										placeholder="Description..."
									/>
								</FormControl>
							</>
							{isEditing ? (
								<FormSpecFieldsTable
									spec={formSpec.data as FormSpecData}
								/>
							) : (
								<FormSpecCreator
									spec={formSpec.data as FormSpecData}
									setSpec={onDataChange}
								/>
							)}
						</Box>
					</Grid>
				</Grid>
			}
			// eslint-disable-next-line react-perf/jsx-no-new-array-as-prop
			actions={[
				{
					text: isEditing ? 'Save' : 'Create',
					color: 'primary',
					onClick: () =>
						isEditing
							? onFormSpecUpdate(formSpec)
							: onFormSpecCreate(formSpec),
					disabled:
						(isEditing
							? !canEdit((asset?.parent as Resource)?.role)
							: !canCreate(selectedApp?.role)) && !user?.isAdmin,
				},
				...(!isEditing && user?.isAdmin
					? [
							{
								text: 'Save as global asset',
								color: 'success',
								onClick: () => onFormSpecCreate(formSpec, true),
							},
						]
					: []),
			]}
		/>
	)
}

export default FormSpecEditorContainer
