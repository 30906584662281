import { useQueryClient } from '@tanstack/react-query'
import {
	useApplicationControllerCreateApplication,
	useApplicationControllerGetApplications,
	useApplicationControllerGetApplicationWithParent,
	useApplicationControllerUpdate,
} from 'api/repository/repositoryComponents'
import { DEFAULT_STALE_TIME } from 'deprecated/services/constants'
import { toast } from 'react-hot-toast'
import type { Application } from 'shared/types'

interface Props {
	workspaceId?: string
}

export const useApplications = ({ workspaceId }: Props) => {
	const queryClient = useQueryClient()

	const {
		data: initialData,
		isLoading: isLoadingApps,
		error: errorLoadingApps,
	} = useApplicationControllerGetApplications(
		{},
		{
			staleTime: DEFAULT_STALE_TIME,
			enabled: !workspaceId,
		}
	)

	const {
		data: workspaceApps,
		isLoading: isLoadingWorkspaceApps,
		error: errorLoadingWorkspaceApps,
	} = useApplicationControllerGetApplicationWithParent(
		{
			pathParams: {
				id: workspaceId,
			},
		},
		{
			staleTime: DEFAULT_STALE_TIME,
			enabled: !!workspaceId,
		}
	)

	const applications: Array<Application> = ((workspaceId
		? workspaceApps?.data
		: initialData?.data) ?? []) as Array<Application>

	const createApplication = useApplicationControllerCreateApplication({
		onSuccess: () => {
			queryClient.invalidateQueries()
			toast.success('Application created')
		},
		onError: error => {
			toast.error(error.stack.message)
		},
	})

	const editApplication = useApplicationControllerUpdate({
		onSuccess: () => {
			queryClient.invalidateQueries()
			toast.success('Application edited')
		},
		onError: error => {
			toast.error(error.stack.message)
		},
	})

	return {
		applications,
		isLoading: isLoadingApps || isLoadingWorkspaceApps,
		createApplication,
		editApplication,
		error: errorLoadingApps ?? errorLoadingWorkspaceApps,
	}
}
