import React, { useMemo } from 'react'

import { ListItemText, Typography } from '@mui/material'
import { createColumnHelper } from '@tanstack/react-table'
import type { FieldSpec, FormSpecData } from 'api/repository/repositorySchemas'
import { AstaTableV2 } from 'components/shared/AstaTable/AstaTable'
import ScrollX from 'deprecated/components/ScrollX'

type Props = {
	spec: FormSpecData | null
}

const columnHelper = createColumnHelper<FieldSpec>()

const FormSpecEditor: React.FC<Props> = ({ spec }) => {
	const defaultColumns = useMemo(
		() => [
			columnHelper.accessor(row => row.fieldNumber, {
				id: 'fieldNumber',
				header: 'Field Number',
				cell: props => props.renderValue(),
			}),
			columnHelper.accessor(row => row.fieldLabel, {
				id: 'fieldLabel',
				header: 'Field Label',
				cell: props => (
					<Typography maxWidth="400px">
						{props.renderValue()}
					</Typography>
				),
			}),
			columnHelper.accessor(row => row.fieldId, {
				id: 'fieldId',
				header: 'Field ID',
				cell: props => props.renderValue(),
			}),
			columnHelper.accessor(row => row.required, {
				id: 'required',
				header: 'Required',
				cell: props => props.renderValue(),
			}),
			columnHelper.accessor(row => row.minimumOccurrences, {
				id: 'minimumOccurences',
				header: 'Minimum Occurrences',
				cell: props => props.renderValue(),
			}),
			columnHelper.accessor(row => row.maximumOccurrences, {
				id: 'maximumOccurences',
				header: 'Maximum Occurrences',
				cell: props => props.renderValue(),
			}),
			columnHelper.accessor(row => row.agencyFieldName, {
				id: 'agencyFieldName',
				header: 'Agency Field Name',
				cell: props => props.renderValue(),
			}),
			columnHelper.accessor(row => row.fieldType, {
				id: 'fieldType',
				header: 'Field Type',
				cell: props => props.renderValue(),
			}),
			columnHelper.accessor(row => row.globalLibraryFieldName, {
				id: 'globalLibraryFieldName',
				header: 'Global Library Field Name',
				cell: props => props.renderValue(),
			}),
			columnHelper.accessor(row => row.fieldTypeSource, {
				id: 'fieldTypeSource',
				header: 'Field Type Source',
				cell: props => props.renderValue(),
			}),
			columnHelper.accessor(row => row.businessRules, {
				id: 'businessRules',
				header: 'Business Rules',
				cell: props => props.renderValue(),
			}),
			columnHelper.accessor(row => row.dataType, {
				id: 'dataType',
				header: 'Data Type',
				cell: props => props.renderValue(),
			}),
			columnHelper.accessor(row => row.listOfValues, {
				id: 'listOfValues',
				header: 'List of Values',
				cell: props => props.renderValue(),
			}),
			columnHelper.accessor(row => row.minCharsOrMinValue, {
				id: 'minCharsOrMinValue',
				header: 'Minimum Chars/Value',
				cell: props => props.renderValue(),
			}),
			columnHelper.accessor(row => row.maxCharsOrMaxValue, {
				id: 'maxCharsOrMaxValue',
				header: 'Maximum Chars/Value',
				cell: props => props.renderValue(),
			}),
			columnHelper.accessor(row => row.fieldImplementation, {
				id: 'fieldImplementation',
				header: 'Field Implementation',
				cell: props => props.renderValue(),
			}),
			columnHelper.accessor(row => row.helpTip, {
				id: 'helpTip',
				header: 'Help Tip',
				cell: props => props.renderValue(),
			}),
			columnHelper.accessor(row => row.mandatoryMessage, {
				id: 'mandatoryMessage',
				header: 'Mandatory Message',
				cell: props => props.renderValue(),
			}),
			columnHelper.accessor(row => row.validationMessage, {
				id: 'validationMessage',
				header: 'Validation Message',
				cell: props => props.renderValue(),
			}),
		],
		[]
	)

	const data = useMemo(
		() =>
			spec
				? [
						{
							fieldNumber: 'Form Title',
							fieldLabel: spec.formTitle,
						},
						{ fieldNumber: 'Form Id', fieldLabel: spec.formId },
						{
							fieldNumber: 'Form Version',
							fieldLabel: spec.formVersion,
						},
						{
							fieldNumber: 'OMB Control Number',
							fieldLabel: spec.ombControlNumber,
						},
						{
							fieldNumber: 'OMB Expiration Date',
							fieldLabel: spec.ombExpirationDate,
						},
						{
							fieldNumber: 'Form Families',
							fieldLabel: spec.formFamilies?.join(', '),
						},
						...spec.fields,
					]
				: [],
		[spec]
	)

	return (
		<ScrollX>
			<ListItemText
				sx={{ minWidth: '200px' }}
				primary={
					<Typography
						variant="subtitle1"
						sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}
					>
						Fields
					</Typography>
				}
				secondary="Requirements for each field"
			/>
			<AstaTableV2
				disableToolbar
				columns={defaultColumns}
				data={data}
				actions={[]}
				// onRowClick={handleRowClick}
				// onRowDoubleClick={handleRowDoubleClick}
				// onRowContextMenu={handleRowContextMenu}
			/>
		</ScrollX>
	)
}

export default FormSpecEditor
