/**
 * This file defines the global mui theme for the application. All components
 * should inherit this theme. This allows us to quickly change the theme of the
 * application. Although we don't really care about this feature for the func.
 * yet, it's needed to work with the material-table library, because they poorly
 * supported MUI v5.
 */

import { createTheme } from '@mui/material'
import { green } from '@mui/material/colors'

export const COLOR_PALLETE = {
	unverified: '#24435F',
	passed: '#14BA9C',
	failed: '#F03658',

	success: '#14BA9C',
	failure: '#F03658',
	untested: '#E5E8ED',
} as const

const theme = createTheme({
	palette: {
		primary: green,
		secondary: green,
	},
	typography: {
		fontFamily: 'Inter, Roboto',
	},
	direction: 'ltr',
	components: {
		MuiContainer: {
			styleOverrides: {
				root: {
					maxWidth: 'none !important',
				},
			},
		},
		MuiButtonBase: {
			defaultProps: {
				// disableRipple: true,
				color: 'black',
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					':hover': {
						backgroundColor: 'black',
					},
				},
			},
		},
	},
})

theme.typography.h4.fontFamily = theme.typography.fontFamily
	.split('')
	.concat('Inter')
	.reverse()
	.join(',')

export default theme
