import React from 'react'

import { Add, UploadOutlined } from '@mui/icons-material'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import { Button } from '@mui/material'

const actionButtonSx = { m: '8px', marginLeft: 'auto', p: '5px 16px' }
const iconButtonSx = { paddingRight: '8px', stroke: '3' }

export type IconType = 'create' | 'download' | 'upload'

const typeToIcon: Record<IconType, JSX.Element> = {
	create: <Add sx={iconButtonSx} />,
	download: <DownloadOutlinedIcon sx={iconButtonSx} />,
	upload: <UploadOutlined sx={iconButtonSx} />,
}

export interface AssetIconButtonProps {
	onClick: () => void
	variant: 'contained' | 'outlined' | 'text'
	text: string
	key: string
	type: IconType
}

const AssetIconButton: React.FC<AssetIconButtonProps> = ({
	onClick,
	text,
	key,
	variant,
	type,
}) => {
	return (
		<Button
			key={key}
			variant={variant}
			sx={actionButtonSx}
			onClick={onClick}
			size="small"
		>
			{typeToIcon[type]}
			{text}
		</Button>
	)
}

export default AssetIconButton
