import React, { useCallback, useEffect, useMemo, useReducer } from 'react'

import Loader from 'components/shared/loader'
import { useWorkspaces } from 'pages/Admin/_workspaces/hooks'
import type { FC, PropsWithChildren } from 'react'
import { useLocation } from 'react-router-dom'
import type { Workspace } from 'shared/types'

import type { WorkspaceContextProps } from './'
import { WorkspaceContext, workspaceReducer } from './'

export interface WorkspaceState {
	workspace: Workspace | undefined
}

const WORKSPACE_INITIAL_STATE: WorkspaceState = {
	workspace: undefined,
}

export const WorkspaceProvider: FC<PropsWithChildren<object>> = ({
	children,
}) => {
	const [state, dispatch] = useReducer(
		workspaceReducer,
		WORKSPACE_INITIAL_STATE
	)

	const { workspaces, isLoading } = useWorkspaces()
	const location = useLocation()
	const urlAppId = location.pathname.split('/')[2]

	//Checks if workspace is still in the workspace list or if it was removed
	useEffect(() => {
		if (workspaces?.length > 0 && state.workspace) {
			const workspaceInList = workspaces.find(
				ws => ws._id === state.workspace._id
			)
			if (!workspaceInList) setWorkspace(workspaces[0]._id)
		} else if (state.workspace) {
			dispatch({
				type: '[Workspace] - Set selected workspace',
				payload: undefined,
			})
		}
	}, [workspaces])

	const setWorkspace = useCallback(
		(workspaceId: string) => {
			if (typeof workspaceId !== 'string') {
				throw new Error(
					`Unsupported type, workspaceId must be a string, ${workspaceId}`
				)
			}
			const workspace = workspaces.find(ws => ws._id === workspaceId)
			if (!workspace) return
			dispatch({
				type: '[Workspace] - Set selected workspace',
				payload: workspace,
			})
			if (workspace) {
				localStorage.setItem('workspace', workspace._id)
			}
		},
		[workspaces]
	)

	/**
	 * Selects the first workspace in the array if a workspace has not been selected unless there's a selectedAppId
	 * in the url, in that case, the SelectedAppProvider will be in charge of setting the workspace
	 */
	if (!isLoading && workspaces?.length > 0 && !state.workspace) {
		const lastSelectedWorkspaceId = localStorage.getItem('workspace')
		if (!urlAppId)
			setWorkspace(lastSelectedWorkspaceId ?? workspaces[0]._id)
	}

	const contextValues: WorkspaceContextProps = useMemo(
		() => ({ ...state, setWorkspace, workspaces }),
		[state, setWorkspace, workspaces]
	)

	if (isLoading) return <Loader />

	return (
		<WorkspaceContext.Provider value={contextValues}>
			{children}
		</WorkspaceContext.Provider>
	)
}
