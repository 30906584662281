import { createContext } from 'react'

import type { Breadcrumb } from './types'

interface ContextProps {
	breadcrumbs: Breadcrumb[]

	setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void
}

export const BreadcrumbsContext = createContext({} as ContextProps)
if (process.env.NODE_ENV !== 'production') {
	BreadcrumbsContext.displayName = 'BreadcrumbsContext'
}
