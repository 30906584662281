import React, { useMemo, useState } from 'react'

import { DATParser } from '@asta/core'
import type { FormSpecData } from 'api/repository/repositorySchemas'
import ScrollX from 'deprecated/components/ScrollX'
import { Button, FileUploader, Pane, Text } from 'evergreen-ui'

import FormSpecFieldsTable from './FormSpecFieldsTable'

interface UploadProps {
	spec: FormSpecData
	setSpec: (spec: FormSpecData) => void
}

/**
 * This component renders a file uploader ffrom evergreen-ui, when a user
 * uploads their xlxs file, then the parser is used to parse the file and
 * the parsed document is sent to the server to be saved.
 */
const FormSpecFileUploader: React.FC<UploadProps> = ({ spec, setSpec }) => {
	const parser = useMemo(() => new DATParser(), [])
	const [_error, setError] = useState<string | null>(null)
	const [selectedFile, setSelectedFile] = useState(null)
	const [showTable, setShowTable] = useState(false)

	const parseSpec = file => {
		const reader = new FileReader()
		reader.readAsArrayBuffer(file)
		reader.onload = () => {
			try {
				const parsedDocument = parser.fromXlsxFile(reader.result)
				setError(null)
				setSpec({
					formTitle: parsedDocument.formTitle,
					formId: parsedDocument.formId,
					formVersion: parsedDocument.formVersion,
					formFamilies: parsedDocument.formFamilies,
					ombControlNumber: parsedDocument.ombControlNumber,
					ombExpirationDate: parsedDocument.ombExpirationDate,
					fields: parsedDocument.fields,
				})
				setShowTable(true)
			} catch {
				setError('Error parsing file.')
				alert('Error parsing file')
			}
		}
	}

	const handleFileSelect = file => {
		setSelectedFile(file)
		parseSpec(file)
	}

	const handleUpload = () => {
		console.log(`Uploading file: ${selectedFile.name}`)
		parseSpec(selectedFile)
		setSelectedFile(null)
	}

	return (
		<Pane>
			{!showTable && (
				<>
					<FileUploader
						width={200}
						height={120}
						marginBottom={8}
						onDrop={handleFileSelect}
						onChange={e => {
							handleFileSelect(e[0])
						}}
					/>
					{selectedFile && (
						<Pane
							display="flex"
							alignItems="center"
							marginBottom={8}
						>
							<Text marginRight={8}>{selectedFile.name}</Text>
							<Button
								variant="contained"
								color="primary"
								onClick={handleUpload}
							>
								Upload
							</Button>
						</Pane>
					)}
				</>
			)}
			{showTable && (
				<ScrollX>
					<FormSpecFieldsTable spec={spec} />
				</ScrollX>
			)}
		</Pane>
	)
}

export default FormSpecFileUploader
