import type { IMetadata } from '../types/interfaces/user'
import axios from './axios-base'

export interface FetchUsersFilter {
	text?: string
	limit?: number
	offset?: number
	workspaces?: boolean
}

export interface User {
	_id: string
	email: string
	status: 'active' | 'inactive'
}

interface UserResponse {
	data: User[]
	metadata: IMetadata
}

const fetchAll = async (filters?: FetchUsersFilter) => {
	const data = filters
		? await axios.get<any, UserResponse>('/api/v2/users/', {
				params: { ...filters },
			})
		: await axios.get<any, UserResponse>('/api/v2/users/')

	return data
}

const deactivateUser = async (id: string) => {
	const { data } = await axios.post(`/api/v2/users/deactivateUser/${id}`)

	return data
}

const activateUser = async (id: string) => {
	const { data } = await axios.post(`/api/v2/users/activateUser/${id}`)

	return data
}

const UserService = {
	fetchAll,
	deactivateUser,
	activateUser,
}

export default UserService
