import type { RunStatusDTO } from '@asta/repository-dtos'

import type { RunTimerState } from './RunTimerProvider'

type RunTimerActionType =
	| {
			type: '[RunTimer] - Update run status'
			payload: RunStatusDTO['runningState']
	  }
	| {
			type: '[RunTimer] - Set initial time'
			payload: number
	  }

export const runTimerReducer = (
	state: RunTimerState,
	action: RunTimerActionType
): RunTimerState => {
	switch (action.type) {
		case '[RunTimer] - Update run status':
			return {
				...state,
				runState: action.payload,
			}

		case '[RunTimer] - Set initial time':
			return {
				...state,
				initialTime: action.payload,
			}

		default:
			return state
	}
}
