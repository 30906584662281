/**
 * Manages the RunDTOs for runs in progress.
 */

import type { RunMetadataDto } from '@asta/repository-dtos'

import type { BaseResourceState } from '../../base/base'
import {
	buildResourceReducer,
	fetchAll,
	fetchOne,
	initialBaseResourceState,
	RESOURCE,
} from '../../base/base'

type RunsState = BaseResourceState<RunMetadataDto>

export const initialState: RunsState = {
	...initialBaseResourceState,
}

export const runsV2Selector = (
	state: any,
	query: {
		appId: string
	}
): RunMetadataDto[] => {
	const runs = state.runsV2.resources as RunMetadataDto[]
	return runs.filter(r => r.applicationId === query.appId)
}

export const runV2Selector = (
	state: any,
	runId: string
): RunMetadataDto | null => state.runsV2.resources.find(r => r.runId === runId)

export const runsV2LastFetchedSelector = (state: any): number => {
	const runsV2 = state.runsV2 as RunsState
	return Date.now() - runsV2.getAll.lastFetched
}

export const fetchRunsV2PendingSelector = (state: any): boolean =>
	state.runsV2.getAll.isPending

export const fetchRunV2 = (runId: string) =>
	fetchOne(RESOURCE.RUNS, runId, `/api/v2/run/${runId}`)

export const fetchRunsV2 = (appId: string) =>
	fetchAll(RESOURCE.RUNS, `/api/v2/run?appId=${appId}`)

export const runsV2Reducer = buildResourceReducer(initialState, RESOURCE.RUNS)
