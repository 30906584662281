import { styled } from '@mui/material'

export const TableCell = styled('td')`
	min-height: 30px;
	padding: 6px;
	color: ${({ theme }) => theme.palette.text.primary};
	border-left: 1px solid hsl(202, 10%, 88%);
	border-left-color: #f3f3f3;
	border-bottom: 1px solid hsl(202, 10%, 88%);
	border-bottom-color: #f3f3f3;
	font-size: 13px;
	line-height: 1.5;
	overflow: hidden;
	white-space: nowrap;
	transition: width transform 0.2s ease-in-out;
	display: flex;
	align-items: center;
`

export const DraggableTableCell = styled(TableCell)<{
	isDragging?: boolean
	transform?: string
}>`
	opacity: ${({ isDragging }) => (isDragging ? 0.8 : 1)};
	z-index: ${({ isDragging }) => (isDragging ? 1 : 0)};
`
