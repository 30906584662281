import React, { useCallback, useContext, useMemo } from 'react'

import { RuleType } from '@asta/core'
import { Box } from '@mui/material'
import { createColumnHelper } from '@tanstack/react-table'
import AstaCheckbox from 'components/shared/AstaCheckbox'
import { AstaTableV2 } from 'components/shared/AstaTable/AstaTable'
import type { CustomFilterShape } from 'components/shared/CustomFilter'
import type { HideColumnFilterOptions } from 'components/shared/HideColumnFilter'
import { useQueryState } from 'components/shared/QueryState/useQueryState'
import type {
	OperatorAndValue,
	SelectFilterDefinition,
} from 'components/shared/TableFilters'
import TableFilters from 'components/shared/TableFilters'
import ViewsManager from 'components/shared/ViewsManager'
import { useFormatColumnVisibilityState } from 'components/shared/ViewsManager/helpers'
import useViews from 'components/shared/ViewsManager/useViews'
import { AuthContext } from 'contexts/auth'
import { useSelectedApp } from 'contexts/selectedApp'
import ScrollX from 'deprecated/components/ScrollX'
import { DEFAULT_STALE_TIME } from 'deprecated/services/constants'
import { capitalize } from 'lodash'
import { canEdit, canRemove } from 'pages/Admin/helpers'
import { ruleTypeToLabel } from 'pages/shared/helpers'
import qs from 'qs'
import { Link as RRDLink } from 'react-router-dom'
import { TEST_CENTER_ROUTES } from 'routes/routes'
import type { Asset, AssetWithParent, Tag } from 'shared/types'

import type {
	AssetTableEntryDTO,
	ResourceDto,
	RuleData,
} from '../../../api/repository/repositorySchemas'
import { AssetActions } from './ActionsCell'
import { useAssetTableData } from './hooks/useAssetTableData'
import { NameCell } from './NameCell'
import { TagsCell } from './TagsCell/TagsCell'
import { TestAssetsTableResults } from './TestAssetsResults'
import type { TagWithColor } from './utils'
import { getAvailableTagsObjWithColor } from './utils'

type AssetType = Asset['type']

export interface TestAssetsTableProps {
	type: AssetType
	update: (asset: Asset, tags: Asset['tags']) => void
	rowOnClick: (asset: AssetWithParent) => void
	actions: React.ReactNode[]
	deleteAsset: (asset: AssetWithParent | Array<AssetWithParent>) => void
	tags: TagWithColor[]
	selectedAssets: Partial<Asset>[]
}

export const tagColors = [
	'#F8B9C5',
	'#FFBC78',
	'#FEE685',
	'#C5EE93',
	'#29E1CB',
	'#A1D3FF',
	'#CFC4FD',
	'#F4B2FF',
	'#C9C9C9',
]

interface TestAssetsTableFilterShape extends CustomFilterShape {
	type?: {
		[key in '$eq' | '$ne']: string
	}
	tag?: {
		$eq: string
	}
	status?: {
		$eq: string
	}
}

interface TestAssetsTableGroupByShape extends OperatorAndValue {
	$eq: string
}

const columnHelper = createColumnHelper<AssetTableEntryDTO>()

const TestAssetsTable: React.FC<TestAssetsTableProps> = ({
	type,
	update,
	rowOnClick,
	actions,
	deleteAsset,
	tags,
	selectedAssets,
}) => {
	const { user } = useContext(AuthContext)
	const { selectedApp } = useSelectedApp()
	const { _id: selectedAppId } = selectedApp ?? { _id: '' }

	const [search, setSearch] = useQueryState<{
		$regex: string
	}>('search')

	const {
		URLFilters,
		filters,
		setFilters,
		setViewFilters,
		URLGroupBy,
		groupBy,
		setGroupBy,
		setViewGroupBy,
		URLSort,
		sort,
		setSort,
		setViewSort,
		URLColumnVisibility,
		columnVisibility,
		setColumnVisibility,
		setViewColumnVisibility,
		URLColumnOrder,
		columnOrder,
		setColumnOrder,
		setViewColumnOrder,
		resetUrlParams,
	} = useViews<TestAssetsTableFilterShape, TestAssetsTableGroupByShape>()

	const { status, ...otherFilters } = filters

	const { data: assetData = [], isLoading: isLoadingAssetData } =
		useAssetTableData(type)(
			{
				pathParams: {
					appId: selectedAppId,
				},
				queryParams: qs.stringify({
					filters: {
						...otherFilters,
						...(status && { status: status.$eq }),
						...(search && { search }),
					},
					groupBy: {
						groupBy,
					},
					...(sort && { sort }),
				}),
			},
			{
				staleTime: DEFAULT_STALE_TIME,
				refetchOnWindowFocus: false,
			}
		)

	const [availableTagsObj] = useMemo(
		() => [getAvailableTagsObjWithColor(tags)],
		[tags]
	)

	const updateTags = useCallback(
		(asset: Asset) => (tags: string[]) => {
			update(
				{
					...asset,
					tags: tags,
				},
				tags
			)
		},
		[update]
	)

	const onStatusChange = useCallback(
		(asset: AssetWithParent | Array<AssetWithParent>) => {
			const getStatus = (status: Asset['status']) => {
				return status === 'active' ? 'inactive' : 'active'
			}

			const formatTags = (tags: Asset['tags']) => {
				return (tags as Tag[]).map(tag => tag._id)
			}

			if (Array.isArray(asset)) {
				asset.forEach(asset => {
					update(
						{
							...asset,
							status: getStatus(asset.status),
						},
						formatTags(asset.tags)
					)
				})
				return
			}

			update(
				{
					...asset,
					status: getStatus(asset.status),
				},
				formatTags(asset.tags)
			)
		},
		[update]
	)

	const onMultipleAssetDelete = useCallback(
		(assets: Array<AssetWithParent>) => {
			if (type === 'flow') {
				deleteAsset(assets)
				return
			}

			assets.forEach(asset => {
				deleteAsset(asset)
			})
		},
		[deleteAsset, type]
	)

	const columns = useMemo(
		() => [
			columnHelper.accessor(row => row.asset._id, {
				id: 'select',
				minSize: 80,
				enableSorting: false,
				header: ({ table }) => (
					<AstaCheckbox
						{...{
							checked: table.getIsAllRowsSelected(),
							indeterminate: table.getIsSomeRowsSelected(),
							onChange: table.getToggleAllRowsSelectedHandler(),
						}}
					/>
				),
				cell: ({ row }) => (
					<AstaCheckbox
						{...{
							checked: row.getIsSelected(),
							disabled: !row.getCanSelect(),
							indeterminate: row.getIsSomeSelected(),
							onChange: row.getToggleSelectedHandler(),
						}}
					/>
				),
			}),
			columnHelper.accessor(row => row.asset.name, {
				id: 'name',
				header: 'Name',
				minSize: 320,
				/**
				 * If the cell is a 'grouping row', which isn't really a grouping row
				 * but is kind of hacked in, because table doesn't support grouping
				 * very well IMO.
				 */
				cell: ({ row }) => (
					<NameCell row={row} rowOnClick={rowOnClick} />
				),
			}),
			columnHelper.accessor(row => row.asset, {
				id: 'actions',
				header: 'Actions',
				minSize: 85,
				enableSorting: false,
				cell: ({ row }) => (
					<AssetActions
						row={row}
						user={user}
						type={type}
						rowOnClick={rowOnClick}
						onStatusChange={onStatusChange}
						deleteAsset={deleteAsset}
					/>
				),
			}),
			columnHelper.accessor(row => row.asset.status, {
				id: 'status',
				header: 'Status',
				minSize: 65,
				cell: ({ row }) => {
					const { isGroup, asset } = row.original

					if (isGroup) return null

					return asset.status
				},
			}),
			...(type === ('rule' as AssetType)
				? [
						columnHelper.accessor(row => row.asset, {
							id: 'rule_type',
							header: 'Type',
							minSize: 125,
							cell: ({ row }) => {
								const { isGroup, asset } = row.original

								if (isGroup) return null

								return capitalize(
									(asset.data as RuleData).type
								).replace('-', ' ')
							},
						}),
						columnHelper.accessor(row => row.analytics, {
							id: 'lastResults',
							header: 'Last Results',
							minSize: 100,
							enableSorting: false,
							cell: ({ row }) => {
								const { isGroup, analytics } = row.original

								if (isGroup || !analytics) return null

								return (
									<Box
										width="100%"
										display="flex"
										alignItems="center"
										justifyContent="center"
									>
										<TestAssetsTableResults
											result={
												analytics.failed > 0
													? 'Failed'
													: 'Passed'
											}
										/>
									</Box>
								)
							},
						}),
						columnHelper.accessor(
							row => row.analytics.lastTestedTimestamp,
							{
								id: 'lastTested',
								header: 'Last tested',
								minSize: 160,
								enableSorting: false,
								cell: ({ row }) => {
									const { isGroup, analytics } = row.original

									if (isGroup || !analytics) return null

									return new Date(
										row.original.analytics.lastTestedTimestamp
									).toLocaleString()
								},
							}
						),
						columnHelper.accessor(
							row => row.analytics.lastTestedRunId,
							{
								id: 'lastRun',
								header: 'Last tested run',
								minSize: 140,
								enableSorting: false,
								cell: ({ row }) => {
									const { isGroup, analytics } = row.original

									if (isGroup || !analytics) return null

									return (
										<div
											style={{
												width: '100%',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											<RRDLink
												to={`/app/${selectedApp?._id}/${TEST_CENTER_ROUTES.log.path(
													row.original.analytics
														.lastTestedRunId
												)}&filters[ruleId][$eq]=${
													row.original.asset._id
												}&redirected=true`}
											>
												{
													row.original.analytics
														.lastTestedRunNumber
												}
											</RRDLink>
										</div>
									)
								},
							}
						),
					]
				: []),

			columnHelper.accessor(() => 'location', {
				id: 'location',
				header: 'Location',
				minSize: 80,
				cell: ({ row }) =>
					!row.original.isGroup && row.original.asset.parent.name,
			}),
			columnHelper.accessor(row => row.asset.tags, {
				id: 'tags',
				header: 'Tags',
				minSize: 240,
				enableSorting: false,
				cell: ({ row }) => {
					const { isGroup, asset } = row.original

					if (isGroup) return null

					return (
						<TagsCell
							availableTags={availableTagsObj}
							currentTags={asset.tags as Tag[]}
							updateTags={updateTags(asset)}
							disabled={
								!canEdit(asset.parent.role) && !user.isAdmin
							}
						/>
					)
				},
			}),
			columnHelper.accessor(() => 'lastModified', {
				id: 'lastModified',
				header: 'Last Modified ',
				minSize: 170,
				cell: ({ row }) => {
					const { isGroup } = row.original

					if (isGroup) return null

					return new Date(
						row.original.asset.updatedAt
					).toLocaleString()
				},
			}),
			columnHelper.accessor(() => 'lastModifiedBy', {
				id: 'lastModifiedBy',
				header: 'Last Modified By',
				minSize: 170,
				cell: ({ row }) => {
					const { isGroup } = row.original

					if (isGroup) return null

					return row.original.asset.updatedBy
				},
			}),
		],
		[availableTagsObj, assetData]
	)

	const columnFilterOptions = useMemo(
		() =>
			columns.map(col => ({
				label:
					typeof col.header === 'string'
						? col.header
						: capitalize(col.id),
				value: col.id,
			})) as HideColumnFilterOptions[],
		[columns]
	)

	/**
	 * We check permissions against selected assets, if all of the selected assets don't match the required permissions, we don't show the option
	 */
	const contextMenuOptions = useMemo(
		() =>
			[
				(user.isAdmin ||
					selectedAssets.every(asset =>
						canRemove((asset.parent as ResourceDto).role)
					)) && {
					label: `Delete`,
					onClick: rows =>
						onMultipleAssetDelete(
							rows
								.filter(row => !row.isGroup)
								.map(({ asset }) => asset)
						),
				},
				(user.isAdmin ||
					selectedAssets.every(asset =>
						canEdit((asset.parent as ResourceDto).role)
					)) && {
					label: `Activate / Deactivate`,
					onClick: rows =>
						onStatusChange(
							rows
								.filter(row => !row.isGroup)
								.map(({ asset }) => asset)
						),
				},
			].filter(Boolean),
		[selectedAssets, user, onStatusChange, onMultipleAssetDelete, type]
	)

	const groupByOptions = useMemo(
		() => [
			{ label: 'Tag', value: 'tag' },
			...(type === ('rule' as AssetType)
				? [{ label: 'Type', value: 'type' }]
				: []),
		],
		[type]
	)

	const tagFilterOptions = useMemo(
		() =>
			tags.map(tag => ({
				label: tag.name,
				value: tag._id,
			})),
		[tags]
	)

	const columnVisibilityState = useFormatColumnVisibilityState(
		columns,
		columnVisibility
	)

	return (
		<>
			<TableFilters
				filters={[
					{
						type: 'search',
						key: 'search',
						onChange: newValues => {
							setSearch(newValues)
						},
						value: search as OperatorAndValue,
					},
					{
						type: 'custom',
						key: 'filters',
						onChange: setFilters,
						value: filters,
						options: [
							...(type === ('rule' as AssetType)
								? [
										{
											column: {
												label: 'Type',
												value: 'type',
											},
											values: Object.values(RuleType).map(
												ruleType => ({
													label: ruleTypeToLabel[
														ruleType
													],
													value: ruleType,
												})
											),
											type: 'select',
										} as SelectFilterDefinition,
									]
								: []),
							...(tags?.length > 0
								? [
										{
											column: {
												label: 'Tag',
												value: 'tag',
											},
											values: tagFilterOptions,
											type: 'select' as const,
										},
									]
								: []),
							{
								column: { label: 'Status', value: 'status' },
								values: [
									{ label: 'active', value: 'active' },
									{ label: 'inactive', value: 'inactive' },
									{ value: 'invalid', label: 'invalid' },
								],
								type: 'select',
							},
						],
					},
					{
						type: 'group',
						key: 'groupBy',
						options: groupByOptions,
						value: groupBy,
						onChange: setGroupBy,
					},
					{
						type: 'hide-columns',
						key: 'hide columns',
						onChange: setColumnVisibility,
						value: columnVisibilityState,
						options: columnFilterOptions,
					},
				]}
				actions={actions}
			>
				<ViewsManager
					filters={URLFilters as CustomFilterShape}
					setViewFilters={setViewFilters}
					setViewColumnVisibility={setViewColumnVisibility}
					columnVisibility={URLColumnVisibility}
					groupBy={URLGroupBy}
					setViewGroupBy={setViewGroupBy}
					sort={URLSort}
					setViewSort={setViewSort}
					columnOrder={URLColumnOrder}
					setViewColumnOrder={setViewColumnOrder}
					resetUrlParams={resetUrlParams}
				/>
			</TableFilters>
			<ScrollX>
				<AstaTableV2
					key={type}
					columns={columns}
					data={assetData}
					actions={actions}
					disableToolbar
					columnVisibility={columnVisibilityState}
					setColumnVisibility={setColumnVisibility}
					columnOrder={columnOrder}
					setColumnOrder={setColumnOrder}
					isLoading={isLoadingAssetData}
					sort={sort}
					setSort={setSort}
					contextMenuOptions={contextMenuOptions}
				/>
			</ScrollX>
		</>
	)
}

export default TestAssetsTable
