import React, {
	type ComponentType,
	type FC,
	useDebugValue,
	useEffect,
} from 'react'

import { usePageTitle } from 'hooks'
import { useNavigate } from 'react-router-dom'
import { type Breadcrumb, TEST_CENTER_ROUTES } from 'routes/routes'

import type { SelectedAppContextProps } from './SelectedAppContext'
import { useSelectedApp } from './useSelectedApp'

/**
 * @see {@link WithSelectedApp}
 */
export type SelectedAppProps = {
	[K in keyof SelectedAppContextProps]-?: SelectedAppContextProps[K] extends
		| undefined
		| infer R
		? R
		: SelectedAppContextProps[K]
}

export type WithSelectedAppOptions = {
	/**
	 * Optionally set the title of the page. Set to `false` to disable this
	 * if you want to handle the title yourself.
	 */
	pageTitle?: string | ((app: SelectedAppContextProps) => string) | false
	/**
	 * Optionally navigate to a page when no app is selected. Defaults to the
	 * landing page. Set to `false` to disable this behavior.
	 */
	navigateOnMissing?: (() => Breadcrumb) | false
}

/**
 * A higher-order component that provides the currently selected
 * variant to the wrapped component, along with methods for managing it.
 */
export const WithSelectedApp = (options: WithSelectedAppOptions) => {
	const {
		pageTitle,
		navigateOnMissing = TEST_CENTER_ROUTES.landing.display,
	} = options
	const useMaybePageTitle = createUsePageTitleHook(pageTitle)

	return <P,>(
		Component: ComponentType<P & SelectedAppProps>
	): ComponentType<P> => {
		const ComponentWithSelectedApp: FC<
			Omit<P, keyof SelectedAppProps>
		> = props => {
			const selected = useSelectedApp()
			const navigate = useNavigate()
			useDebugValue(selected?.selectedApp, app =>
				app
					? `variant: ${app.name} (${app._id})`
					: 'no selected variant'
			)

			useMaybePageTitle(selected)
			const shouldNavigate =
				!selected?.selectedApp?._id && navigateOnMissing
			useEffect(() => {
				if (shouldNavigate) {
					navigate(navigateOnMissing().url)
				}
			}, [navigate, shouldNavigate])

			return shouldNavigate ? null : (
				<Component {...(props as P)} {...selected} />
			)
		}

		// Use a pretty display name in dev
		if (process.env.NODE_ENV !== 'production') {
			ComponentWithSelectedApp.displayName = `WithSelectedApp(${
				Component.displayName ?? Component.name ?? 'Anonymous'
			})`
		}

		return ComponentWithSelectedApp as ComponentType<P>
	}
}

function createUsePageTitleHook(
	pageTitle: WithSelectedAppOptions['pageTitle']
) {
	if (pageTitle === false) {
		return () => {}
	} else {
		return (selected: SelectedAppContextProps) => {
			usePageTitle(
				typeof pageTitle === 'function'
					? pageTitle(selected)
					: pageTitle ?? selected.displayName
			)
		}
	}
}
