import type {
	CreateWorkspaceDTO,
	UpdateWorkspaceDTO,
} from '@asta/repository-dtos'

import axios from './axios-base'

const create = async (workspace: CreateWorkspaceDTO) => {
	const { data } = await axios.post('/api/v2/workspace/', workspace)
	return data
}

const fetchAll = async () => {
	const { data } = await axios.get('/api/v2/workspace/')
	return data
}

const fetch = async (id: string) => {
	const { data } = await axios.get('/api/v2/workspace/' + id)
	return data
}

const update = async (id: string, workspace: UpdateWorkspaceDTO) => {
	const { data } = await axios.put('/api/v2/workspace/' + id, workspace)
	return data
}
const WorkspaceService = {
	create,
	fetch,
	fetchAll,
	update,
}

export default WorkspaceService
