import type { ModelNode } from '@asta/core'

import { put } from '../base'
import { updateModel } from './model'

/***************************** SELECTORS **************************************/

export type UpdateComponentState = {}

export const updateComponentInitialState = {}

/****************************** ACTIONS ***************************************/

export type UpdateModelComponentActionTypes =
	| UpdateComponentPending
	| UpdateComponentError
	| UpdateComponentSuccess

interface UpdateComponentPending {
	type: typeof UPDATE_COMPONENT_PENDING
}
interface UpdateComponentError {
	type: typeof UPDATE_COMPONENT_ERROR
	errorMessage: string
}
interface UpdateComponentSuccess {
	type: typeof UPDATE_COMPONENT_SUCCESS
	appId: string
	update: ModelNode
}

export const UPDATE_COMPONENT_PENDING = 'UPDATE_COMPONENT_PENDING'
export const UPDATE_COMPONENT_ERROR = 'UPDATE_COMPONENT_PENDING'
export const UPDATE_COMPONENT_SUCCESS = 'UPDATE_COMPONENT_SUCCESS'

/***************************** REQUESTS ***************************************/
export function updateComponent(
	appId: string,
	componentId: string,
	component: ModelNode
) {
	return async (dispatch: any) => {
		dispatch({ type: UPDATE_COMPONENT_PENDING })
		put(`/api/v2/variants/${appId}/model/components/${componentId}`, {
			...component,
		})
			.then(res => res.json())
			.then(updateBody => {
				// Check if there was an error with updating

				// Dispatch update to be handled by model reducer
				const updatedComponent = updateBody.component
				dispatch(
					updateModel([
						{
							type: 'update',
							componentId: componentId,
							relationshipId: null,
							component: updatedComponent,
						},
					])
				)
				dispatch({
					type: UPDATE_COMPONENT_SUCCESS,
					appId,
					updates: updateBody,
				})
			})
			.catch(error =>
				dispatch({
					type: UPDATE_COMPONENT_ERROR,
					errorMessage: error.message,
				})
			)
	}
}

/****************************** REDUCER ***************************************/
export function updateComponentReducer(
	state = updateComponentInitialState,
	action: UpdateModelComponentActionTypes
): UpdateComponentState {
	switch (action.type) {
		case UPDATE_COMPONENT_PENDING:
			return state

		case UPDATE_COMPONENT_ERROR:
			return state

		case UPDATE_COMPONENT_SUCCESS:
			return state

		default:
			return state
	}
}
