import type { BreadcrumbsState } from './BreadcrumbsProvider'
import type { Breadcrumb } from './types'

type BreadcrumbsActionType = {
	type: '[Breadcrumbs] - Set breadcrumbs'
	payload: Breadcrumb[]
}

export const breadcrumbsReducer = (
	state: BreadcrumbsState,
	action: BreadcrumbsActionType
): BreadcrumbsState => {
	switch (action.type) {
		case '[Breadcrumbs] - Set breadcrumbs':
			return {
				...state,
				breadcrumbs: action.payload,
			}

		default:
			return state
	}
}
