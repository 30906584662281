import { useEffect, useState } from 'react'

import qs from 'qs'
import { useNavigate } from 'react-router-dom'

export type Operators = '$in' | '$eq' | '$regex' | '$ne'
export type OperatorAndValue<T> = {
	[key in Operators]: T
}

// This should follow the LHS bracket convention
// An object would look like:
// foo: {
//    bar: 'baz'
// }

type SetValue<T> = React.Dispatch<React.SetStateAction<T>>

export const useQueryState = <
	T extends
		| object
		| string
		| boolean
		| number
		| OperatorAndValue<Operators> =
		| object
		| string
		| boolean
		| number
		| OperatorAndValue<Operators>,
>(
	key: string,
	initialValue?: T
): [T, SetValue<T>] => {
	const navigate = useNavigate()

	const getInitialValue = (): T => {
		const params = qs.parse(window.location.search, {
			ignoreQueryPrefix: true,
		})

		if (!params[key]) return initialValue
		if (typeof params[key] !== 'string') return params[key] as T
		return (
			params[key] === 'true' || params[key] === 'false'
				? JSON.parse(params[key] as string)
				: params[key]
		) as T
	}

	const [state, setState] = useState<T>(getInitialValue())

	useEffect(() => {
		const params = qs.parse(window.location.search, {
			ignoreQueryPrefix: true,
		})
		const newParams = { ...params, [key]: state }
		const newQueryString = qs.stringify(newParams)

		const newURL = `${window.location.pathname}?${newQueryString}`
		navigate(newURL, { replace: true })
	}, [key, state])

	return [state, setState]
}
