import { Buffer } from 'buffer'

import type { Request } from './base'
import { get } from './base'

export type FilesState = {
	/**
	 * Records are indexed by the fileId and the stored value is the base64
	 * encoded string for the image
	 */
	files: Record<string, string>

	/**
	 * Keeps track of the status of all the outgoing requests for files
	 */
	requests: Record<string, Request>
}

export const filesInitialState = {
	files: {} as Record<string, string>,
	requests: {} as Record<string, Request>,
}

export const filesSelector = (state: any) => state.downloadedFiles.files

// ACTIONS
export type FileActions = GetFilePending | GetFileSuccess | GetFileError
export const GET_FILE_PENDING = 'GET_FILE_PENDING'
export const GET_FILE_ERROR = 'GET_FILE_ERROR'
export const GET_FILE_SUCCESS = 'GET_FILE_SUCCESS'
interface GetFilePending {
	type: typeof GET_FILE_PENDING
	fileId: string
}
interface GetFileSuccess {
	type: typeof GET_FILE_SUCCESS
	fileId: string
	fileBase64: string
}
interface GetFileError {
	type: typeof GET_FILE_ERROR
	fileId: string
	error: any
}
export function getFilePending(fileId: string) {
	return { type: GET_FILE_PENDING, fileId }
}
export function getFileError(fileId: string, error: any) {
	return { type: GET_FILE_ERROR, fileId, error }
}
export function getFileSuccess(fileId: string, fileBase64: string) {
	return { type: GET_FILE_SUCCESS, fileId, fileBase64 }
}

// REDUCER
export const filesReducer = (
	state = filesInitialState,
	action: FileActions
): FilesState => {
	const files = state.files
	const requests = state.requests
	switch (action.type) {
		case GET_FILE_SUCCESS:
			files[action.fileId] = action.fileBase64
			requests[action.fileId] = { pending: false, error: null }
			return { ...state, files, requests }
		case GET_FILE_PENDING:
			requests[action.fileId] = { pending: true, error: null }
			return { ...state, requests }
		case GET_FILE_ERROR:
			requests[action.fileId] = { error: action.error, pending: false }
			return { ...state, requests }
		default:
			return state
	}
}

// REQUESTS
export function getFile(fileId: string, file: any) {
	return (dispatch: any) => {
		if (file != undefined) return
		dispatch(getFilePending(fileId))
		get(`/api/v2/files/${fileId}`)
			.then(async res => {
				if (res.status === 404) throw new Error('File does not exist')
				const imageBuffer = await res.arrayBuffer()
				const imageStr = Buffer.from(imageBuffer).toString('base64')
				dispatch(getFileSuccess(fileId, imageStr))
			})
			.catch(() => {
				dispatch(getFileError(fileId, 'Unexpected error'))
			})
	}
}
