import React, { useEffect, useReducer } from 'react'

import { config } from 'config'
import type { IUser } from 'deprecated/types/interfaces/user'
import type { FC, PropsWithChildren } from 'react'

import { AuthContext } from './AuthContext'
import { authReducer } from './AuthReducer'
import { useWhoAmI } from './useWhoAmI'

export interface AuthState {
	isLoggedIn: boolean
	user?: IUser
}

const AUTH_INITIAL_STATE: AuthState = {
	isLoggedIn: false,
	user: undefined,
}

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
	const [state, dispatch] = useReducer(authReducer, AUTH_INITIAL_STATE)
	const { whoAmIData, logout: authLogout } = useWhoAmI()

	useEffect(() => {
		if (!state.isLoggedIn && whoAmIData) {
			dispatch({ type: '[Auth] - Login', payload: whoAmIData as IUser })
		}
	}, [whoAmIData])

	const sendLogout = async () => {
		authLogout.mutate(null, {
			onSuccess: () => {
				dispatch({ type: '[Auth] - Logout' })
				const win: Window = window
				win.location.assign(`${config.services.oss.url}/logout`)
			},
		})
	}

	const logIn = () => {}

	const logout = () => {
		sendLogout()
	}

	return (
		<AuthContext.Provider
			value={{
				...state,

				// Methods
				logIn,
				logout,
			}}
		>
			{children}
		</AuthContext.Provider>
	)
}
