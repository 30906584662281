import { useCallback, useMemo, useState } from 'react'

import { useApplications } from 'pages/Admin/_applications/hooks'
import { useVariants } from 'pages/Admin/_variants/hooks'
import { useVariantsWorkspace } from 'pages/Admin/_variants/hooks/useVariantsWorkspace'
import { useWorkspaces } from 'pages/Admin/_workspaces/hooks'
import { canCreate } from 'pages/Admin/helpers'
import type {
	Application,
	AssetWithParent,
	TagWithParent,
	Variant,
	Workspace,
} from 'shared/types'

export interface UseCopyAndMoveProps {
	selectedVariant: Variant
	resource: AssetWithParent | TagWithParent
	isAdmin: boolean
}

export const useCopyAndMove = ({
	selectedVariant,
	resource,
	isAdmin,
}: UseCopyAndMoveProps) => {
	const resourceParentsType = resource.parent.type
	const { workspace } = useVariantsWorkspace(selectedVariant._id)

	const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace>(
		resourceParentsType !== 'instance' && resourceParentsType
			? workspace
			: null
	)
	const [selectedApplication, setSelectedApp] = useState<Application>(
		(resourceParentsType === 'application' ||
		resourceParentsType === 'variant'
			? selectedVariant.parent
			: null) as unknown as Application
	)

	const [selectedVar, setSelectedVariant] = useState<Variant>(
		resourceParentsType === 'variant' ? selectedVariant : null
	)

	const { workspaces, isLoading: isLoadingWorkspaces } = useWorkspaces()
	const { applications, isLoading: isLoadingApps } = useApplications(
		selectedWorkspace ? { workspaceId: selectedWorkspace._id } : {}
	)
	const { variants, isLoading: isLoadingVariants } = useVariants({
		applicationId: (selectedApplication
			? selectedApplication._id
			: resource?.parent.parent) as string,
	})

	const onResourceClick = useCallback(
		(item: Workspace | Application | Variant) => {
			switch (item.type) {
				case 'workspace': {
					setSelectedWorkspace(item as Workspace)
					return
				}
				case 'application': {
					setSelectedApp(item as Application)
					return
				}
				case 'variant': {
					setSelectedVariant(item as Variant)
					return
				}
			}
		},
		[]
	)

	const onBreadcrumbClick = useCallback(
		(item: Workspace | Application | Variant | { type: 'instance' }) => {
			if (!isAdmin && 'role' in item && !canCreate(item.role)) return

			switch (item.type) {
				case 'instance': {
					setSelectedWorkspace(null)
					setSelectedApp(null)
					setSelectedVariant(null)
					return
				}
				case 'workspace': {
					setSelectedApp(null)
					setSelectedVariant(null)
					return
				}
				case 'application': {
					setSelectedVariant(null)
					return
				}
				default:
					return
			}
		},
		[isAdmin]
	)

	const selectableResources = useMemo(() => {
		if (selectedApplication && !isLoadingVariants) return variants
		if (selectedWorkspace && !isLoadingApps) return applications
		return workspaces
	}, [
		selectedApplication,
		selectedWorkspace,
		workspaces,
		applications,
		variants,
		isLoadingVariants,
		isLoadingApps,
	])

	const breadcrumbResources = useMemo(
		() =>
			[selectedWorkspace, selectedApplication, selectedVar].filter(
				Boolean
			),
		[selectedWorkspace, selectedApplication, selectedVar]
	)

	return {
		selectedWorkspace,
		selectedApplication,
		selectedVar,
		onResourceClick,
		onBreadcrumbClick,
		selectableResources,
		breadcrumbResources,
		isLoadingWorkspaces,
	}
}
