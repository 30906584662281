import React, { useCallback, useState } from 'react'

import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import type { AutocompleteRenderInputParams } from '@mui/material'
import { Autocomplete, Box, ListItem, Stack, TextField } from '@mui/material'
import { createStyles, makeStyles, styled } from '@mui/styles'
import { Badge } from 'evergreen-ui'

const StyledAutocomplete = styled(Autocomplete)(() => ({
	height: '20px',
	minWidth: '120px',
}))

const StyledListItem = styled(ListItem)(() => ({ py: '0 !important' }))

const PointerBox = styled(Box)(() => ({
	cursor: 'pointer',
}))

const StyledTextField = styled(TextField)(() => ({
	height: '30px',
	'div:first-of-type': {
		py: '0 !important',
	},
}))

export type AutocompletePill = {
	_id: string
	label: string
	value: string
	color?: string
}

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			display: 'flex',
			flexWrap: 'wrap',
			width: '100%',
			height: '42.9px',
			margin: '-6px',
			['& .MuiAutocomplete-root']: {
				height: '100% !important',
				width: '100% !important',
				'& .MuiFormControl-root': {
					height: '100%',
					width: '100%',
					borderRadius: 0,
				},
			},
			['& .MuiInputBase-root']: {
				width: '100%',
				height: '100%',
				borderRadius: 0,
			},
			'& .MuiOutlinedInput-notchedOutline': {
				border: 'none',
			},
		},
		inputRoot: {
			flexWrap: 'wrap',
			overflow: 'hidden',
			width: '200px',
			height: '100%',
		},
		chip: {
			margin: 0.5,
		},
	})
)

type Props = {
	initialValue: AutocompletePill[]
	options: AutocompletePill[]
	onChange: (value: AutocompletePill[]) => void
	label: string
	placeholder: string
	disabled?: boolean
}

const AstaAutocomplete: React.FC<Props> = ({
	initialValue,
	options,
	onChange,
	label,
	placeholder,
	disabled = false,
}) => {
	const classes = useStyles()

	const [selectedOptions, setSelectedOptions] =
		useState<AutocompletePill[]>(initialValue)

	const handleDelete = useCallback(
		(pill: AutocompletePill) => {
			const newTags = selectedOptions.filter(
				item => item.value !== pill.value
			)
			setSelectedOptions(newTags)
			onChange(newTags)
		},
		[selectedOptions, onChange]
	)

	const handleChange = useCallback(
		(event: React.ChangeEvent<{}>, value: AutocompletePill[]) => {
			setSelectedOptions(value)
			onChange(value)
		},
		[onChange]
	)

	const getInitialTags = useCallback(
		(initialTags: AutocompletePill[]) => {
			return initialTags.map(option => {
				return (
					<Box key={option._id}>
						{option.color === 'neutral' && (
							<Badge color="neutral">
								<Stack
									display="flex"
									direction="row"
									spacing={1.5}
								>
									{option.label}{' '}
									<Box onClick={() => handleDelete(option)}>
										x
									</Box>
								</Stack>
							</Badge>
						)}
						{option.color !== 'neutral' && (
							<Badge backgroundColor={option.color} marginX={2}>
								<Stack
									display="flex"
									direction="row"
									spacing={1.5}
									alignItems="center"
									justifyContent="center"
								>
									{option.label}{' '}
									<PointerBox
										onClick={() => handleDelete(option)}
									>
										{!disabled && (
											<CancelRoundedIcon
												sx={{
													fontSize: 14,
													pt: '2px',
													pl: '3px',
												}}
											/>
										)}
									</PointerBox>
								</Stack>
							</Badge>
						)}
					</Box>
				)
			})
		},
		[disabled, handleDelete]
	)

	const getOptionLabel = useCallback((option: AutocompletePill) => {
		return option.label
	}, [])

	const onAutoCompleteClick = useCallback(
		(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			e.preventDefault()
		},
		[]
	)

	const isOptionEqualToValue = useCallback(
		(option: AutocompletePill, value: AutocompletePill) => {
			return option.value === value.value
		},
		[]
	)

	const renderOption = useCallback(
		(
			props: React.HTMLAttributes<HTMLLIElement>,
			option: AutocompletePill
		) => {
			return (
				<StyledListItem {...props}>
					{option.color === 'neutral' && (
						<Badge color="neutral">{option.label}</Badge>
					)}
					{option.color !== 'neutral' && (
						<Badge backgroundColor={option.color}>
							{option.label}
						</Badge>
					)}
				</StyledListItem>
			)
		},
		[]
	)

	const renderInput = useCallback(
		(params: AutocompleteRenderInputParams): JSX.Element | null => {
			return (
				<StyledTextField
					{...params}
					variant="outlined"
					label={label}
					onClick={e => e.stopPropagation()}
					placeholder={placeholder}
					InputProps={{
						...params.InputProps,
						classes: { root: classes.inputRoot },
					}}
				/>
			)
		},
		[classes.inputRoot, label, placeholder]
	)

	return (
		<div className={classes.root}>
			<StyledAutocomplete
				multiple
				id="autocomplete-pill-list"
				options={options}
				value={selectedOptions}
				getOptionLabel={getOptionLabel}
				onClick={onAutoCompleteClick}
				isOptionEqualToValue={isOptionEqualToValue}
				//To select
				renderOption={renderOption}
				//Initial tags
				renderTags={getInitialTags}
				renderInput={renderInput}
				onChange={handleChange}
				disabled={disabled}
			/>
		</div>
	)
}

export default React.memo(AstaAutocomplete)
