import type { CreateVariantDTO, UpdateVariantDto } from '@asta/repository-dtos'

import axios from './axios-base'

const create = async (variant: CreateVariantDTO) => {
	const { data } = await axios.post('/api/v2/variants/', variant)
	return data
}
const fetchAll = async () => {
	const { data } = await axios.get('/api/v2/variants/')
	return data
}

const fetch = (id: string) => axios.get('/api/v2/variants/' + id)

const update = async (id: string, variant: UpdateVariantDto) => {
	const { data } = await axios.put('/api/v2/variants/' + id, variant)
	return data
}

const fetchVariantsWithApplicationId = async (workspaceId: string) => {
	const { data } = await axios.get('/api/v2/variants/parent/' + workspaceId)
	return data
}

const fetchVariantsWorkspace = async (variantId: string) => {
	const { data } = await axios.get(`/api/v2/variants/${variantId}/workspace`)
	return data
}

const fetchVariantsApplication = async (variantId: string) => {
	const { data } = await axios.get(
		`/api/v2/variants/${variantId}/application`
	)
	return data
}

const VariantService = {
	create,
	fetch,
	fetchAll,
	update,
	fetchVariantsWithApplicationId,
	fetchVariantsWorkspace,
	fetchVariantsApplication,
}

export default VariantService
