import React from 'react'

import { Box, Dialog, useMediaQuery, useTheme } from '@mui/material'
import { CrossIcon } from 'evergreen-ui'

interface Props {
	onClose: () => void
	isOpen: boolean
	children: React.ReactNode
}

const AdminModal: React.FC<Props> = ({ isOpen, onClose, children }) => {
	const theme = useTheme()
	const matchDownMD = useMediaQuery(theme.breakpoints.down('md'))

	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-start',
					width: matchDownMD ? '80vw' : 500,
					height: 'auto',
				}}
			>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						justifyContent: 'flex-end',
						cursor: 'pointer',
						fontSize: '1rem',
						padding: 2,
					}}
				>
					<CrossIcon onClick={onClose} scale="2x" />
				</Box>
				<Box sx={{ padding: 1 }}>{children}</Box>
			</Box>
		</Dialog>
	)
}

export default AdminModal
