import { useQueryClient } from '@tanstack/react-query'
import {
	useModelControllerRemoveModel,
	useVariantControllerCreateVariant,
	useVariantControllerGetVariantsWithParent,
	useVariantControllerUpdateVariant,
} from 'api/repository/repositoryComponents'
import { DEFAULT_STALE_TIME } from 'deprecated/services/constants'
import { toast } from 'react-hot-toast'
import type { Variant } from 'shared/types'

interface Props {
	applicationId?: string
}

export const useVariants = ({ applicationId }: Props) => {
	const queryClient = useQueryClient()

	const {
		data: initialVariants = { data: [] },
		isLoading,
		error,
	} = useVariantControllerGetVariantsWithParent(
		{
			pathParams: {
				id: applicationId,
			},
		},
		{
			staleTime: DEFAULT_STALE_TIME,
			enabled: !!applicationId,
		}
	)

	const variants: Array<Variant> = initialVariants.data as Array<Variant>

	const createVariant = useVariantControllerCreateVariant({
		onSuccess: () => {
			queryClient.invalidateQueries()
			toast.success('Variant created')
		},
		onError: error => {
			toast.error(error.stack.message)
		},
	})

	const editVariant = useVariantControllerUpdateVariant({
		onSuccess: () => {
			queryClient.invalidateQueries()
			toast.success('Variant edited')
		},
		onError: (error: { message: string }) => {
			toast.error(error.message)
		},
	})

	const removeVariantsModel = useModelControllerRemoveModel({
		onSuccess: () => {
			queryClient.invalidateQueries()
			toast.success(`Variant's model deleted`)
		},
		onError: (error: { message: string }) => {
			toast.error(error.message)
		},
	})

	return {
		variants,
		isLoading,
		createVariant,
		editVariant,
		error,
		removeVariantsModel,
	}
}
