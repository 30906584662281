import { post } from './base'

/******************************* STATE ****************************************/
export type AuthState = {
	authenticated: boolean
	identity: string | null
	loginRequest: {
		pending: boolean
		error: any
	}
}

export type Request = {
	pending: boolean
	error: any
}

export const authInitialState: AuthState = {
	authenticated: localStorage.getItem('jwt') ? true : false,
	identity: null,
	loginRequest: {
		pending: false,
		error: null,
	},
}
/***************************** SELECTORS **************************************/
export const authenticatedSelector = (state: any) => state.auth.authenticated
export const identitySelector = (state: any) => state.auth.identity
export const loginRequestSelector = (state: any) => state.auth.loginRequest
/****************************** ACTIONS ***************************************/

export type AuthActionTypes =
	| LoginRequestPendingAction
	| LoginRequestSuccessAction
	| LoginRequestErrorAction
	| LogoutAction

interface LoginRequestPendingAction {
	type: typeof LOGIN_REQUEST_PENDING
}
interface LoginRequestErrorAction {
	type: typeof LOGIN_REQUEST_ERROR
	error: any
}
interface LoginRequestSuccessAction {
	type: typeof LOGIN_REQUEST_SUCCESS
	identity: string
}
interface LogoutAction {
	type: typeof LOGOUT
}

export const LOGIN_REQUEST_PENDING = 'LOGIN_REQUEST_PENDING'
export const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS'
export const LOGIN_REQUEST_ERROR = 'LOGIN_REQUEST_ERROR'
export const LOGOUT = 'LOGOUT'

export function loginRequestPendingAction() {
	return {
		type: LOGIN_REQUEST_PENDING,
	}
}
export function loginRequestSuccessAction(identity: string) {
	return {
		type: LOGIN_REQUEST_SUCCESS,
		identity,
	}
}
export function loginRequestErrorAction(error: any) {
	return {
		type: LOGIN_REQUEST_ERROR,
		error,
	}
}
export function logoutAction() {
	return {
		type: LOGOUT,
	}
}
/***************************** REQUESTS ***************************************/
type LoginRequest = {
	key: string
}

type LoginResponse = {
	success: boolean
	message: string
	identity: string
	error: any
}

export function makeLoginRequest(key: string) {
	return async (dispatch: any) => {
		dispatch(loginRequestPendingAction())

		const body: LoginRequest = { key }

		post('/api/v2/auth/login', body)
			.then(res => res.json())
			.then((loginResponse: LoginResponse) => {
				if (loginResponse.error)
					dispatch(loginRequestErrorAction('Internal error.'))
				else if (!loginResponse.success)
					dispatch(loginRequestErrorAction(loginResponse.message))
				else if (loginResponse.success) {
					localStorage.setItem('jwt', key)
					dispatch(loginRequestSuccessAction(loginResponse.identity))
				}
			})
	}
}

export function makeLogoutRequest() {
	return async (dispatch: any) => {
		localStorage.removeItem('jwt')
		dispatch(logoutAction())
	}
}

export function logout() {
	return async (dispatch: any) => {
		localStorage.removeItem('jwt')
		dispatch(logoutAction())
	}
}

/****************************** REDUCER ***************************************/
export function authReducer(
	state = authInitialState,
	action: AuthActionTypes
): AuthState {
	let loginRequest = { pending: true, error: null }

	switch (action.type) {
		case LOGIN_REQUEST_PENDING:
			loginRequest = { pending: true, error: null }
			return { ...state, loginRequest }

		case LOGIN_REQUEST_ERROR:
			loginRequest = { pending: false, error: action.error }
			return { ...state, loginRequest }

		case LOGIN_REQUEST_SUCCESS:
			loginRequest = { pending: false, error: null }
			return {
				...state,
				loginRequest,
				authenticated: true,
				identity: action.identity,
			}

		case LOGOUT:
			return { ...state, authenticated: false, identity: null }

		default:
			return state
	}
}
