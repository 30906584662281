import { useMutation, useQuery } from '@tanstack/react-query'
import AuthService from 'deprecated/services/AuthService'
import { DEFAULT_STALE_TIME } from 'deprecated/services/constants'

export const useWhoAmI = () => {
	const {
		data: whoAmIData = null,
		isLoading,
		error,
	} = useQuery(['whoami'], AuthService.whoami, {
		staleTime: DEFAULT_STALE_TIME * 24, //Update very 24h
	})

	const logout = useMutation({
		mutationFn: AuthService.logout,
	})

	return {
		whoAmIData,
		isLoading,
		logout,
		error,
	}
}
