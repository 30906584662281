import { createContext } from 'react'

import type { IUser } from '../../deprecated/types/interfaces'

interface ContextProps {
	isLoggedIn: boolean
	user?: IUser

	logIn: () => void
	logout: () => void
}

export const AuthContext = createContext({} as ContextProps)
