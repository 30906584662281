import type { WorkQueueItem } from '@asta/core'

import type { StrategyDTO } from './strategies/strategies'

// STATE
export type WorkQueueState = {
	items: WorkQueueItemDTO[]
}
export type WorkQueue = {
	items: WorkQueueItemDTO[]
}
export type WorkQueueItemDTO = {
	options: {
		sync: boolean
		test: boolean
	}
	type: WorkQueueItem['type']
	pageId: string | null
	componentId: string | null
	activityId: string | null
	label: string
	strategies: StrategyDTO[]
}
export const workQueueInitialState: WorkQueueState = {
	items: [],
}

// SELECTORS
export const workQueueSelector = (state: any) => state.workQueue.items

// ACTIONS types
export type WorkQueueActionTypes =
	| AddItemToWorkQueue
	| RemoveItemFromWorkQueue
	| ClearWorkQueue
	| UpdateWorkQueueItem

export const ADD_ITEM_TO_WORK_QUEUE = 'ADD_ITEM_TO_WORK_QUEUE'

interface AddItemToWorkQueue {
	type: typeof ADD_ITEM_TO_WORK_QUEUE
	item: WorkQueueItemDTO
}

export function addItemToWorkQueue(item: WorkQueueItemDTO) {
	return { type: ADD_ITEM_TO_WORK_QUEUE, item }
}

const REMOVE_ITEM_FROM_WORK_QUEUE = 'REMOVE_ITEM_FROM_WORK_QUEUE'

interface RemoveItemFromWorkQueue {
	type: typeof REMOVE_ITEM_FROM_WORK_QUEUE
	index: number
}

export function removeItemFromWorkQueue(index: number) {
	return { type: REMOVE_ITEM_FROM_WORK_QUEUE, index }
}

const UPDATE_WORK_QUEUE_ITEM = 'UPDATE_WORK_QUEUE_ITEM'

interface UpdateWorkQueueItem {
	type: typeof UPDATE_WORK_QUEUE_ITEM
	index: number
	item: WorkQueueItemDTO
}

export function updateWorkQueueItem(index: number, item: WorkQueueItemDTO) {
	return { type: UPDATE_WORK_QUEUE_ITEM, index, item }
}

const CLEAR_WORK_QUEUE = 'CLEAR_WORK_QUEUE'

interface ClearWorkQueue {
	type: typeof CLEAR_WORK_QUEUE
}

export function clearWorkQueue() {
	return { type: CLEAR_WORK_QUEUE }
}

// REDUCER
export const workQueueReducer = (
	state = workQueueInitialState,
	action: WorkQueueActionTypes
): WorkQueueState => {
	switch (action.type) {
		case ADD_ITEM_TO_WORK_QUEUE:
			return {
				...state,
				items: state.items.concat(action.item),
			}

		case REMOVE_ITEM_FROM_WORK_QUEUE:
			return {
				...state,
				items: state.items.filter((_, i) => {
					return i !== action.index
				}),
			}

		case UPDATE_WORK_QUEUE_ITEM:
			return {
				...state,
				items: state.items.map((item, i) => {
					return i === action.index ? action.item : item
				}),
			}

		case CLEAR_WORK_QUEUE:
			return {
				...state,
				items: [],
			}

		default:
			return state
	}
}

// REQUESTS
export function addComponentToWorkQueue(item: WorkQueueItemDTO) {
	return (dispatch: any) => {
		dispatch(addItemToWorkQueue(item))
	}
}
