/**
 * Fetches and stores the applications from the repository.
 */
import type {
	BaseResourceState,
	RequestState,
	ResourceAction,
} from '../base/base'
import {
	buildResourceReducer,
	fetchAll,
	initialBaseResourceState,
	RESOURCE,
} from '../base/base'

// State
type ApplicationsState = BaseResourceState<ApplicationDTO> & {
	chosenApplicationId: string | null
	selectedApplication: ApplicationDTO | null
	getAll: RequestState
}

export const applicationsInitialState: ApplicationsState = {
	...initialBaseResourceState,
	chosenApplicationId: null,
	selectedApplication: null,
	getAll: {
		isPending: false,
		errorMessage: null,
		lastFetched: null,
	},
}

// DTOs
export type ApplicationDTO = {
	_id: string
	name: string
	model: ModelDTO | undefined
	defaultUrl: string
}

type ModelDTO = {
	applicationId: string
	nodes: ModelNodeDTO[]
	edges: ModelEdgeDTO[]
}

type ModelNodeDTO = {
	id: string
	applicationId: string
	name: string
	type: string
	data: Record<string, string>
}

type ModelEdgeDTO = {
	id: string
	applicationId: string
	type: string
	to: string
	from: string
	data: Record<string, string>
}

// Selectors
export const applicationsSelector = (state: any): ApplicationDTO[] =>
	state.applications.resources

export const lastFetchedApplicationsSelector = (state: any): number =>
	state.applications.getAll.lastFetched

// Requests
export const fetchApplications = () => fetchAll(RESOURCE.APPLICATIONS)

// Actions
export type ApplicationActions = ChooseApplication
const SWITCH_APPLICATION = 'SWITCH_APPLICATION'
interface ChooseApplication {
	type: typeof SWITCH_APPLICATION
	applicationId: string
}
export function switchApplication(applicationId: string): ChooseApplication {
	return { type: SWITCH_APPLICATION, applicationId }
}

// Reducer
const baseReducer = buildResourceReducer(
	applicationsInitialState,
	RESOURCE.APPLICATIONS
)

export function applicationsReducer(
	state = applicationsInitialState,
	action: ResourceAction | ApplicationActions
): ApplicationsState {
	state = baseReducer(state, action as ResourceAction)
	switch (action.type) {
		case SWITCH_APPLICATION:
			return {
				...state,
				chosenApplicationId: action.applicationId,
				selectedApplication: state.resources.find(
					a => a._id === action.applicationId
				),
			}
		default:
			return state
	}
}
