import React, { useCallback, useMemo } from 'react'

import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import type { CSSProperties, FC } from 'react'
import type { Tag } from 'shared/types'

export interface TagsCellProps {
	currentTags: Tag[]
	availableTags: Record<string, Tag>
	updateTags: (tags: string[]) => void
	disabled?: boolean
}

const containerStyles: CSSProperties = {
	display: 'flex',
	flexWrap: 'wrap',
}

const chipStyles = (backgroundColor: string): CSSProperties => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: backgroundColor,
	margin: '0 4px',
	flexWrap: 'wrap',
	borderRadius: '8px',
	padding: '0 8px',
})

const iconStyles = {
	fontSize: 18,
	cursor: 'pointer',
	paddingLeft: '4px',
}

export const TagsCell: FC<TagsCellProps> = ({
	currentTags,
	availableTags,
	updateTags,
	disabled = true,
}) => {
	const tagsWithColor = useMemo(
		() =>
			currentTags.map(tag => ({
				...tag,
				bgColor: availableTags[tag._id]?.color,
			})),
		[currentTags, availableTags]
	)

	const handleDelete = useCallback(
		(tag: Tag) => {
			updateTags(
				currentTags.filter(t => t._id !== tag._id).map(tag => tag._id)
			)
		},
		[currentTags, updateTags]
	)

	if (currentTags.length === 0) return null

	return (
		<div role="group" aria-label="Tags Cell" style={containerStyles}>
			{tagsWithColor.map(tag => {
				return (
					<div
						role="listitem"
						aria-label={`${tag.name} chip`}
						key={tag._id}
						style={chipStyles(tag.bgColor)}
					>
						{tag.name}
						<CancelRoundedIcon
							role="button"
							aria-hidden={false}
							aria-label={`Delete ${tag.name}`}
							sx={iconStyles}
							{...(!disabled && {
								onClick: () => handleDelete(tag),
							})}
						/>
					</div>
				)
			})}
		</div>
	)
}
