import type { StrategyDefinition } from '@asta/core'
import { AgentStrategy } from '@asta/core'

interface StrategiesState {
	strategies: StrategyDefinition[]
}

/**
 * Data resources available as parameters for strategies.
 */
export enum ResourceType {
	FormSpec = 'dat',
	Dataset = 'dataset',
	User = 'user',
	Identifier = 'id',
}

export const strategiesInitialState: StrategiesState = {
	strategies: [
		{
			id: AgentStrategy.OMBVerification,
			name: 'OMB Number / Expiration',
			description:
				'Verifies that the OMB Number and Expiration Date matches the FormSpec. Checks the forms repository, standalone PDF, and webform. Will create a new template, package, and workspace with just this form, unless they are provided.',
			params: [
				{
					id: 'dat',
					name: 'FormSpec',
					optional: false,
					type: ResourceType.FormSpec,
					resource: null,
				},
				// {
				// 	name: 'Funding Opportunity Number',
				// 	optional: true,
				// 	type: ResourceType.Identifier
				// }, {
				// 	name: 'Application Filing Name',
				// 	optional: true,
				// 	type: ResourceType.Identifier
				// }
			],
		},
		{
			id: AgentStrategy.DATVerification,
			name: 'FormSpec Verification',
			description:
				"Verifies that the webform implements the FormSpec's specifications by checking the form's fields against their corresponding FormSpec fields. Will create a new template, package, and workspace with just this form, unless they are all provided",
			params: [
				{
					id: 'dat',
					name: 'FormSpec',
					optional: false,
					type: ResourceType.FormSpec,
					resource: null,
				},
				// {
				// 	name: 'Funding Opportunity Number',
				// 	optional: true,
				// 	type: ResourceType.Identifier
				// }, {
				// 	name: 'Application Filing Name',
				// 	optional: true,
				// 	type: ResourceType.Identifier
				// }
			],
		},
		// {
		// 	name: 'Webform Submission',
		// 	description:
		// 		'Verifies that a webform can be submitted with the data provided. Will create a new template, package, and workspace with just this form, unless they are all provided.',
		// 	params: [
		// 		{
		// 			name: 'Webform Data',
		// 			optional: false,
		// 			type: ResourceType.Dataset,
		// 		},
		// 		// {
		// 		// 	name: 'Funding Opportunity Number',
		// 		// 	optional: true,
		// 		// 	type: ResourceType.Identifier
		// 		// }, {
		// 		// 	name: 'Application Filing Name',
		// 		// 	optional: true,
		// 		// 	type: ResourceType.Identifier
		// 		// }
		// 	],
		// },
	],
}

export const strategiesSelector = (state: any): StrategyDefinition[] =>
	state.strategies.strategies

export const strategiesReducer = (
	state = strategiesInitialState
): StrategiesState => {
	return state
}
