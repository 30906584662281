export type LogPageState = {
	logEntrySelected: any
	tabs: any[]
	tabSelected: number | null
}

export const logPageInitialState = {
	logEntrySelected: null,
	tabs: [],
	tabSelected: 1,
}

export const logEntrySelectedSelector = (state: any) =>
	state.logPage.logEntrySelected
export const tabsSelector = (state: any) => state.logPage.tabs
export const tabSelectedSelector = (state: any) => state.logPage.tabSelected

// ACTIONS
export type LogPageActions =
	| SetLogEntrySelected
	| OpenTab
	| SelectTab
	| CloseTab
	| SetTabEntry

export const SET_LOG_ENTRY_SELECTED = 'SET_LOG_ENTRY_SELECTED'

interface SetLogEntrySelected {
	type: typeof SET_LOG_ENTRY_SELECTED
	logEntrySelected: any
}

export function setLogEntrySelected(logEntry: any) {
	return (dispatch: any) => {
		dispatch({
			type: SET_LOG_ENTRY_SELECTED,
			logEntrySelected: logEntry,
		})
	}
}

export const OPEN_LOG_PANEL_TAB = 'OPEN_LOG_PANEL_TAB'
interface OpenTab {
	type: typeof OPEN_LOG_PANEL_TAB
	entry: any
}
export function openTab(entry: any) {
	return (dispatch: any) => {
		dispatch({ type: OPEN_LOG_PANEL_TAB, entry })
	}
}

export const SET_LOG_PANEL_TAB_ENTRY = 'SET_LOG_PANEL_TAB_ENTRY'
interface SetTabEntry {
	type: typeof SET_LOG_PANEL_TAB_ENTRY
	tabIdx: number
	entry: any
}
/**
 * Set the entry display in the tab.
 */
export function setTabEntry(tabIdx: number, entry: any) {
	return (dispatch: any) => {
		dispatch({ type: SET_LOG_PANEL_TAB_ENTRY, tabIdx, entry })
	}
}

export const SET_LOG_PANEL_TAB_SELECTED = 'SET_LOG_PANEL_TAB_SELECTED'
interface SelectTab {
	type: typeof SET_LOG_PANEL_TAB_SELECTED
	tabIdx: number | null
}
export function selectTab(tabIdx: number | null) {
	return (dispatch: any) => {
		dispatch({ type: SET_LOG_PANEL_TAB_SELECTED, tabIdx })
	}
}

export const CLOSE_LOG_PANEL_TAB = 'CLOSE_LOG_PANEL_TAB'
interface CloseTab {
	type: typeof CLOSE_LOG_PANEL_TAB
	tabIdx: number | null
}
export function closeTab(tabIdx: number | null) {
	return (dispatch: any) => {
		dispatch({ type: CLOSE_LOG_PANEL_TAB, tabIdx })
	}
}

// REDUCER
export const logPageReducer = (
	state = logPageInitialState,
	action: LogPageActions
): LogPageState => {
	switch (action.type) {
		case SET_LOG_ENTRY_SELECTED:
			return {
				...state,
				logEntrySelected: action.logEntrySelected,
			}

		case OPEN_LOG_PANEL_TAB:
			return {
				...state,
				tabSelected: 0,
				tabs: [action.entry],
			}

		case SET_LOG_PANEL_TAB_ENTRY:
			return {
				...state,
				tabs: state.tabs.map((tab, idx) => {
					if (idx === action.tabIdx) return action.entry
					return tab
				}),
			}

		case CLOSE_LOG_PANEL_TAB:
			return {
				...state,
				tabSelected:
					state.tabSelected === state.tabs.length - 1
						? state.tabs.length - 2
						: state.tabSelected,
				tabs: state.tabs.filter((t, i) => i !== action.tabIdx),
			}

		case SET_LOG_PANEL_TAB_SELECTED:
			return {
				...state,
				tabSelected: action.tabIdx,
			}

		default:
			return state
	}
}
