import { useCallback } from 'react'

import { useSortable } from '@dnd-kit/sortable'
import type { Transform } from '@dnd-kit/utilities'
import { CSS } from '@dnd-kit/utilities'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { Box, styled } from '@mui/material'
import { flexRender, type Header } from '@tanstack/react-table'

const HeaderCell = styled('td', {
	shouldForwardProp: prop =>
		prop !== 'width' &&
		prop !== 'isDragging' &&
		prop !== 'transform' &&
		prop !== 'canSort',
})<{
	width: string | number
	isDragging: boolean
	transform: Transform
	canSort: boolean
}>(({ theme, width, isDragging, transform, canSort }) => ({
	padding: 6,
	color: theme.palette.text.primary,
	borderLeft: '1px solid hsl(202, 10%, 88%)',
	borderLeftColor: '#f3f3f3',
	borderBottom: '1px solid hsl(202, 10%, 88%)',
	borderBottomColor: '#f3f3f3',
	background: theme.palette.background.default,
	textTransform: 'uppercase',
	fontWeight: 600,
	lineHeight: 1.5,
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	width,
	minHeight: 30,
	position: 'relative',
	opacity: isDragging ? 0.8 : 1,
	transform: CSS.Translate.toString(transform),
	transition: 'width transform 0.2s ease-in-out',
	zIndex: isDragging ? 1 : 0,
	cursor: canSort ? 'pointer' : 'default',

	'&:first-of-type': {
		padding: '6px !important',
	},
}))

const DraggableBox = styled('div')`
	cursor: pointer;
`

export const Resizer = styled('div', {
	shouldForwardProp: prop => prop !== 'isResizing',
})<{ isResizing: boolean }>(({ theme, isResizing }) => ({
	opacity: 0,
	position: 'absolute',
	right: 0,
	top: 0,
	height: '100%',
	width: 5,
	background: theme.palette.action.hover,
	cursor: 'col-resize',
	userSelect: 'none',
	touchAction: 'none',
	...(isResizing && {
		background: theme.palette.action.selected,
		opacity: 1,
	}),
	'&:hover': {
		opacity: 1,
	},
}))

const StyledArrowDown = styled(ArrowDownwardIcon)`
	font-size: 14px;
`

const StyledArrowUp = styled(ArrowUpwardIcon)`
	font-size: 14px;
`

export function DraggableHeader<T>({
	header,
	sort,
	setSort,
}: {
	header: Header<T, unknown>
	sort?: string
	setSort?: (sort: string) => void
}) {
	const { attributes, isDragging, listeners, setNodeRef, transform } =
		useSortable({
			id: header.column.id,
		})

	//Needed for select rows, otherwise it wouldn't work
	const isSelect = header.column.id === 'select'
	const canSort = header.column.getCanSort()
	const sortable = canSort && setSort
	const isSorted = sortable && sort?.replace('-', '') === header.column.id
	const isDesc = isSorted && sort.startsWith('-')

	const _setSort = useCallback(() => {
		if (!sortable) return

		if (isSorted && sort.startsWith('-')) {
			setSort(null)
			return
		}

		if (isSorted) {
			setSort(`-${header.column.id}`)
			return
		}

		setSort(header.column.id)
	}, [setSort, header.column.id, isSorted, sort, sortable])

	return (
		<HeaderCell
			colSpan={header.colSpan}
			ref={setNodeRef}
			width={!isNaN(header.getSize()) ? header.getSize() : 'auto'}
			isDragging={isDragging}
			transform={transform}
			canSort={canSort}
		>
			<Box onClick={_setSort} display="flex" flexDirection="row">
				{isSelect ? (
					flexRender(
						header.column.columnDef.header,
						header.getContext()
					)
				) : (
					<DraggableBox {...attributes} {...listeners}>
						{header.isPlaceholder
							? null
							: flexRender(
									header.column.columnDef.header,
									header.getContext()
								)}
					</DraggableBox>
				)}
				<Box m={0.3} ml={1}>
					{isSorted &&
						(isDesc ? <StyledArrowDown /> : <StyledArrowUp />)}
				</Box>
			</Box>
			<Resizer
				isResizing={header.column.getIsResizing()}
				{...{
					onMouseDown: header.getResizeHandler(),
					onTouchStart: header.getResizeHandler(),
				}}
			/>
		</HeaderCell>
	)
}
