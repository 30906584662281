import {
	alertsInitialState,
	alertsReducer,
} from 'deprecated/features/alerts/alerts'
import applicationsV2 from 'deprecated/services/applicationsSlice'
import bApplications, {
	bApplicationInitialState,
} from 'deprecated/services/BApplicationSlice'
import permissions, {
	permissionsInitialState,
} from 'deprecated/services/PermissionSlice'
import runParameters, {
	runParametersInitialState,
} from 'deprecated/services/RunParametersSlice'
import users, { usersInitialState } from 'deprecated/services/UserSlice'
import variants, { variantInitialState } from 'deprecated/services/VariantSlice'
import workspaces, {
	workspaceInitialState,
} from 'deprecated/services/WorkspaceSlice'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunk from 'redux-thunk'
import menu from 'store/reducers/menu'
import snackbar from 'store/reducers/snackbar'

import {
	agentsReducer,
	initialState as agentsInitialState,
} from './deprecated/features/agents/agents'
import {
	applicationComponentAssocationsInitialState,
	applicationComponentAssociationsReducer,
	applicationsInitialState,
	applicationsReducer,
	modelsInitialState,
	modelsReducer,
} from './deprecated/features/applications'
import {
	initialState as assetsInitialState,
	ruleAssetsReducer as assetsReducer,
} from './deprecated/features/assets'
import { authInitialState, authReducer } from './deprecated/features/auth'
// import { requirementsDocumentsInitialState, requirementsDocumentsReducer } from './features/requirementsDocuments'
import {
	filesInitialState as downloadedFilesInitialState,
	filesReducer as downloadedFilesReducer,
} from './deprecated/features/downloadedFiles'
import {
	driversReducer,
	initialState as driversInitialState,
} from './deprecated/features/drivers/drivers'
import {
	initialState as issuesInitialState,
	issuesReducer,
} from './deprecated/features/issues'
import {
	deleteComponentInitialState,
	deleteComponentReducer,
} from './deprecated/features/model/deleteComponent'
import {
	modelInitialState,
	modelReducer,
} from './deprecated/features/model/model'
import {
	updateComponentInitialState,
	updateComponentReducer,
} from './deprecated/features/model/updateComponent'
import {
	activitiesPageInitialState,
	activitiesPageReducer,
} from './deprecated/features/pages/activities/activitiesPage'
import {
	logPageInitialState,
	logPageReducer,
} from './deprecated/features/pages/log'
import {
	reportIssueInitialState,
	reportIssueReducer,
} from './deprecated/features/reportIssue'
import {
	requirementsDocumentsInitialState,
	requirementsDocumentsReducer,
} from './deprecated/features/requirementsDocuments'
import {
	initialState as runsV2InitialState,
	runsV2Reducer,
} from './deprecated/features/run/runsInProgress/runsInProgress'
import {
	initialState as runWorkQueueInitialState,
	runWorkQueueReducer,
} from './deprecated/features/run/workQueues/workQueues'
import { runsInitialState, runsReducer } from './deprecated/features/runs'
import {
	screenshotsInitialState,
	screenshotsReducer,
} from './deprecated/features/screenshots'
import { startInitialState, startReducer } from './deprecated/features/start'
import {
	stopRunInitialState,
	stopRunReducer,
} from './deprecated/features/stopRun'
import {
	strategiesInitialState,
	strategiesReducer,
} from './deprecated/features/strategies/strategies'
import {
	workQueueInitialState,
	workQueueReducer,
} from './deprecated/features/workQueue'

const rootReducer = combineReducers({
	activitiesPage: activitiesPageReducer,
	alerts: alertsReducer,
	agents: agentsReducer,
	applications: applicationsReducer,
	applicationComponentAssociations: applicationComponentAssociationsReducer,
	auth: authReducer,
	downloadedFiles: downloadedFilesReducer,
	drivers: driversReducer,
	issues: issuesReducer,
	logPage: logPageReducer,
	model: modelReducer,
	models: modelsReducer,
	deleteComponent: deleteComponentReducer,
	updateComponent: updateComponentReducer,

	assets: assetsReducer,

	runs: runsReducer,
	runsV2: runsV2Reducer,
	runParameters,
	runWorkQueues: runWorkQueueReducer,
	screenshots: screenshotsReducer,
	start: startReducer,
	strategies: strategiesReducer,
	stopRun: stopRunReducer,
	reportIssue: reportIssueReducer,
	requirementsDocuments: requirementsDocumentsReducer,
	workQueue: workQueueReducer,

	menu,
	snackbar,

	applicationsV2,
	permissions,
	workspaces,
	variants,
	bApplications,
	users,
})

export const initialState = {
	activitiesPage: activitiesPageInitialState,
	alerts: alertsInitialState,
	agents: agentsInitialState,
	applications: applicationsInitialState,
	applicationComponentAssociations:
		applicationComponentAssocationsInitialState,
	auth: authInitialState,
	downloadedFiles: downloadedFilesInitialState,
	drivers: driversInitialState,
	issues: issuesInitialState,
	logPage: logPageInitialState,
	model: modelInitialState,
	models: modelsInitialState,
	deleteComponent: deleteComponentInitialState,
	permissions: permissionsInitialState,
	updateComponent: updateComponentInitialState,

	assets: assetsInitialState,

	runs: runsInitialState,
	runParameters: runParametersInitialState,
	runsV2: runsV2InitialState,
	runWorkQueues: runWorkQueueInitialState,
	screenshots: screenshotsInitialState,
	start: startInitialState,
	strategies: strategiesInitialState,
	stopRun: stopRunInitialState,
	reportIssue: reportIssueInitialState,
	requirementsDocuments: requirementsDocumentsInitialState,
	workQueue: workQueueInitialState,
	workspaces: workspaceInitialState,
	variants: variantInitialState,
	bApplications: bApplicationInitialState,
	users: usersInitialState,
}

const store = createStore(rootReducer, initialState, applyMiddleware(thunk))

export default store
