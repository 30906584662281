import React, { useCallback, useMemo } from 'react'

import { Box, Button, Collapse } from '@mui/material'
import { styled } from '@mui/material/styles'
import type { FC } from 'react'

import type {
	SelectFilterDefinition,
	TextFilterDefinition,
} from '../TableFilters'
import { FilterBox } from './FilterBox'
import { FilterRow } from './FilterRow'
import type { CustomFilterShape } from './useFilters'
import {
	addOperatorsToOptions,
	convertFilterArrayToObject,
	filterIncompleteFilters,
} from './util'

const Dialog = styled(Collapse)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	position: 'absolute',
	marginTop: 0,
	background: theme.palette.background.paper,
	borderRadius: 0.25,
	padding: 20,
	boxShadow:
		'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
	zIndex: 11,
	minWidth: 300,
}))

const AddConditionButton = styled(Button)(() => ({
	cursor: 'pointer',
	marginTop: 16,
	fontWeight: 'bold',
}))

interface FilterListProps {
	open: boolean
	closeAndRemoveEmpty: () => void
	filters: Array<CustomFilterShape>
	options: Array<SelectFilterDefinition | TextFilterDefinition>
	onFilterChange: (newValue: CustomFilterShape, index: number) => void
}

export const FilterList: FC<FilterListProps> = ({
	open,
	closeAndRemoveEmpty,
	filters,
	options,
	onFilterChange,
}) => {
	const optionsWithOperators = useMemo(
		() => options.map(addOperatorsToOptions),
		[options]
	)

	const filtersAsObject = useMemo(
		() => convertFilterArrayToObject(filters),
		[filters]
	)

	const hasIncompleteFilters = useMemo(
		() => filterIncompleteFilters(filters).length !== filters.length,
		[filters]
	)

	const availableFiltersToAdd = useMemo(
		() =>
			optionsWithOperators.filter(
				option => !(option.column.value in filtersAsObject)
			),
		[filtersAsObject, optionsWithOperators]
	)

	const addNewFilterRow = useCallback(() => {
		const { column, operators } = availableFiltersToAdd[0]
		const newFilter = {
			[column.value]: {
				[operators[0].value]: null,
			},
		}
		onFilterChange(newFilter, filters.length ? filters.length + 1 : 1)
	}, [availableFiltersToAdd, filters, onFilterChange])

	return (
		<Dialog
			aria-hidden={!open}
			aria-label="Filter Dialog"
			role="dialog"
			in={open}
			timeout={75}
		>
			<Box>
				<FilterBox
					filters={filters}
					closeAndRemoveEmpty={closeAndRemoveEmpty}
				/>
				{filters.map((filter, i) => {
					const column = Object.keys(filter)[0]

					/**
					 * Include the options that have not been selected and
					 * The selected option when it's the current entry
					 */
					const filteredOptions = optionsWithOperators.filter(op => {
						return (
							!(op.column.value in filtersAsObject) ||
							op.column.value === column
						)
					})

					return (
						<FilterRow
							key={column}
							filter={filter}
							index={i}
							options={filteredOptions}
							onChange={onFilterChange}
						/>
					)
				})}

				{/* Add new rows */}
				{!hasIncompleteFilters && availableFiltersToAdd.length > 0 && (
					<Box width="100%">
						<AddConditionButton
							role="button"
							aria-label="Add condition"
							variant="text"
							onClick={addNewFilterRow}
						>
							+ Add condition
						</AddConditionButton>
					</Box>
				)}
			</Box>
		</Dialog>
	)
}
