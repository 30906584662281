import React from 'react'

import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material'
import type { ThemeOptions } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import type { PaletteProps, SxProps } from '@mui/system'

interface Props {
	title: string | JSX.Element
	buttonText: string
	show: boolean
	setShow: (show: boolean) => void
	onClose: () => void
	buttonEnabled?: boolean
	content?: JSX.Element
	actions?: {
		text: string
		color: PaletteProps['color']
		onClick: () => void
		disabled?: boolean
	}[]
	children?: JSX.Element
	/**
	 * `sx` props for the dialog
	 */
	sx?: SxProps
	contentSx?: SxProps
	ref?: React.Ref<unknown>
}

const useStyles = makeStyles((theme: ThemeOptions) => ({
	dialogTitle: {
		borderBttom: `1px solid ${theme?.palette?.divider}`,
	},
	dialogActions: {
		borderTop: `1px solid ${theme?.palette?.divider}`,
	},
}))

const AstaDialog = React.forwardRef<HTMLElement, Props>(function AstaDialog(
	{
		buttonText,
		show,
		setShow,
		onClose,
		title,
		content,
		actions,
		sx,
		contentSx,
	},
	ref
) {
	const theme = useTheme()
	const classes = useStyles(theme)

	return (
		<Box ref={ref}>
			<Button
				variant="contained"
				color="primary"
				onClick={_ => setShow(true)}
				fullWidth={false}
				sx={{
					width: '200px',
					m: '8px',
					marginLeft: 'auto',
					p: '5px 16px',
				}}
			>
				{buttonText}
			</Button>
			<Dialog
				open={show}
				sx={{ ...sx }}
				PaperProps={{ sx: { maxWidth: 'unset' } }}
			>
				<DialogTitle className={classes.dialogTitle}>
					{title}
				</DialogTitle>
				<DialogContent sx={contentSx}>
					{content != null && content}
				</DialogContent>
				<DialogActions className={classes.dialogActions}>
					<Button
						aria-label="Cancel"
						color="secondary"
						variant="outlined"
						onClick={_ => {
							setShow(false)
							onClose()
						}}
						fullWidth={false}
					>
						Cancel
					</Button>
					{actions != null &&
						actions.map((a, i) => (
							<Button
								key={a.text ?? i}
								color={a.color}
								onClick={a.onClick}
								variant="contained"
								disabled={a.disabled ?? false}
							>
								{a.text}
							</Button>
						))}
				</DialogActions>
			</Dialog>
		</Box>
	)
})

export default AstaDialog
