import type { StartRunRequestDTO } from '@asta/repository-dtos'

import { get, post } from './base'

/******************************* STATE ****************************************/
export type StartState = {
	startOptionsSelected: StartOptions
	startOptionsAvailable: StartOptionsAvailable
	pending: boolean
	error: any
	success: boolean
}

export const startInitialState: StartState = {
	startOptionsSelected: {
		application: null,
		parameter: null,
		ruleset: null,
	},
	startOptionsAvailable: {
		applications: [],
		parameters: [],
		rulesets: [],
	},
	pending: false,
	error: null,
	success: false,
}

export type StartOptions = {
	application: string | null
	parameter: string | null
	ruleset: string | null
}

export type StartOptionsAvailable = {
	applications: StartOptionChoice[]
	parameters: StartOptionChoice[]
	rulesets: StartOptionChoice[]
}

export type StartOptionChoice = {
	id: string
	name: string
}

/***************************** SELECTORS **************************************/
export const startOptionsSelectedSelector = (state: any) =>
	state.start.startOptionsSelected
export const startOptionsAvailableSelector = (state: any) =>
	state.start.startOptionsAvailable
export const startPendingSelector = (state: any) => state.start.pending
export const startErrorSelector = (state: any) => state.start.error
export const startSuccessSelector = (state: any) => state.start.success

export const getStartOptions = (state: any) =>
	state.getStartOptions.getStartOptions
export const getStartOptionsPending = (state: any) =>
	state.getStartOptions.pending
export const getStartOptionsError = (state: any) => state.getStartOptions.error

/****************************** ACTIONS ***************************************/
export type StartActionTypes =
	| StartPendingAction
	| StartSuccessAction
	| StartErrorAction
	| ResetStartStateAction

export type GetStartOptionsActionTypes =
	| GetStartOptionsPendingAction
	| GetStartOptionsSuccessAction
	| GetStartOptionsErrorAction

export const RESET_START_STATE = 'RESET_START_STATE'

export const START_PENDING = 'START_PENDING'
export const START_SUCCESS = 'START_SUCCESS'
export const START_ERROR = 'START_ERROR'

export const GET_START_OPTIONS_PENDING = 'GET_START_OPTIONS_PENDING'
export const GET_START_OPTIONS_SUCCESS = 'GET_START_OPTIONS_SUCCESS'
export const GET_START_OPTIONS_ERROR = 'GET_START_OPTIONS_ERROR'

interface ResetStartStateAction {
	type: typeof RESET_START_STATE
}

interface StartSuccessAction {
	type: typeof START_SUCCESS
}
interface StartErrorAction {
	type: typeof START_ERROR
	error: any
}
interface StartPendingAction {
	type: typeof START_PENDING
}

interface GetStartOptionsPendingAction {
	type: typeof GET_START_OPTIONS_PENDING
}
interface GetStartOptionsSuccessAction {
	type: typeof GET_START_OPTIONS_SUCCESS
	startOptionsAvailable: StartOptionsAvailable
}
interface GetStartOptionsErrorAction {
	type: typeof GET_START_OPTIONS_ERROR
	error: any
}

export function resetStartStateAction() {
	return { type: RESET_START_STATE }
}

export function startPendingAction() {
	return { type: START_PENDING }
}
export function startSuccessAction() {
	return { type: START_SUCCESS }
}
export function startErrorAction(error: any) {
	return { type: START_ERROR, error }
}

export function getStartOptionsPendingAction() {
	return { type: GET_START_OPTIONS_PENDING }
}
export function getStartOptionsSuccessAction(
	startOptionsAvailable: StartOptionsAvailable
) {
	return { type: GET_START_OPTIONS_SUCCESS, startOptionsAvailable }
}
export function getStartOptionsErrorAction(error: any) {
	return { type: GET_START_OPTIONS_ERROR, error }
}

/***************************** REQUESTS ***************************************/

export function startRunRequest(dto: StartRunRequestDTO) {
	return (dispatch: any) => {
		// TODO: dtos and some class for building api requests
		dispatch(startPendingAction())
		post(`/api/v2/start/variant/${dto.applicationId}`, dto)
			.then(res => res.json())
			.then(res => {
				if (res.statusCode != null && res.statusCode !== 200)
					dispatch(startErrorAction(res.message))
				else dispatch(startSuccessAction())
			})
			.catch(_error => {
				dispatch(startErrorAction('Unknown error.'))
			})
			.finally(() => {
				//
				// setTimeout(dispatch(startErrorAction('been a while')), 10000)
			})
	}
}

export function getStartOptionsRequest() {
	return (dispatch: any) => {
		dispatch(getStartOptionsPendingAction())
		get('/api/v2/run/options')
			.then(res => res.json())
			.then(startOptions => {
				if (startOptions.error) throw startOptions.error
				dispatch(getStartOptionsSuccessAction(startOptions))
			})
			.catch(error => {
				dispatch(getStartOptionsErrorAction(error))
			})
	}
}

export function resetStartState() {
	return (dispatch: any) => {
		dispatch(resetStartStateAction())
	}
}

/****************************** REDUCER ***************************************/
export function startReducer(
	state = startInitialState,
	action: StartActionTypes | GetStartOptionsActionTypes
): StartState {
	switch (action.type) {
		case RESET_START_STATE:
			return { ...state, pending: false, error: null, success: false }

		case START_PENDING:
			return { ...state, pending: true, error: null, success: false }

		case START_SUCCESS:
			return { ...state, pending: false, error: null, success: true }

		case START_ERROR:
			return {
				...state,
				pending: false,
				error: action.error,
				success: false,
			}

		case GET_START_OPTIONS_PENDING:
			return { ...state, pending: true, error: null }

		case GET_START_OPTIONS_SUCCESS:
			return {
				...state,
				pending: false,
				error: null,
				startOptionsAvailable: action.startOptionsAvailable,
			}

		case GET_START_OPTIONS_ERROR:
			return { ...state, pending: false, error: action.error }

		default:
			return state
	}
}
