import axios from 'axios'
import { getToken } from 'deprecated/features/base'

import { config } from '../../config'
import AuthService from './AuthService'

const {
	repository: { url: repositoryUrl },
	oss: { url: ossUrl },
} = config.services

const axiosInstance = axios.create({
	baseURL: repositoryUrl,
	headers: {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
		'x-api-key': getToken(),
	},
	withCredentials: true,
})

axiosInstance.interceptors.response.use(
	response => {
		return response.data
	},
	error => {
		const win: Window = window
		if (error.response) {
			switch (error.response.status) {
				case 401:
					AuthService.logout().then(() => {
						win.location.assign(`${ossUrl}/login`)
					})
					break
				case 404:
					win.location = '/not-found'
					break
				case 500:
					// win.location = '/server-error'
					break
				default:
					return Promise.reject(error.response.data)
			}
		} else {
			return Promise.reject(error)
		}
	}
)

export default axiosInstance
