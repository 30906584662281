import React from 'react'

import {
	Box,
	Button,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	InputLabel,
	Skeleton,
	Stack,
	TextField,
} from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik'
import type { FC } from 'react'
import * as Yup from 'yup'

import { DEFAULT_GLOBAL_VIEWS_NAME } from '.'

export interface ViewFormValues {
	name: string
}

interface Props {
	onSave: (values: ViewFormValues) => void
	onClose: () => void
	name?: string
	isAdmin: boolean
}

const ViewsForm: FC<Props> = ({ onSave, onClose, name = '', isAdmin }) => {
	const formik = useFormik({
		initialValues: {
			name,
		},
		validationSchema: Yup.object().shape({
			name: Yup.string().max(255).required('Name is required'),
		}),
		onSubmit: (values, { setSubmitting }) => {
			onSave(values)
			setSubmitting(false)
		},
	})

	const {
		errors,
		touched,
		handleSubmit,
		isSubmitting,
		getFieldProps,
		values,
	} = formik

	if (isSubmitting) {
		return <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
	}

	return (
		<FormikProvider value={formik}>
			<Form autoComplete="off" noValidate onSubmit={handleSubmit}>
				<DialogTitle>Save view as...</DialogTitle>
				<Divider />
				<DialogContent sx={{ p: 2.5 }}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Stack spacing={1.25}>
								<InputLabel htmlFor="view-name">
									Name
								</InputLabel>
								<TextField
									fullWidth
									id="view-name"
									placeholder="Enter View Name"
									{...getFieldProps('name')}
									error={Boolean(touched.name && errors.name)}
									helperText={touched.name && errors.name}
								/>
							</Stack>
						</Grid>
					</Grid>
				</DialogContent>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						justifyContent: 'flex-end',
						marginBottom: 4,
					}}
				>
					<Button
						variant="contained"
						color="primary"
						type="submit"
						sx={{ marginRight: 2 }}
						disabled={
							!isAdmin &&
							values.name === DEFAULT_GLOBAL_VIEWS_NAME
						}
					>
						Save
					</Button>
					<Button
						variant="outlined"
						color="secondary"
						onClick={onClose}
						sx={{ marginRight: 4 }}
					>
						Cancel
					</Button>
				</Box>
			</Form>
		</FormikProvider>
	)
}

export default ViewsForm
