import { useVariantControllerGetVariants } from 'api/repository/repositoryComponents'
import { DEFAULT_STALE_TIME } from 'deprecated/services/constants'
import type { Variant } from 'shared/types'

const EMPTY_VARIANTS = [] as Variant[]
export const useAllVariants = () => {
	const { data, isLoading, error } = useVariantControllerGetVariants(
		{},
		{ staleTime: DEFAULT_STALE_TIME }
	)

	const variants = data?.data ?? EMPTY_VARIANTS

	return {
		variants,
		isLoading,
		error,
	}
}
