import React from 'react'

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { Box, Typography } from '@mui/material'
import type { FC } from 'react'

import type { CustomFilterShape } from './useFilters'

const buttonContainerStyles = {
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
	cursor: 'pointer',
}

interface FilterButtonProps {
	filters: Array<CustomFilterShape>
	closeAndRemoveEmpty: () => void
}

export const FilterBox: FC<FilterButtonProps> = ({
	filters,
	closeAndRemoveEmpty,
}) => {
	return (
		<Box sx={buttonContainerStyles}>
			{filters.length > 0 ? (
				<Typography variant="body2">
					In this table, show records
				</Typography>
			) : (
				<Typography variant="body2">
					No filter conditions are applied
				</Typography>
			)}
			<CloseOutlinedIcon
				role="button"
				aria-hidden="false"
				aria-label="close filter dialog"
				fontSize="small"
				onClick={closeAndRemoveEmpty}
			/>
		</Box>
	)
}
