import React from 'react'

import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { COLOR_PALLETE } from 'deprecated/components'

const sx = {
	failed: {
		color: COLOR_PALLETE.failed,
		fontSize: '1.2rem',
	},
	passed: {
		color: COLOR_PALLETE.passed,
		fontSize: '1.2rem',
	},
}
export const TestAssetsTableResults = ({
	result,
}: {
	result: 'Passed' | 'Failed'
}) => {
	switch (result) {
		case 'Failed':
			return <CloseIcon sx={sx.failed} />
		case 'Passed':
			return <CheckIcon sx={sx.passed} />
		default:
			return null
	}
}
