import type { Rule } from '@asta/core'
import type {
	AssetDto,
	FlowDto,
	RuleAssetDto,
	TaskAssetDto,
} from '@asta/repository-dtos'
import { AssetType } from '@asta/repository-dtos'

import type { BaseResourceState } from './base/base'
import {
	buildResourceReducer,
	createOne,
	deleteOne,
	fetchAll,
	fetchOne,
	initialBaseResourceState,
	RESOURCE,
	updateOne,
} from './base/base'

/**
 * @deprecated
 */

type RuleAssetsState = BaseResourceState<
	| (AssetDto & {
			resource: RuleAssetDto
	  })
	| {
			resource: TaskAssetDto
	  }
>

export const initialState: RuleAssetsState = {
	...initialBaseResourceState,
}

export const rulesSelector = (state: any): Record<string, Rule> => {
	const resState = state.assets as RuleAssetsState
	return resState.resources
		.filter(res => res.resource.type == AssetType.Rule)
		.reduce(
			(rules, rule) => {
				rules[rule.resource.data._id] = rule.resource.data
				return rules
			},
			{} as Record<string, Rule>
		)
}

export const ruleSelector = (state: any, id: string): Rule | null => {
	const resState = state.assets as RuleAssetsState
	const byId = Object.values(
		resState.resources.filter(res => res.resource.type == AssetType.Rule)
	).find(ass => ass.resource.data.id == id || ass.resource.data._id == id)
	const by_Id = state.assets.resources[id]
	return byId != null ? byId : by_Id
}

export const makeGetRulesRequest = () =>
	fetchAll(RESOURCE.ASSETS, '/api/v2/assets/rule')

export const createRule = (rule: Rule) =>
	createOne(RESOURCE.ASSETS, rule, '/api/v2/assets/rule')

export const makeCreateRuleRequest = (rule: Rule) => {
	const asset: AssetDto = {
		_id: rule._id,
		name: rule.name,
		desc: rule.desc,
		tags: rule.tags,
		type: 'rule',
		resource: {
			type: 'rule',
			data: rule,
		},
	}
	return createOne(RESOURCE.ASSETS, asset, '/api/v2/assets/rule')
}

export const updateRule = (rule: Rule) =>
	updateOne(RESOURCE.ASSETS, rule, '/api/v2/assets/rule/' + rule.id)

export const makeUpdateRuleRequest = (rule: Rule) =>
	updateOne(RESOURCE.ASSETS, rule, '/api/v2/assets/rule/' + rule.id)

export const deleteRule = (rule: Rule) =>
	deleteOne(RESOURCE.ASSETS, rule.id, '/api/v2/assets/rule/' + rule.id)

export const makeDeleteRuleRequest = (rule: Rule) =>
	deleteOne(RESOURCE.ASSETS, rule.id, '/api/v2/assets/rule/' + rule.id)

export const ruleAssetsReducer = buildResourceReducer(
	initialState,
	RESOURCE.ASSETS
)

export const activitiesSelector = (
	state: any
): (FlowDto & { _id: string })[] => {
	const resState = state.assets as RuleAssetsState
	return resState.resources
		.filter(res => res.resource.type == AssetType.Task)
		.map(res => {
			return {
				...res.resource.data,
				_id: res._id,
			}
		})
}

export const activitySelector = (state: any, id: string): FlowDto | null => {
	const resState = state.assets as RuleAssetsState
	const byId = Object.values(
		resState.resources.filter(res => res.resource.type == AssetType.Task)
	).find(
		activity =>
			activity.resource.data.id == id || activity.resource.data._id == id
	)
	const by_Id = state.assets.resources[id]
	return byId != null ? byId : by_Id
}

export const fetchTasks = () => fetchAll(RESOURCE.ASSETS, '/api/v2/assets/task')

export const fetchTask = (id: string) => fetchOne(RESOURCE.ASSETS, 'task/' + id)

export const createTask = (task: AssetDto) =>
	createOne(RESOURCE.ASSETS, task, '/api/v2/assets/task')

export const updateTask = (task: AssetDto) =>
	updateOne(RESOURCE.ASSETS, task, '/api/v2/assets/task/' + task._id)

export const deleteTask = (task: FlowDto) =>
	deleteOne(RESOURCE.ASSETS, task.id, '/api/v2/assets/task/' + task.id)

export const fetchAssets = (variant: string, type: AssetType) => {
	switch (type) {
		case AssetType.Rule:
			return fetchAll(RESOURCE.ASSETS, '/api/v2/assets/rule')
		case AssetType.Task:
			return fetchAll(RESOURCE.ASSETS, '/api/v2/assets/task')
		default:
			return fetchAll(RESOURCE.ASSETS, '/api/v2/assets')
	}
}

export const assetsSelector = (state: any) => {
	return state.assets.resources
}
