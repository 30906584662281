import React, { createContext } from 'react'

// project import
import { mantisConfig } from 'config'
// types
import type {
	CustomizationProps,
	FontFamily,
	I18n,
	PresetColor,
	ThemeDirection,
	ThemeMode,
} from 'deprecated/types/config'
import useLocalStorage from 'hooks/useLocalStorage'
import type { ReactNode } from 'react'

// initial state
const initialState: CustomizationProps = {
	...mantisConfig,
	onChangeContainer: () => {},
	onChangeLocalization: () => {},
	onChangeMode: () => {},
	onChangePresetColor: () => {},
	onChangeDirection: () => {},
	onChangeMiniDrawer: () => {},
	onChangeFontFamily: () => {},
}

// ==============================|| CONFIG CONTEXT & PROVIDER ||============================== //

const ConfigContext = createContext(initialState)

type ConfigProviderProps = {
	children: ReactNode
}

function ConfigProvider({ children }: ConfigProviderProps) {
	const [config, setConfig] = useLocalStorage(
		'mantis-react-ts-config',
		initialState
	)

	const onChangeContainer = () => {
		setConfig({
			...config,
			container: !config.container,
		})
	}

	const onChangeLocalization = (lang: I18n) => {
		setConfig({
			...config,
			i18n: lang,
		})
	}

	const onChangeMode = (mode: ThemeMode) => {
		setConfig({
			...config,
			mode,
		})
	}

	const onChangePresetColor = (theme: PresetColor) => {
		setConfig({
			...config,
			presetColor: theme,
		})
	}

	const onChangeDirection = (direction: ThemeDirection) => {
		setConfig({
			...config,
			themeDirection: direction,
		})
	}

	const onChangeMiniDrawer = (miniDrawer: boolean) => {
		setConfig({
			...config,
			miniDrawer,
		})
	}

	const onChangeFontFamily = (fontFamily: FontFamily) => {
		setConfig({
			...config,
			fontFamily,
		})
	}

	return (
		<ConfigContext.Provider
			value={{
				...config,
				onChangeContainer,
				onChangeLocalization,
				onChangeMode,
				onChangePresetColor,
				onChangeDirection,
				onChangeMiniDrawer,
				onChangeFontFamily,
			}}
		>
			{children}
		</ConfigContext.Provider>
	)
}

if (process.env.NODE_ENV !== 'production') {
	ConfigContext.displayName = 'ConfigContext'
}

export { ConfigProvider, ConfigContext }
