import React, { lazy, Suspense } from 'react'

import { WithLoaderSuspense } from 'components/shared/loader'
import FormSpecPage from 'pages/App/Assets/FormSpecPage'
// test-center
import StartPage from 'pages/App/Start'
import ConfigPage from 'pages/App/Start/ConfigPage'

import { ADMIN_ROUTES, TEST_CENTER_ROUTES } from './routes'

// layout
const PageLayout = WithLoaderSuspense(lazy(() => import('layout/PageLayout')))

const StatusPage = WithLoaderSuspense(lazy(() => import('pages/App/Status')))
const DashboardPage = WithLoaderSuspense(
	lazy(() => import('pages/App/Dashboard/DashboardPage'))
)

// artifacts pages
const ModelPage = WithLoaderSuspense(
	lazy(() => import('pages/App/Artifacts/Model/ModelsPage'))
)
const LogPage = WithLoaderSuspense(
	lazy(() => import('pages/App/Artifacts/Log/LogPage'))
)
const RunsPage = WithLoaderSuspense(
	lazy(() => import('pages/App/Artifacts/Runs/RunsPage'))
)
// todo: add this back when we add issues
// const IssuesPage = WithLoaderSuspense(
// 	lazy(() => import('pages/App/Artifacts/Issues/IssuesPage'))
// )

// assets pages
const RulesPage = WithLoaderSuspense(
	lazy(() => import('pages/App/Assets/RulesPage'))
)
const DatasetsPage = WithLoaderSuspense(
	lazy(() => import('pages/App/Assets/DatasetsPage'))
)
const RunTemplatesPage = WithLoaderSuspense(
	lazy(() => import('pages/App/Assets/RunTemplatesPage'))
)
const FlowsPage = WithLoaderSuspense(
	lazy(() => import('pages/App/Assets/FlowsPage'))
)

// admin-center
const AdminPage = WithLoaderSuspense(
	lazy(() => import('../pages/Admin/AdminPage'))
)
const SystemSettings = WithLoaderSuspense(
	lazy(() => import('pages/Admin/system-settings/SystemSettings.page'))
)
const WorkspacesPage = WithLoaderSuspense(
	lazy(() => import('pages/Admin/Workspaces.page'))
)

const WorkspacePage = WithLoaderSuspense(
	lazy(() => import('pages/Admin/workspace/page'))
)

const BApplicationsPage = WithLoaderSuspense(
	lazy(() => import('pages/Admin/Applications.page'))
)
const VariantsPage = WithLoaderSuspense(
	lazy(() => import('pages/Admin/Variants.page'))
)
const UsersPage = WithLoaderSuspense(
	lazy(() => import('pages/Admin/users/Users.page'))
)
const InvitationsPage = WithLoaderSuspense(
	lazy(() => import('pages/Admin/invitations/invitations.page'))
)
const PATsManagementPage = WithLoaderSuspense(
	lazy(() => import('pages/Admin/pat/page'))
)
const TagsPage = WithLoaderSuspense(
	lazy(() => import('pages/Admin/tags/Tags.page'))
)

// error pages
const NotFoundPage = WithLoaderSuspense(
	lazy(() => import('pages/404/index.page'))
)
const ServerErrorPage = WithLoaderSuspense(
	lazy(() => import('pages/500/index.page'))
)

const MainRoutes = {
	path: '',
	children: [
		{
			path: '',
			children: [
				{
					path: '',
					element: <PageLayout />,
					children: [
						{
							path: '',
							element: <ConfigPage />,
						},
					],
				},
				{
					path: ADMIN_ROUTES.workspaces.path(),
					element: <PageLayout />,
					children: [
						{
							path: '',
							element: (
								<AdminPage>
									<WorkspacesPage />
								</AdminPage>
							),
						},
						{
							path: ADMIN_ROUTES.workspaceApps.path(),
							children: [
								{
									path: '',
									element: (
										<AdminPage>
											<BApplicationsPage />
										</AdminPage>
									),
								},
								{
									path: ADMIN_ROUTES.appVariants.path(),
									element: (
										<AdminPage>
											<VariantsPage />
										</AdminPage>
									),
								},
							],
						},
					],
				},
				{
					path: ADMIN_ROUTES.workspace.path(),
					element: <PageLayout />,
					children: [
						{
							path: '',
							element: (
								<AdminPage>
									<WorkspacePage />
								</AdminPage>
							),
						},
					],
				},
				{
					path: ADMIN_ROUTES.applications.path(),
					element: <PageLayout />,
					children: [
						{
							path: '',
							element: (
								<AdminPage>
									<BApplicationsPage />
								</AdminPage>
							),
						},
						{
							path: ADMIN_ROUTES.applicationsVariants.path(),
							element: (
								<AdminPage>
									<VariantsPage />
								</AdminPage>
							),
						},
					],
				},
				{
					path: ADMIN_ROUTES['manage-invitations'].path(),
					element: <PageLayout />,
					children: [
						{
							path: '',
							element: (
								<AdminPage>
									<InvitationsPage />
								</AdminPage>
							),
						},
					],
				},
				{
					path: ADMIN_ROUTES.pats.path(),
					element: <PageLayout />,
					children: [
						{
							path: '',
							element: (
								<AdminPage>
									<PATsManagementPage />
								</AdminPage>
							),
						},
					],
				},
				{
					path: ADMIN_ROUTES.users.path(),
					element: <PageLayout />,
					children: [
						{
							path: '',
							element: (
								<AdminPage>
									<UsersPage />
								</AdminPage>
							),
						},
					],
				},
				{
					path: ADMIN_ROUTES['manage-system'].path(),
					element: <PageLayout />,
					children: [
						{
							path: '',
							element: (
								<AdminPage>
									<SystemSettings />
								</AdminPage>
							),
						},
					],
				},
			],
		},
		{
			path: 'not-found',
			element: <NotFoundPage />,
			children: [],
		},
		{
			path: 'server-error',
			element: <ServerErrorPage />,
			children: [],
		},
		{
			path: 'app/:appId',
			element: <PageLayout />,
			children: [
				{
					path: '',
					element: <StartPage />,
				},
				{
					path: TEST_CENTER_ROUTES.start.path(),
					element: <StartPage />,
				},
				{
					path: TEST_CENTER_ROUTES.status.path(),
					element: <StatusPage />,
				},
				{
					path: TEST_CENTER_ROUTES.dashboard.path(),
					element: <DashboardPage />,
				},
				{
					path: TEST_CENTER_ROUTES.tags.path(),
					element: <TagsPage />,
				},
				{
					path: TEST_CENTER_ROUTES.runs.path(),
					element: <RunsPage />,
				},
				{
					path: TEST_CENTER_ROUTES.log.path(),
					element: <LogPage />,
				},
				// TODO(don): un-comment when we add issues page back
				// {
				// 	path: TEST_CENTER_ROUTES.issues.path(),
				// 	element: <IssuesPage />,
				// },
				{
					path: TEST_CENTER_ROUTES.model.path(),
					element: <ModelPage />,
				},
				{
					path: TEST_CENTER_ROUTES.rules.path(),
					element: <RulesPage />,
				},
				{
					path: TEST_CENTER_ROUTES.flows.path(),
					element: <FlowsPage />,
				},
				{
					path: 'flows/:id',
					element: <FlowsPage />,
				},
				{
					path: TEST_CENTER_ROUTES.data.path(),
					element: <DatasetsPage />,
				},
				{
					path: TEST_CENTER_ROUTES['run-templates'].path(),
					element: <RunTemplatesPage />,
				},
				{
					path: TEST_CENTER_ROUTES['form-specs'].path(),
					element: (
						<Suspense fallback={<div>Loading...</div>}>
							<FormSpecPage />
						</Suspense>
					),
				},
			],
		},
		{
			path: '*',
			element: <NotFoundPage />,
		},
	],
}

export default MainRoutes
