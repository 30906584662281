import React, { useCallback, useContext } from 'react'

import { useToggle } from '@asta/react-component-library'
import { MenuItem } from '@mui/material'
import Modal from 'components/shared/AstaModal/Modal'
import CopyAndMoveForm from 'components/shared/MoveAndCopy/CopyAndMoveForm'
import { AuthContext } from 'contexts/auth'
import { WithSelectedApp } from 'contexts/selectedApp'
import { useAssets } from 'hooks/App/useAssets'
import { canCreate } from 'pages/Admin/helpers'
import type { FC } from 'react'
import type { Asset, AssetWithParent } from 'shared/types'

import type { SelectedAppProps } from '../../../contexts/selectedApp/WithSelectedApp'

interface Props {
	type: Asset['type']
	asset: AssetWithParent
	onClose?: () => void
}

const DuplicateAssetMenu: FC<Props & SelectedAppProps> = ({
	selectedApp,
	type,
	asset,
	onClose,
}) => {
	const [open, , _on, close] = useToggle()

	const { user } = useContext(AuthContext)
	const { createAsset } = useAssets(type)

	const onCopy = useCallback(
		(name: string, newParent: string) => {
			const { _id, ...rest } = asset

			createAsset.mutate({
				pathParams: {
					appId: newParent,
				},
				body: {
					...rest,
					name,
					tags: asset.tags.map(tag =>
						typeof tag === 'string' ? tag : tag._id
					),
					parent: newParent,
				},
			})
			onClose && onClose()
		},
		[asset, createAsset, onClose]
	)

	const _onClose = useCallback(() => {
		close()
		onClose()
	}, [close, onClose])

	if (!canCreate(asset.parent.role) && !user?.isAdmin) return null

	return (
		<>
			<MenuItem onClick={_on}>Copy to</MenuItem>
			<Modal onClose={_onClose} isOpen={open}>
				<CopyAndMoveForm
					selectedApp={selectedApp}
					onSave={onCopy}
					onClose={_onClose}
					name={asset.name}
					isAdmin={user?.isAdmin}
					type="copy"
					resource={asset}
				/>
			</Modal>
		</>
	)
}

export default WithSelectedApp({})(DuplicateAssetMenu)
